import {
  AppBar,
  Box,
  Button as ButtonMui,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Button } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import { memo } from 'react';

interface Props extends DialogProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  disableConfirm?: boolean;
  handleClose: () => void;
  contextTitle?: string;
  textConfirm?: string;
  handleConfirm?: () => void;
}

export const Modal = ({
  title,
  contextTitle,
  fullWidth,
  fullScreen,
  maxWidth,
  children,
  open,
  textConfirm,
  disableConfirm = false,
  handleConfirm,
  onClose,
  handleClose,
  ...rest
}: Props) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <AppBar sx={{ position: 'relative' }} className="bg-soft-primary">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {handleConfirm && textConfirm && (
            <ButtonMui autoFocus color="inherit" onClick={handleClose}>
              {textConfirm}
            </ButtonMui>
          )}
        </Toolbar>
      </AppBar>

      <DialogContent>
        {contextTitle && <DialogContentText>{contextTitle}</DialogContentText>}

        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="soft-secondary"
          onClick={handleClose}
          style={{
            backgroundColor: 'gray-300',
            margin: '0.2rem',
          }}
        >
          Chiudi
        </Button>
        {handleConfirm && textConfirm && (
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={disableConfirm}
            style={{
              backgroundColor: 'primary',
              margin: '0.2rem',
            }}
          >
            {textConfirm}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(Modal);
