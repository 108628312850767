import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Grid } from '../../components';
import {
  FETCH,
  useAppDispatch,
  getAction,
  FATTURAZIONE_ID,
  getPermsByModule,
  getFatturazioneById,
  setPage,
} from '../../store';
import {
  FATTURAZIONE,
  FATTURAZIONE_LIST,
  MAP_TAB_ID_MODULE_PAGE,
  MAP_TAB_ID_TITLE_PAGE,
} from 'utils/constants';
import { resetImportazione } from 'store/importazioni/importazione';
import { listMonths } from 'helpers/date';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const generateData = (rows: number) => {
  return Array.from({ length: rows }, (_, index) => ({
    id: index + 1,
    name: `Item ${index + 1}`,
    date: new Date().toISOString().split('T')[0],
    isActive: index % 2 === 0 ? 'Yes' : 'No',
  }));
};

const columns = [
  { id: 'id', label: 'ID', minWidth: 100 },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'date', label: 'Date', minWidth: 170 },
  { id: 'isActive', label: 'Active', minWidth: 100 },
];

const Fatturazione: React.FC = () => {
  const dispatch = useAppDispatch();

  const permsFattutazione = useSelector(getPermsByModule(FATTURAZIONE));

  const [dateRange, setDateRange] = useState<any>([
    new Date(),
    new Date().setDate(new Date().getDate() + 7),
  ]);
  const [startDate, endDate] = dateRange;
  const [selectedId, setSelectedId] = useState<number>(0);

  const fatturazione = useSelector(
    selectedId ? getFatturazioneById(selectedId) : createSelector(() => null)
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);

  const months = listMonths();

  const open = () => {
    setIsOpen(true);
  };

  const reset = () => {
    // const actionReset = getAction(ANAGRAFICA_ID, RESET);
    // actionReset && dispatch(actionReset);
    setSelectedId(0);
  };

  const showDialogImport = (show: boolean) => {
    setShowImport(show);
    !show && dispatch(resetImportazione());
  };

  const handleOpenImport = () => {
    setShowImport(true);
  };

  const handleOpenNew = () => {
    reset();
    setIsOpen(true);
  };

  const onClose = () => {
    reset();
    setIsOpen(false);
  };

  /*
   * handle date change
   */
  const onDateChange = (date: Date) => {
    if (date) {
      setDateRange(date);
    }
  };

  const getTitle = () => {
    let title = '';
    if (fatturazione) title = `Fattura n. ${fatturazione.f24_doc_number}`;
    return title;
  };

  useEffect(() => {
    if (selectedId) {
      const actionFetch = getAction(FATTURAZIONE_ID, FETCH);
      actionFetch && dispatch(actionFetch({ id: selectedId }));
    }
  }, [selectedId]);

  useEffect(() => {
    const module = MAP_TAB_ID_MODULE_PAGE[FATTURAZIONE_ID];
    const pageInfo = {
      tabId: FATTURAZIONE_ID,
      module,
      url: FATTURAZIONE_LIST,
      title: MAP_TAB_ID_TITLE_PAGE[FATTURAZIONE_ID],
    };
    dispatch(setPage(pageInfo));
  }, []);

  return (
    <Row>
      <Col style={{ paddingTop: '1rem' }}>
        <Grid
          tabId={FATTURAZIONE_ID}
          module={FATTURAZIONE}
          pagination
          title=""
          selectedId={selectedId}
          selectRow={setSelectedId}
          open={setIsOpen}
          handleOpenNew={handleOpenNew}
          handleOpenImport={handleOpenImport}
          hasSelectorTime
          hasSchedeSelector
          hasFatturazioneStats
          hasReload
          hasNew={false}
          hasImport={false}
          hasExport={false}
        />
      </Col>
      <Form
        title={getTitle()}
        isModal={false}
        id={selectedId}
        isOpen={isOpen}
        tabId={FATTURAZIONE_ID}
        module={FATTURAZIONE}
        handleClose={onClose}
        hasLockState={false}
        hasSave={false}
      />
    </Row>
  );
};

export default Fatturazione;
