import { ActionType } from '../../..';
import { fetchUtente, createUtente, deleteUtente, resetUtente } from '../actions';

export const UtenteMapAction: Record<ActionType | string, any> = {
  fetch: fetchUtente,
  submit: createUtente,
  delete: deleteUtente,
  reset: resetUtente,
  deleteEmptyRows: deleteUtente,
};

export default UtenteMapAction;
