import { RootState } from 'store';
import { UtenteDeleteParams } from '../types';
import { apiClient, handleError, URL_UTENTE, URL_DELETE } from '../../../index';
import { AxiosResponse } from 'axios';
import { setUtenteSelectedIds } from 'store/utenti';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteUtente = createAsyncThunk(
  'utente/delete',
  async (params: UtenteDeleteParams, thunkAPI) => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.delete<AxiosResponse>({
        url: `${URL_UTENTE}${URL_DELETE}/${params.id}`,
      });
      if (response.status === 200) {
        if (state?.utenti?.selectedIds.includes(params.id)) {
          thunkAPI.dispatch(setUtenteSelectedIds(params.id));
        }
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
