import * as dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la localizzazione italiana

dayjs.locale('it');

/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.toJSON = () => dayjs.default(this).format('YYYY-MM-DD HH:mm:ss');

export const listMonths = () => {
  const dt = new Date();
  const month = dt.getMonth();
  const year = dt.getFullYear();
  const daysInMonth = new Date(year, month, 0).getDate();
  return daysInMonth;
};

export const formatNumberMonthToString = (monthNumber: number) => {
  return dayjs
    .default()
    .month(monthNumber - 1)
    .format('MMMM');
};

export const dateFormat = (date: Date, format = 'YYYY-MM-DD HH:mm:ss'): string =>
  dayjs.default(date).format(format);

export const dateToDayJs = (date: Date): dayjs.Dayjs => dayjs.default(date);

export const getHoursMinutesDifferenceFromDates = (
  dateFrom: dayjs.Dayjs,
  dateTo: dayjs.Dayjs
): { minutes: number; hours: number } => {
  const totalMinutes = getDifferenceInMinutes(dateFrom, dateTo);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes < 2 ? 1 : totalMinutes % 60;

  return {
    minutes,
    hours,
  };
};

export const getDifferenceInMinutes = (dateFrom: dayjs.Dayjs, dateTo: dayjs.Dayjs): number => {
  if (dateTo < dateFrom) {
    const appDateTo = dateTo;
    dateTo = dateFrom;
    dateFrom = appDateTo;
  }

  return dateTo.diff(dateFrom, 'minutes');
};

export const getToday = (): Date => {
  return dayjs.default().toDate();
};
