import {
  CODICE_FISCALE_REGEX,
  PARTITA_IVA_REGEX,
  PHONE_NUMBER,
  validateCodiceFiscale,
  validatePiva,
} from 'utils/constants/validations';
import * as Yup from 'yup';

const utenteSchema = Yup.object().shape({
  name: Yup.string().nullable(true).required('campo obbligatorio'),
  lastname: Yup.string().nullable(true).required('campo obbligatorio'),
  email: Yup.string()
    .email('inserire una e-mail valida')
    .nullable(true)
    .required('campo obbligatorio'),
  pwd: Yup.string().nullable(true).required('campo obbligatorio'),
});

export default utenteSchema;
