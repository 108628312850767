import { TEXT, DATE, SELECT, NUMBER } from 'components/Grid/Filters/model';
import { Months } from 'utils/date';
import { ColumnCustomHeader } from './model';
import { ColumnData, GenericObject } from 'utils/types';
import { formatNumberMonthToString } from 'helpers/date';
import { capitalizeFirstLetter } from 'helpers';
import { Badge } from 'react-bootstrap';
import { StatoScheda, StatoSchedaLabels } from 'store';
import { getVariantForStatus } from './utils';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '5rem',
  },
  {
    Header: 'Nome',
    accessor: 'nome',
    sort: true,
    filterType: TEXT,
    width: '20rem',
  },
  {
    Header: 'Stato',
    accessor: 'stato',
    sort: false,
    width: '10rem',
    actionComponent: (row: GenericObject) => {
      if (row.stato) {
        const value = StatoSchedaLabels[row.stato];
        const variantStato = getVariantForStatus(row.stato as StatoScheda);
        return (
          <Badge
            key={`stato-${row.id}`}
            className={`soft-${variantStato} badge bg-soft-${variantStato}`}
            style={{ margin: '.3rem' }}
          >
            {value}
          </Badge>
        );
      }
      return <div>-</div>;
    },
  },
  {
    Header: 'Mese',
    accessor: 'mese',
    sort: true,
    format: (value: any) => {
      return value ? capitalizeFirstLetter(formatNumberMonthToString(Number(value))) : '';
    },
    width: '12rem',
  },
  {
    Header: 'Anno',
    accessor: 'anno',
    sort: true,
    filterType: NUMBER,
    width: '10rem',
  },
];

export const schede = {
  preview: baseColumns,
  complete: baseColumns,
};

export const convertAccessorToSimpleTableHeader = () =>
  baseColumns.map((column: any) => ({ key: column.accessor, values: column.Header }));

export const convertAccessorSchedeToColumnData = (): any[] => {
  delete baseColumns[2];
  return baseColumns.map((column: any) => ({
    id: column.accessor,
    label: column.Header,
    align: ['mese', 'anno'].includes(column.accessor) ? 'right' : 'left',
    format: (value: any) =>
      ['string', 'number'].includes(typeof value) ? value : value.descrizione,
  }));
};
