import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/applicationStore';

export const getPageState = (state: RootState) => state.page;

export const getCurrentPage = createSelector(getPageState, (pageState) => pageState);
export const getCurrentTabId = createSelector(getCurrentPage, (page) => page.tabId);
export const getCurrentModule = createSelector(getCurrentPage, (page) => page.module);
export const getCurrentUrl = createSelector(getCurrentPage, (page) => page.url);
export const getCurrentTitle = createSelector(getCurrentPage, (page) => page.title);
