import {
  Types,
  TYPE_STRING,
  TYPE_INTEGER,
  TYPE_SELECT,
  TYPE_NUMBER,
  TYPE_BOOLEAN,
  TYPE_DATE,
  TYPE_CEDOLINI,
  TYPE_CURRENCY,
  TYPE_DATA_FATTURAZIONE,
} from 'store/filtri';
import { KeyValue } from 'store/types';

export interface ModuloChiave {
  id?: number;
  modulo_id: number;
  parentId: number;
  nome: string | null;
  tipo: Types | string;
  valore_default?: string | null;
  posizione: number;
  campo_sistema: boolean;
  elenco_valori?: string[];
}

export class ModuloChiaveClass implements ModuloChiave {
  id = 0;
  modulo_id = 0;
  parentId = 0;
  nome = null;
  tipo = TYPE_STRING;
  valore_default = null;
  posizione = 0;
  campo_sistema = false;
  elenco_valori = [];
  created = undefined;
  updated = undefined;
}

export const TipologiaChiave: KeyValue[] = [
  { id: TYPE_STRING, descrizione: 'Testo' },
  { id: TYPE_NUMBER, descrizione: 'Num. decimale' },
  { id: TYPE_INTEGER, descrizione: 'Num. intero' },
  { id: TYPE_CURRENCY, descrizione: 'Valuta' },
  { id: TYPE_BOOLEAN, descrizione: 'Booleano' },
  { id: TYPE_DATE, descrizione: 'Data' },
  { id: TYPE_SELECT, descrizione: 'Selezione' },
  { id: TYPE_CEDOLINI, descrizione: 'Num. cedolini' },
  { id: TYPE_DATA_FATTURAZIONE, descrizione: 'Data fatturazione' },
];

export const TipologiaChiaviSistema: string[] = [TYPE_CEDOLINI];
