// base URLs

const { REACT_APP_API_URL, REACT_APP_API_URL_LOCALHOST, REACT_APP_API_PROTOCOL } =
  process.env || {};
export const BASE = REACT_APP_API_URL; //
export const BASE_LOCALHOST = REACT_APP_API_URL_LOCALHOST; //
export const BASE_PROTOCOL = REACT_APP_API_PROTOCOL; //

// sections
export const URL_USER = '/user';
export const URL_ANAGRAFICA = '/anagrafica';
export const URL_TAB_IVA = '/tab_iva';
export const URL_CONTO = '/conto';
export const URL_ANAGRAFICA_CONTATTI = '/anagrafica_contatto';
export const URL_ANAGRAFICA_EMAILS = '/anagrafica_email';
export const URL_REFERENTE = '/referente';
export const URL_GEO = '/geo';
export const URL_GEO_NAZIONE = '/nazione';
export const URL_GEO_PROVINCIA = '/provincia';
export const URL_GEO_REGIONE = '/regione';
export const URL_GEO_COMUNE = '/comune';
export const URL_GEO_CITTA = '/citta';
export const URL_GEO_CAP = '/geo_cap';
export const URL_ATTIVITA = '/attivita';
export const URL_CONTATTO_CLIENTE = '/contatto_cliente';
export const URL_POLIZZA = '/polizza';
export const URL_DEAL = '/deal';
export const URL_ESITO = '/esito';
export const URL_COMPAGNIA = '/compagnia';
export const URL_IMPORTAZIONE = '/importazione';
export const URL_FILTRO = '/filtro';
export const URL_MODULO = '/modulo';
export const URL_MODULO_RIGHE = '/modulo_righe';
export const URL_RIGHE = '/righe';
export const URL_SETTING = '/setting';
export const URL_SCHEDA = '/scheda';
export const URL_LOCK = '/lock';
export const URL_FATTURAZIONE = '/fatturazione';
export const URL_AFFILIATI = '/affiliati';
export const URL_AZIENDA = '/azienda';

// versions
export const URL_V1 = '/v1';

// endpoints

// users endpoints
export const URL_EMAIL_CHANGE = '/email_change';
export const URL_EMAIL_CHANGE_VERIFY = '/email_change_verify';
export const URL_LOGIN = '/login';
export const URL_LOGOUT = '/logout';
export const URL_PASSWORD_CHANGE = '/password_change';
export const URL_PASSWORD_RESET = '/password_reset';
export const URL_REFRESH = '/refreshToken';
export const URL_ME = '/me';
export const URL_CONFIRM = '/confirm';
export const URL_FILE = '/file';
export const URL_STATISTICHE = '/statistiche';

//impostazioni endpoints
export const URL_PREDEFINITI = '/predefiniti';

//utenti endpoints
export const URL_REGISTER = '/register';

//system endpoints
export const URL_VERIFICA = '/verifica';

// common endpoints
export const URL_CREATE = '/create';
export const URL_DUPLICA = '/duplica';
export const URL_SAVE = '/save';
export const URL_CREATE_MULTI = '/create_multi';
export const URL_DELETE = '/delete';
export const URL_DELETE_EMPTY_ROWS = '/delete_empty_rows';
export const URL_DELETE_MULTI = '/delete_multi';
export const URL_ENABLE_MULTI = '/enable_multi';
export const URL_ENABLE = '/enable';
export const URL_DOWNLOAD = '/download';
export const URL_GET = '/item';
export const URL_ITEM = '/item';
export const URL_LIST = '/list';
export const URL_ITEMS = '/items';
export const URL_SELECT_ALL = '/selectAll';
export const URL_LOG = '/log';
export const URL_TIPOLOGIA = '/tipologia';
export const URL_STATO = '/stato';
export const URL_GRUPPO = '/gruppo';
export const URL_CATEGORIA = '/categoria';
export const URL_FRAZIONAMENTI = '/frazionamenti';
export const URL_CLASSIFICAZIONI = '/classificazioni';
export const URL_UPDATE = '/update';
export const URL_UPLOAD = '/upload';
export const URL_UTENTE = '/utente';
export const URL_CREATE_POSITION = '/create_position';
export const URL_REMOVE = '/remove';
export const URL_ESPORTA_FATTURE = '/esporta-fatture';
export const URL_FATTURE_INVIATE = '/fatture-inviate';
export const URL_INVIATA_SDI = '/inviata-sdi';
export const URL_ASSOCIA_MULTI = '/associa_multi';
export const URL_ASSOCIA_PADRE = '/associa_padre';
export const URL_INFO = '/info';
export const URL_VALIDATE = '/validate';
export const URL_CAMPI_FATTURAZIONE = '/campi-fatturazione';
export const URL_CAMBIO_STATO = '/cambio-stato';
export const URL_ADD_LOG_SCHEDA = '/add_log';
export const URL_SEND = '/send';
export const URL_DATA_FATTURAZIONE = '/data-fatturazione';
export const URL_LAST = '/last';
export const URL_RIGA_ANAGRAFICA = '/riga-anagrafica';
export const URL_RELOAD = '/reload';
