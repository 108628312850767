import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setAnagraficaSelectedIds } from '.';
import { apiClient, handleError, RootState, setError } from '../../..';
import { URL_ANAGRAFICA, URL_ENABLE_MULTI } from '../../..';

export const enableeAnagraficheMulti = createAsyncThunk(
  'anagrafiche/enableMulti',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = state?.anagrafiche?.selectedIds ?? [];
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_ENABLE_MULTI}`,
        params: { ids: params },
      });
      if (response.status === 200) {
        const deleted = response.data.data.deleted;
        const notDeleted = response.data.data.notDeleted;
        if (deleted) {
          deleted.forEach((id: number) => {
            if (state?.anagrafiche?.selectedIds.includes(id)) {
              thunkAPI.dispatch(setAnagraficaSelectedIds(id));
            }
          });
        }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
