import { createSelector } from 'reselect';
import { Anagrafica } from '../..';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getAffiliatiState = (state: RootState) => state.affiliati;

export const getAffiliatiLoader = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loading === PENDING
);

export const getAffiliatiFailed = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loading === FAILED
);

export const getAffiliatiSuccess = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loading === SUCCEEDED
);

export const getAffiliatiOptionLoader = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loadingOption === PENDING
);

export const getAffiliatiOptionFailed = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loadingOption === FAILED
);

export const getAffiliatiOptionSuccess = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loadingOption === SUCCEEDED
);

export const getAffiliatiEnumLoader = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loadingEnum === PENDING
);

export const getAffiliatiEnumFailed = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loadingEnum === FAILED
);

export const getAffiliatiEnumSuccess = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState.loadingEnum === SUCCEEDED
);

export const getAffiliatiError = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.error
);

export const getAffiliati = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.data
);

export const getAffiliatiInfo = createSelector(
  getAffiliatiState,
  (affiliatiState: any): Info => affiliatiState?.info ?? { total: 0 }
);

export const getAffiliatiOption = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.dataOption
);

export const getAffiliatiOptionInfo = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.infoOption
);

export const getAffiliatiKeyValues = createSelector(
  getAffiliati,
  (affiliati) =>
    affiliati?.map((affiliato: Anagrafica) => ({
      id: affiliato.id,
      descrizione: affiliato.ragione_sociale,
    })) ?? []
);
export const getAffiliatiOptionKeyValues = createSelector(
  getAffiliatiOption,
  (affiliati) =>
    affiliati?.map((affiliato: Anagrafica) => ({
      id: affiliato.id,
      descrizione: affiliato.ragione_sociale,
    })) ?? []
);

export const getAffiliatoById = (id: string | number) =>
  createSelector(getAffiliati, (affiliati) =>
    affiliati?.find((affiliatoElement: Anagrafica) => affiliatoElement.id === id)
  );

export const getAffiliatoOptionById = (id: string | number) =>
  createSelector(getAffiliatiOption, (affiliati) =>
    affiliati?.find((affiliatoElement: Anagrafica) => affiliatoElement.id === id)
  );

export const getAffiliatiParams = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.params
);

export const getAffiliatiParamsOption = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.paramsOption
);

export const getAffiliatiSelectedIds = createSelector(
  getAffiliatiState,
  (affiliatiState) => affiliatiState?.selectedIds
);
