import FormButtons from 'components/FormButtons';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Perm, SCHEDA_ID, TabsId } from 'store';
import Forms from './children';
import FormContext from './FormContext';
import { ModuleName } from 'utils/constants';
import { useCrudForm, useWindowSize } from 'hooks';
import { Box, Dialog, Flex } from 'components';
import LockAlert from 'components/LockAlert';
import { Button, Typography } from '@mui/material';
import MapEmptyElement from './mapEmptyElement';
import { MapTitleEditElement, MapTitlesNewElement } from './title';
import { formatNumberMonthToString } from 'helpers/date';

type SizeType = 'lg' | 'md' | 'xl';

interface FormProps {
  title?: string;
  isOpen: boolean;
  perms?: Perm;
  id: number | null;
  tabId: TabsId;
  handleClose: () => void;
  module: ModuleName;
  isModal?: boolean;
  hasSave?: boolean;
  deleteEmptyChild?: boolean;
  hasLockState?: boolean;
  minHeight?: string;
  size?: SizeType;
  hasOnlySaveAndClose?: boolean;
  saveAndCloseText?: string;
  customComponent?: React.ReactElement;
  customSave?: () => void;
  customSaveAndClose?: () => void;
  hideFormButtons?: boolean;
  hideSaveButtons?: boolean;
}

const Form = ({
  title = '',
  isOpen,
  perms,
  id,
  tabId,
  handleClose,
  module,
  hasSave = true,
  isModal = false,
  deleteEmptyChild = false,
  hasLockState = false,
  minHeight = '20rem',
  size = 'lg',
  hasOnlySaveAndClose = false,
  saveAndCloseText,
  customComponent,
  customSave,
  customSaveAndClose,
  hideFormButtons = false,
  hideSaveButtons = false,
  ...rest
}: FormProps & any) => {
  const component = Forms[tabId];

  const {
    methods,
    closeAndResetForm,
    handleSave,
    handleSaveAndClose,
    isLoading,
    element,
    handleSaveCurrentTab,
    hanldeLockStatus,
    handleUnlock,
    isDisabled,
    errorLock,
  } = useCrudForm({
    id,
    tabId,
    handleClose,
    deleteEmptyChild,
    module,
    customSaveAndClose,
  });
  const formElement = React.cloneElement(customComponent ?? component, {
    id,
    element,
    module,
    ...rest,
  });

  const window = useWindowSize();

  const titleEmpty = MapTitlesNewElement[tabId];

  const [titleElement, setTitleElement] = useState<string>(titleEmpty);

  const [heightForm, setHeightForm] = useState('50rem');

  useLayoutEffect(() => {
    const formButton = document.getElementById('form-buttons');
    const heightWindow = window.height;
    if (formButton && heightWindow) {
      const heightFormButton = formButton.offsetHeight;
      setHeightForm(`${heightWindow - heightFormButton}px`);
    }
  }, [window.height]);

  useEffect(() => {
    if (element) {
      const keys = MapTitleEditElement[tabId];
      // Costruisci la stringa concatenando i valori delle chiavi presenti in element

      if (tabId === SCHEDA_ID) {
        const titleParts = `${element.nome} ${formatNumberMonthToString(element.mese)}/${
          element.anno
        }`;
        setTitleElement(titleParts);
      } else {
        const titleParts = keys
          .map((key) => element[key])
          .filter((value) => value !== undefined && value !== '');
        // Unisci le parti con uno spazio (o altro separatore a tua scelta)
        setTitleElement(titleParts.join(' '));
      }
    }
  }, [element]);

  if (isOpen) {
    return isModal ? (
      <Dialog
        title={title}
        setShow={handleClose}
        show={isOpen}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div
          style={{
            position: 'relative',
            minHeight: minHeight,
            maxHeight: 'fit-content',
          }}
        >
          <FormContext.Provider
            value={{
              formId: id,
              element: element,
              isDisabled: hasLockState ? isDisabled : false,
              handleSaveCurrentTab,
              closeForm: closeAndResetForm,
            }}
          >
            <FormProvider {...methods}>{formElement}</FormProvider>
          </FormContext.Provider>

          {!hideFormButtons ? (
            <FormButtons
              id={id}
              hasLockState={hasLockState}
              hasSave={perms && perms.can_write && hasSave}
              handleClose={closeAndResetForm}
              handleSave={customSave ?? handleSave}
              handleSaveAndClose={customSaveAndClose ?? handleSaveAndClose}
              isLoading={isLoading}
              handleLockStatus={hanldeLockStatus}
              handleUnlock={handleUnlock}
              isDisabled={isDisabled}
              hasOnlySaveAndClose
              saveAndCloseText={saveAndCloseText}
              hideSaveButtons={hideSaveButtons}
            />
          ) : (
            <div />
          )}
        </div>
        {errorLock && Number(errorLock.module_id) === Number(id) && module === errorLock.module ? (
          <LockAlert element={element} />
        ) : (
          <></>
        )}
      </Dialog>
    ) : (
      <div className={`form outline-soft-primary`} style={{ padding: 0 }}>
        <Box
          display="grid"
          gridTemplateRows={hideFormButtons ? `4.2rem auto` : `4.2rem auto 4.2rem`}
          // flexDirection="column"
          // justifyContent={'space-between'}
          // position="relative"
          height="100vh"
        >
          <Flex
            backgroundColor="var(--bs-primary-bg-subtle)"
            borderBottom="1px var(--bs-primary) solid"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h6" // Scegli la variante che meglio si adatta alle tue esigenze
              style={{
                fontWeight: 600,
                fontSize: '1.7em',
                paddingLeft: '1rem',
                textTransform: 'uppercase',
              }} // Peso del font non in grassetto e dimensione leggermente più piccola
            >
              {titleElement}
            </Typography>
            {hideFormButtons && (
              <Button
                variant="outlined"
                onClick={closeAndResetForm}
                style={{ marginRight: '1rem' }}
              >
                Close
              </Button>
            )}
          </Flex>
          <FormContext.Provider
            value={{
              formId: id,
              element: element,
              isDisabled: hasLockState ? isDisabled : false,
              handleSaveCurrentTab,
              closeForm: closeAndResetForm,
            }}
          >
            <FormProvider {...methods}>
              <div style={{ overflowY: 'auto' }}>{formElement}</div>
            </FormProvider>
            {errorLock &&
            Number(errorLock.module_id) === Number(id) &&
            module === errorLock.module ? (
              <LockAlert element={element} />
            ) : (
              <></>
            )}
          </FormContext.Provider>

          {!hideFormButtons ? (
            <FormButtons
              id={id}
              hasLockState={hasLockState}
              hasSave={!!perms && !!perms?.can_write && hasSave}
              handleClose={closeAndResetForm}
              handleSave={customSave ?? handleSave}
              handleSaveAndClose={customSaveAndClose ?? handleSaveAndClose}
              isLoading={isLoading}
              handleLockStatus={hanldeLockStatus}
              handleUnlock={handleUnlock}
              isDisabled={isDisabled}
              hasOnlySaveAndClose
              saveAndCloseText={saveAndCloseText}
              hideSaveButtons={hideSaveButtons}
            />
          ) : (
            <div />
          )}
        </Box>
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default Form;
