import { KeyValue } from './../../../store/types/model';
import {
  TabsId,
  Types,
  TYPE_STRING,
  TYPE_NUMBER,
  TYPE_DATE,
  TYPE_BOOLEAN,
  FiltroChiave,
} from 'store';
export const TEXT = 'text';
export const DATE = 'date';
export const NUMBER = 'number';
export const SELECT = 'select';
export const BOOLEAN = 'bool';
export type FilterType = typeof TEXT | typeof DATE | typeof NUMBER | typeof SELECT | typeof BOOLEAN;

export const MapTypes: Record<FilterType, Types> = {
  [TEXT]: TYPE_STRING,
  [DATE]: TYPE_DATE,
  [NUMBER]: TYPE_NUMBER,
  [SELECT]: TYPE_STRING,
  [BOOLEAN]: TYPE_BOOLEAN,
};

export interface FilterProps {
  type: FilterType;
  title: string;
  tabId: TabsId;
  field: string;
  params: any;
  updateParams: (params: any) => void;
  options?: KeyValue[];
  dateFormat?: string;
  timeFormat?: string;
  filters: FiltroChiave[];
  register?: any;
  setFilters: (filters: FiltroChiave[]) => void;
}
