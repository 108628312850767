import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContoFetchParams } from '../types';
import { handleError, URL_CONTO, URL_GET, URL_V1 } from '../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../utils';

export const fetchConto = createAsyncThunk('conto/fetch', async (_, thunkAPI) => {
  try {
    const response = await apiClient.get<AxiosResponse>({
      url: `${URL_CONTO}${URL_GET}`,
    });
    if (response.status === 200) {
      return response.data;
    }
    return handleError(response.status, thunkAPI, response.data?.status);
  } catch (error: any) {
    return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
  }
});
