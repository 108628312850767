import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnagraficaCreateParams } from '../types';
import { apiClient, handleError, URL_ANAGRAFICA, URL_CREATE } from '../../../index';
import { AxiosResponse } from 'axios';

export const createAnagrafica = createAsyncThunk(
  'anagrafica/create',
  async (params: AnagraficaCreateParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_CREATE}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
