import { DataModal, Flex } from 'components';
import { Button } from 'react-bootstrap';
import Icon from '@ailibs/feather-react-ts';
import {
  ANAGRAFICA_ID,
  fetchAnagrafiche,
  getPermsByModule,
  getSelectorSelectedIds,
  resetAnagraficaSelectedIds,
  useAppDispatch,
} from 'store';
import { ANAGRAFICA } from 'utils';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { enableeAnagraficheMulti } from 'store/anagrafiche/anagrafiche/actions/enableMulti';

export const AnagraficaDeletedButton = () => {
  const perms = useSelector(getPermsByModule(ANAGRAFICA));
  const selectedIds: any[] = getSelectorSelectedIds(ANAGRAFICA_ID);
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    if (selectedIds && selectedIds.length > 0) {
      const response = await dispatch(enableeAnagraficheMulti());
      if (response && !response.error) {
        dispatch(resetAnagraficaSelectedIds());
        await dispatch(fetchAnagrafiche());
      }
    }
  };

  return (
    <DataModal
      button={
        <Flex paddingRight="2rem" paddingBottom="0.2rem">
          <Button
            variant="soft-primary"
            className="cursor-pointer"
            onClick={() => void 0}
            disabled={perms && !perms.can_write}
            style={{
              margin: '0', // Rimuove il padding del pulsante
              borderRadius: '50%', // Rende il pulsante perfettamente rotondo
              width: '30px', // Imposta una larghezza fissa
              height: '30px', // Imposta un'altezza fissa per assicurarsi che sia uguale alla larghezza
              display: 'flex', // Usa flex per centrare l'icona all'interno del pulsante
              justifyContent: 'center', // Centra orizzontalmente l'icona
              alignItems: 'center', // Centra verticalmente l'icona
              marginRight: '.5rem',
            }}
          >
            <i className="uil uil-arrow-circle-down"></i>
          </Button>
          <Flex fontWeight="700" fontSize="0.9rem" alignItems="center">
            Ripristino
          </Flex>
        </Flex>
      }
      onSubmit={onSubmit}
      title={'Ripristina anagrafiche'}
      tabId={ANAGRAFICA_ID}
      additionalParams={{ deleted: true }}
    />
  );
};

export default AnagraficaDeletedButton;
