import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, URL_ME, URL_USER } from '../../utils';

export const fetchUser = createAsyncThunk('user/fetch', async (_, thunkAPI) => {
  try {
    const response = await apiClient.get<AxiosResponse>({
      url: `${URL_USER}${URL_ME}`,
      params: {},
    });
    if (response.status === 200) {
      return response.data;
    }

    return handleError(response.status, thunkAPI, response.data?.status);
  } catch (error: any) {
    return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
  }
});
