import { ErrorState } from '../';
import { createSelector } from 'reselect';
import { LockStatus, RootState } from '../..';

export const getErrorState = (state: RootState): ErrorState => state.error;

export const getErrorMessage = createSelector(
  getErrorState,
  (errorState): string => (errorState?.errorMessage as string) || ''
);

export const getErrorTitle = createSelector(
  getErrorState,
  (errorState): string => (errorState?.errorTitle as string) || ''
);

export const getErrorLock = createSelector(
  getErrorState,
  (errorState): LockStatus | undefined | null => errorState?.lock
);

export const getErrorStatus = createSelector(
  getErrorState,
  (errorState): string | number => (errorState?.errorStatus as string) || ''
);
