import { ModuleName } from 'utils/constants';

export interface Filtro {
  id?: number;
  module: ModuleName | null;
  nome: string | null;
  filtro_chiavi?: FiltroChiave[];
  created?: Date;
  updated?: Date;
}
export const TYPE_STRING = 'string';
export const TYPE_CURRENCY = 'currency';
export const TYPE_DATE = 'date';
export const TYPE_NUMBER = 'number';
export const TYPE_BOOLEAN = 'boolean';
export const TYPE_INTEGER = 'int';
export const TYPE_SELECT = 'select';
export const TYPE_CEDOLINI = 'ced';
export const TYPE_TAGS = 'tags';
export const TYPE_DATA_FATTURAZIONE = 'data_fatturazione';

export type Types =
  | typeof TYPE_STRING
  | typeof TYPE_CURRENCY
  | typeof TYPE_DATE
  | typeof TYPE_NUMBER
  | typeof TYPE_INTEGER
  | typeof TYPE_SELECT
  | typeof TYPE_BOOLEAN;

export interface FiltroChiave {
  id?: number;
  chiave: string;
  valore: string | null;
  tipo: Types;
}

export class FiltroClass implements Filtro {
  id = 0;
  module = null;
  nome = null;
  created = undefined;
  updated = undefined;
  filtro_chiavi = [];
}
