import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { InfoStepsProps, StepValidazione } from 'utils';

interface LoaderProps {
  steps: Record<StepValidazione, InfoStepsProps>;
}

const StepLoader: React.FC<LoaderProps> = ({ steps }) => {
  const getIconForState = (stato: 'idle' | 'waiting' | 'success' | 'error') => {
    switch (stato) {
      case 'idle':
        return <HourglassEmptyIcon color="action" />;
      case 'waiting':
        return <HourglassFullIcon color="primary" />;
      case 'success':
        return <CheckCircleIcon color="success" />;
      case 'error':
        return <ErrorIcon color="error" />;
      default:
        return null;
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
    >
      <CircularProgress />
      <Box mt={4}>
        {Object.entries(steps).map(([step, info]) => (
          <Box key={step} mb={2} display="flex" alignItems="center">
            {getIconForState(info.stato as 'idle' | 'waiting' | 'success' | 'error')}
            <Box ml={1}>
              <Typography variant="h6">{info.title}</Typography>
              <Typography variant="body2">Stato: {info.stato}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StepLoader;
