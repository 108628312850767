import { KeyValue } from 'store/types';

export interface Utente {
  id?: number;
  name: string | null;
  pwd: string | null;
  lastname: string | null;
  email: string | null;
  enable: boolean;
  date_enable: Date;
  role: string;
  created?: Date;
  updated?: Date;
}

export class UtenteClass implements Utente {
  id = 0;
  name = null;
  lastname = null;
  pwd = null;
  email = null;
  enable = true;
  date_enable = new Date();
  role = ROLE_USER;
  created = undefined;
  updated = undefined;
}

export const ROLE_USER = 'user';
export const ROLE_ADMIN = 'admin';

export const Roles: KeyValue[] = [
  { id: ROLE_ADMIN, descrizione: 'Amministratore', default: false },
  { id: ROLE_USER, descrizione: 'Utente', default: true },
];
