export const SUBMIT = 'submit';
export const FETCH = 'fetch';
export const DELETE = 'delete';
export const DELETEMULTI = 'deleteMulti';
export const RESET = 'reset';
export const FETCHLIST = 'fetchList';
export const RESETLIST = 'resetList';
export const FETCHLISTOPTION = 'fetchListOption';
export const RESETLISTOPTION = 'resetListOption';
export const SETPARAMS = 'setParams';
export const SETPARAMSOPTION = 'setParamsOption';
export const SETSELECTEDIDS = 'setSelectedIds';
export const SETSELECTALL = 'setSelectAll';
export const RESETPARAMS = 'restParams';
export const RESETPARAMSOPTION = 'restParamsOption';
export const RESETSELECTEDIDS = 'resetSelectedIds';
export const DELETE_EMPTY_ROWS = 'deleteEmptyRows';
export const UPDATE_SINGLE_RECORD = 'updateSingleRecord';
export const RESET_ALL = 'resetAll';
export const RESET_ALL_OPTION = 'resetAllOption';

export type ActionType =
  | 'submit'
  | 'fetch'
  | 'delete'
  | 'deleteMulti'
  | 'reset'
  | 'fetchList'
  | 'resetList'
  | 'fetchListOption'
  | 'resetListOption'
  | 'setParams'
  | 'setParamsOption'
  | 'setSelectedIds'
  | 'setSelectAll'
  | 'restParams'
  | 'restParamsOption'
  | 'resetSelectedIds'
  | 'deleteEmptyRows'
  | 'updateSingleRecord'
  | 'resetAll'
  | 'resetAllOption';

export const getActionType = (actionType: ActionType, option = false) => {
  const actionTypeList: Record<string, string> = {
    [FETCHLIST]: option ? FETCHLISTOPTION : FETCHLIST,
    [RESETLIST]: option ? RESETLISTOPTION : RESETLIST,
    [SETPARAMS]: option ? SETPARAMSOPTION : SETPARAMS,
    [RESETPARAMS]: option ? RESETPARAMSOPTION : RESETPARAMS,
    [SETSELECTEDIDS]: SETSELECTEDIDS,
    [RESETSELECTEDIDS]: RESETSELECTEDIDS,
    [SETSELECTALL]: SETSELECTALL,
    [SUBMIT]: SUBMIT,
    [FETCH]: FETCH,
    [DELETE]: DELETE,
    [DELETEMULTI]: DELETEMULTI,
    [RESET]: RESET,
    [DELETE_EMPTY_ROWS]: DELETE_EMPTY_ROWS,
    [UPDATE_SINGLE_RECORD]: UPDATE_SINGLE_RECORD,
    [RESET_ALL]: option ? RESET_ALL_OPTION : RESET_ALL,
  };

  return actionTypeList[actionType];
};
