import Icon from '@ailibs/feather-react-ts';
import { FormInput } from 'components';
import { ChangeEvent } from 'react';
import { FilterElement } from './FilterElement';
import { FilterProps, MapTypes } from './model';

export const FilterColumn = ({
  type,
  field,
  title,
  updateParams,
  params,
  filters,
  setFilters,
  register,
  ...rest
}: FilterProps): JSX.Element => {
  const removeFilter = (value: any) => {
    const copyFilters = filters;
    copyFilters.forEach((filter, index) => {
      if (filter.chiave === field) copyFilters.splice(index, 1);
    });
    return copyFilters;
  };

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    const otherFilters = removeFilter(value);
    if (!value.length) {
      const { ...otherParams } = params;
      otherParams[field] = null;
      setFilters(otherFilters.length ? [...otherFilters] : []);
      return updateParams({ ...otherParams });
    }
    (value.length >= 3 || rest?.options) && updateParams({ ...params, [field]: value });
    setFilters([...otherFilters, { chiave: field, valore: value ?? null, tipo: MapTypes[type] }]);
  };

  return (
    <FilterElement
      title={title}
      type={type}
      name={field}
      containerClass={'mb-1'}
      style={{ paddingTop: '0', paddingBottom: '0' }}
      onChange={onChange}
      register={register}
      {...rest}
    />
  );
};
