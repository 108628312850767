import { SelectorType } from '../../../utils/constants/selectorTypes';
import {
  getFatturazione,
  getFatturazioneFailed,
  getFatturazioneLoader,
  getFatturazioneSuccess,
} from '../selectors';

export const FatturazioneMapSelector = {
  loadingElement: getFatturazioneLoader,
  successElement: getFatturazioneSuccess,
  failedElement: getFatturazioneFailed,
  element: getFatturazione,
};

export default FatturazioneMapSelector;
