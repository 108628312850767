import { createSlice } from '@reduxjs/toolkit';
import { StatePage } from '../types/state';
import { LOGIN_ID } from 'store/utils';
import { LOGIN } from 'utils';
import { setPage, resetPage } from '../actions';

const initialState: StatePage = {
  tabId: LOGIN_ID,
  url: LOGIN,
};

export const pageSlice = createSlice({
  name: 'pageInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setPage, (state, { payload }) => {
        state.tabId = payload.tabId;
        state.module = payload.module;
        state.url = payload.url;
      })
      .addCase(resetPage, (state) => (state = initialState));
  },
});
