import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Grid, Modal } from '../../components';
import {
  FETCH,
  useAppDispatch,
  getAction,
  SCHEDA_ID,
  getPermsByModule,
  Scheda,
  getSelectorElementById,
  setPage,
  VALIDATE,
  cambioStatoScheda,
  SchedaCamnbioStatoParams,
  VALIDAZIONE_SCHEDA_ID,
  campiFatturazioneScheda,
  BLOCK,
  COMPLETED,
} from '../../store';
import { MAP_TAB_ID_MODULE_PAGE, MAP_TAB_ID_TITLE_PAGE, SCHEDA, SCHEDE } from 'utils/constants';
import { resetImportazione } from 'store/importazioni/importazione';
import { formatNumberMonthToString, listMonths } from 'helpers/date';
import { useSelector } from 'react-redux';
import { MenuItem } from 'utils';
import * as dayjs from 'dayjs';
import DuplicaScheda from './duplicaScheda';
import { ValidateSchedaForm } from './validateScheda';
import { RecapInfoScheda } from './recapInfoScheda';
import { fetchConto } from 'store/conto';

// dummy data

const Schede = () => {
  const dispatch = useAppDispatch();

  const perms = useSelector(getPermsByModule(SCHEDA));

  const [dateRange, setDateRange] = useState<any>([
    new Date(),
    new Date().setDate(new Date().getDate() + 7),
  ]);
  const [startDate, endDate] = dateRange;
  const [statoScheda, setStatoScheda] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenRecap, setIsOpenRecap] = useState<boolean>(false);
  const [isOpenValidation, setIsOpenValidation] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);
  const currentScheda = getSelectorElementById<Scheda>(SCHEDA_ID, selectedId);
  const dayjsElement = dayjs.default();
  const currentMonth = dayjsElement.month();
  const currentYear = dayjsElement.year();

  const months = listMonths();

  const additionalRowMenuOption: MenuItem[] = [
    {
      title: 'Duplica scheda',
      onClick: () => console.log('duplica'),
      handleClickItem: (id: number) => {
        setSelectedId(id);
        setIsOpenModal(true);
      },
      key: 'duplica-scheda',
      icon: 'copy',
      needId: true,
    },
  ];

  const open = () => {
    setIsOpen(true);
  };

  const reset = () => {
    // const actionReset = getAction(ANAGRAFICA_ID, RESET);
    // actionReset && dispatch(actionReset);
    setSelectedId(0);
  };

  const showDialogImport = (show: boolean) => {
    setShowImport(show);
    !show && dispatch(resetImportazione());
  };

  const handleOpenImport = () => {
    setShowImport(true);
  };

  const handleOpenNew = () => {
    reset();
    setIsOpen(true);
  };

  const onClose = () => {
    reset();
    setIsOpen(false);
  };

  const onCloseValidazione = () => {
    setIsOpenValidation(false);
  };

  /*
   * handle date change
   */
  const onDateChange = (date: Date) => {
    if (date) {
      setDateRange(date);
    }
  };

  const onSelectId = async (id: number) => {
    const actionFetch = getAction(SCHEDA_ID, FETCH);
    if (actionFetch) {
      const response = await dispatch(actionFetch({ id: selectedId }));
      if (response && !response.error) {
        const scheda = response.payload.data.scheda;
        console.log({ stato: scheda.stato });
        setStatoScheda(scheda.stato);
      }
    }
  };

  const openRecapInfo = async () => {
    const responseConto = await dispatch(fetchConto()).then((res: any) => {
      setIsOpenRecap(true);
    });
  };

  const validateForm = async () => {
    if (selectedId) {
      const params: SchedaCamnbioStatoParams = {
        id: selectedId,
        stato: VALIDATE,
      };

      const response = await dispatch(cambioStatoScheda(params)).then(async (res: any) => {
        if (res && !res.error) {
          const responseCampi = await dispatch(campiFatturazioneScheda({ id: selectedId }));
          setIsOpenRecap(false);
          setIsOpen(false);
          setIsOpenValidation(true);
        }
      });
    }
  };

  const openRecap = () => {
    onClose();
    setIsOpenRecap(true);
  };

  useEffect(() => {
    if (selectedId) {
      onSelectId(selectedId).then();
    }
    setStatoScheda('');
  }, [selectedId]);

  useEffect(() => {
    const module = MAP_TAB_ID_MODULE_PAGE[SCHEDA_ID];
    const pageInfo = {
      tabId: SCHEDA_ID,
      module,
      url: SCHEDE,
      title: MAP_TAB_ID_TITLE_PAGE[SCHEDA_ID],
    };
    dispatch(setPage(pageInfo));
  }, []);

  return (
    <Row>
      <Col style={{ paddingTop: '1rem' }}>
        <Grid
          tabId={SCHEDA_ID}
          module={SCHEDA}
          pagination
          title=""
          selectedId={selectedId}
          selectRow={setSelectedId}
          open={setIsOpen}
          handleOpenNew={handleOpenNew}
          handleOpenImport={handleOpenImport}
          hasSelectorTime
          hasImport={false}
          hasExport={false}
          additionalRowMenuOption={additionalRowMenuOption}
        />
      </Col>
      <Form
        title={!selectedId && 'Nuova Scheda'}
        perms={perms}
        isModal={!selectedId}
        id={selectedId}
        isOpen={isOpen}
        tabId={SCHEDA_ID}
        module={SCHEDA}
        handleClose={onClose}
        hasLockState={false}
        hasOnlySaveAndClose
        hideSaveButtons={selectedId && [BLOCK, COMPLETED].includes(statoScheda)}
        {...(selectedId && ![BLOCK, COMPLETED].includes(statoScheda)
          ? {
              customSaveAndClose: openRecapInfo,
              saveAndCloseText: 'Avanti',
            }
          : {})}
      />
      <DuplicaScheda id={selectedId} isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />

      {isOpenRecap && (
        <RecapInfoScheda
          scheda={currentScheda}
          isOpen={isOpenRecap}
          onClose={() => setIsOpenRecap(false)}
          onConfirm={validateForm}
        />
      )}

      {isOpenValidation && (
        <Form
          title={'Validazione Scheda'}
          perms={perms}
          id={selectedId}
          isOpen={isOpenValidation}
          tabId={VALIDAZIONE_SCHEDA_ID}
          module={SCHEDA}
          handleClose={onCloseValidazione}
          hasLockState={false}
          hasOnlySaveAndClose
          hideFormButtons
          customComponent={<ValidateSchedaForm id={selectedId} />}
        />
      )}
    </Row>
  );
};

export default Schede;
