import { yupResolver } from '@hookform/resolvers/yup';
import { AlertMessage } from 'components/AlertMessage';
import MapValidationSchemas from 'components/Form/mapValidations';
import FormButtons from 'components/FormButtons';
import FormInput from 'components/FormInput';
import { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Filtro,
  FiltroChiave,
  FiltroClass,
  FILTRO_ID,
  getAction,
  getSelectorLoadingElement,
  SUBMIT,
  TYPE_SELECT,
  getPermsByModule,
} from 'store';
import { ModelFormProps } from '../model';
import { useSelector } from 'react-redux';
import { FILTRO } from 'utils';

interface FiltroProps extends ModelFormProps<any> {
  handleClose: () => void;
  filters?: FiltroChiave[];
  selectedIds?: number[];
}

export const FiltroForm = ({ module, handleClose, filters = [], selectedIds }: FiltroProps) => {
  const emptyElement = new FiltroClass();

  const schema = MapValidationSchemas[FILTRO_ID];

  const isLoading = getSelectorLoadingElement(FILTRO_ID);

  const methods = useForm<any>({
    mode: 'onBlur',
    defaultValues: emptyElement,
    resolver: yupResolver(schema),
  });

  const perms = useSelector(getPermsByModule(FILTRO));

  const dispatch = useDispatch();
  const {
    trigger,
    getValues,
    setValue,
    reset,
    register,
    control,
    watch,
    formState: { errors, dirtyFields, isDirty, touchedFields },
    clearErrors,
  } = methods;

  const closeAndResetForm = () => {
    // resetStore(id, true);
    reset(emptyElement);
    handleClose();
  };

  const actionSave = async (): Promise<any | null> => {
    const noError = await trigger();
    const item = getValues();

    if (noError) {
      const actionCreate = getAction(FILTRO_ID, SUBMIT);

      const response = await dispatch(actionCreate(item));
      reset(item);
      return response;
    }
    return null;
  };

  const save = async (close: boolean): Promise<any | null> => {
    const response = await actionSave();
    if (!!response && !response.error) {
      const elementResponse = response?.payload['data'];
      // @ts-ignore
      AlertMessage({
        title: 'Perfetto',
        text: 'Record salvato con successo',
        icon: 'success',
        allowOutsideClick: false,
      });
      return elementResponse;
    }
    return null;
  };

  const handleSave = async (): Promise<any> => {
    return await save(false);
  };

  const handleSaveAndClose = async (): Promise<void> => {
    const elementResponse = await save(true);
    if (elementResponse) {
      return handleClose();
    }
  };

  useEffect(() => {
    setValue('module', module);
    if (selectedIds?.length) {
      const filterSelectedIds: FiltroChiave = {
        chiave: 'id',
        tipo: TYPE_SELECT,
        valore: JSON.stringify(selectedIds),
      };
      filters.push(filterSelectedIds);
    }
    setValue('keys', filters);
  }, []);

  return (
    <div
      className={`outline-soft-primary`}
      style={{
        height: '10rem',
        position: 'relative',
      }}
    >
      <FormProvider {...methods}>
        <Row>
          <Col md="12">
            <FormInput
              type="text"
              name="nome"
              label={'Inserisci il nome del filtro'}
              placeholder="Es.: Filtro anagrafiche Cagliari"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
      </FormProvider>
      <FormButtons
        id={null}
        handleClose={closeAndResetForm}
        handleSave={handleSave}
        handleSaveAndClose={handleSaveAndClose}
        isLoading={isLoading}
        isDisabled={false}
      />
    </div>
  );
};

export default FiltroForm;
