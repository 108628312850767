import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError } from '../../..';
import { URL_ANAGRAFICA, URL_TIPOLOGIA } from '../../..';

export const fetchAnagraficaTipologie = createAsyncThunk(
  'tipologieAnagrafica/fetch',
  async (_, thunkAPI): Promise<any> => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_TIPOLOGIA}`,
        params: {},
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
