import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  apiClient,
  handleError,
  RootState,
  URL_ITEM,
  URL_SELECT_ALL,
  URL_ANAGRAFICA,
  URL_AFFILIATI,
} from '../../..';

export const selectAffiliatiAll = createAsyncThunk(
  'affiliati/selectAll',
  async (options: boolean | undefined, thunkAPI): Promise<any> => {
    const state = thunkAPI?.getState() as RootState;
    const parameters = state?.affiliati?.[options ? 'paramsOption' : 'params'] ?? {};
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_ITEM}/${parameters.parentId}${URL_AFFILIATI}${URL_SELECT_ALL}`,
        params: parameters,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
