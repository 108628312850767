import { createSlice } from '@reduxjs/toolkit';
import { aziendaInfo } from '../actions';
import { FAILED, IDLE, PENDING, StateSingular, SUCCEEDED } from '../../types';
import { Azienda } from '../types';

const initialState: StateSingular<Azienda> = {
  data: null,
  loading: IDLE,
  error: null,
};

export const aziendaSlice = createSlice({
  name: 'azienda',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(aziendaInfo.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(aziendaInfo.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(aziendaInfo.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      });
  },
});
