import { createAsyncThunk } from '@reduxjs/toolkit';
import { FatturazioneInviataParams } from '../types';
import { apiClient, handleError, URL_FATTURAZIONE, URL_INVIATA_SDI } from '../../../index';
import { AxiosResponse } from 'axios';

export const inviataSdiFatturazione = createAsyncThunk(
  'fatturazione/inviata',
  async (params: FatturazioneInviataParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_FATTURAZIONE}${URL_INVIATA_SDI}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
