import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import {
  FatturazioneSchedeRequest,
  INVIO_STATO_DI,
  INVIO_STATO_ERR,
  INVIO_STATO_IN,
  INVIO_STATO_WINV,
  statisticheFatturazione,
  StatoInvio,
  StatoInvioColors,
  StatoInvioLabels,
  useAppDispatch,
} from 'store';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AdvancedPopover from './AdvancedPopover';
import { Paper, Typography } from '@mui/material';
import { GenericObject, Generics, MONTHS } from 'utils';
import Flex from './Flex';
import Box from './Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CurrencyFormatter from './CurrencyFormatter';

interface StatisticheFatturazioneProps {
  params: FatturazioneSchedeRequest;
  updateParams: (params: GenericObject) => void;
}

interface Stats {
  data: GenericObject[];
  mese: number;
  anno: number;
  onClickState: (stato: StatoInvio) => void;
}

export const StatoInvioIcons: Generics<StatoInvio, React.ReactElement> = {
  [INVIO_STATO_DI]: <AccessTimeIcon />,
  [INVIO_STATO_WINV]: <SendIcon />,
  [INVIO_STATO_IN]: <CheckCircleIcon />,
  [INVIO_STATO_ERR]: <ErrorIcon />,
};

const TableStats = ({ data, mese, anno, onClickState }: Stats) => {
  const periodoRiferimento = `${MONTHS[mese - 1]} ${anno}`;
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Flex
        borderBottom="1px solid #e0e0e0"
        paddingInline="1rem"
        paddingTop="1rem"
        paddingBottom=".5rem"
      >
        <Typography variant="h6" align="center">
          Riepiologo Fatturazione {periodoRiferimento}
        </Typography>
      </Flex>

      {data.map((row: Record<string, any>, index: number) => {
        return (
          <Flex
            cursor="pointer"
            alignItems="center"
            padding="1rem"
            borderBottom="1px solid #e0e0e0"
            key={index}
            onClick={() => onClickState(row.invio_stato as StatoInvio)}
          >
            <Box
              marginRight="1rem"
              width="1.4rem"
              textAlign="center"
              color={StatoInvioColors[row.invio_stato]}
            >
              {StatoInvioIcons[row.invio_stato as StatoInvio]}
            </Box>
            <Box flexGrow="1">
              <Box fontWeight="bold" marginBottom="2px">
                {StatoInvioLabels[row.invio_stato as StatoInvio]}
              </Box>
              <Box fontSize="12px" color="#757575">
                Netto: <CurrencyFormatter amount={row.importo_netto} currency={'EUR'} /> | Ivato:{' '}
                <CurrencyFormatter amount={row.importo_ivato} currency={'EUR'} /> | IVA:{' '}
                <CurrencyFormatter amount={row.importo_iva} currency={'EUR'} />
              </Box>
            </Box>
            <Box marginLeft="2rem" fontWeight="bold" fontSize="16px" color="#1976d2">
              {row.num_fatture}
            </Box>
          </Flex>
        );
      })}
    </Paper>
  );
};

export const StatisticheFatturazione = ({ params, updateParams }: StatisticheFatturazioneProps) => {
  const dispatch = useAppDispatch();
  const dayjsElement = dayjs.default();

  const [stats, setStats] = useState(null);

  const currentMonth = dayjsElement.month() + 1;
  const currentYear = dayjsElement.year();

  const getStatistiche = async () => {
    const response = await dispatch(
      statisticheFatturazione({
        mese: params?.mese ?? currentMonth,
        anno: params?.anno ?? currentYear,
      })
    );
    if (response && !response.error) {
      const data = response.payload.data;
      if (data && data.length > 0) {
        setStats(data);
      } else {
        setStats(null);
      }
    }
  };

  const setState = async (stato: StatoInvio) => {
    const newParams = { ...params, invio_stato: stato };
    updateParams(newParams);
    // dispatch(setFatturazioneParams(newParams));
  };

  // useEffect(() => {
  //   getStatistiche();
  // }, [params?.mese, params?.anno]);

  return (
    <Flex paddingRight="2rem" paddingBottom="0.2rem">
      <AdvancedPopover
        anchorOriginVertical="bottom"
        transformOriginVertical="top"
        anchorOriginHorizontal="left"
        transformOriginHorizontal="center"
        anchorElement={
          <InsertChartIcon sx={{ cursor: 'pointer', color: 'thistle', fontSize: '2rem' }} />
        }
        children={
          <TableStats
            data={stats ?? []}
            mese={params?.mese ?? currentMonth}
            anno={params?.anno ?? currentYear}
            onClickState={setState}
          />
        }
        onClickCallback={() => {
          getStatistiche();
        }}
      />
      <Flex cursor="pointer" fontWeight="700" fontSize="0.9rem" alignItems="center" width="7.2rem">
        Riepilogo fatture
      </Flex>
    </Flex>
  );
};

export default StatisticheFatturazione;
