import { Middleware } from '@reduxjs/toolkit';
import { getAction } from 'store/types';
import { RESETLIST } from 'store/utils';

export const resetStateMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === 'page/set') {
    const state = store.getState();
    const currentPage = state.page;
    if (currentPage.url !== action.payload?.url) {
      const resetList = getAction(currentPage.tabId, RESETLIST);
      resetList && store.dispatch(resetList());
    }
  }
  return next(action);
};
