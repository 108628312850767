import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { Perm, getPermsByModule } from 'store';
import { ANAGRAFICA, ModuleName } from 'utils';

export interface ContextProps {
  perms?: Perm;
}

export const Context = createContext<ContextProps>({});

export interface ProviderProps {
  module: ModuleName;
  children: React.ReactElement | React.ReactElement[];
}

export const FormProvider = ({ module, children }: ProviderProps) => {
  const perms = useSelector(getPermsByModule(module));
  return <Context.Provider value={{ perms }}>{children}</Context.Provider>;
};
