import { queryString } from './index';
import axios from 'axios';
import checkTokenValidity from './checkTokenValidity';
import { BASE, BASE_LOCALHOST, BASE_PROTOCOL } from '../constants';
import { MultipartUrl, ParamsUrl } from '../constants/types';
import { DATE_FORMAT, formatDate, YYYY_MM_DD } from 'utils/date';

let host = window.location.hostname;
let parts = host.split('.');

let subdomain: string;
if (parts.length >= 3) {
  subdomain = parts[0];
} else {
  subdomain = 'localhost';
}

console.log({
  subdomain,
  host,
  window,
  localtion: window.location,
  BASE_PROTOCOL,
  BASE,
  BASE_LOCALHOST,
});

const BASE_URL =
  subdomain === 'localhost' ? BASE_LOCALHOST : `${BASE_PROTOCOL}://${subdomain}-${BASE}`;

const instance = axios.create({
  baseURL: BASE_URL,
  headers: { 'content-type': 'application/json' },
});

instance.interceptors.request.use(
  async (config) => {
    const token = await checkTokenValidity();
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['X-PAGHE-APP'] = '0';
      return config;
    }
    delete instance.defaults.headers.common['Authorization'];
    return config;
  },
  (error) => {
    return Promise.reject(error); // TODO: Handle Promise return
  }
);

const serializeDates = (obj: any) => {
  const isObject = (val: any) => val && typeof val === 'object';

  if (!isObject(obj)) return obj;

  for (const key in obj) {
    if (obj[key] instanceof Date) {
      obj[key] = formatDate(obj[key], DATE_FORMAT[YYYY_MM_DD]);
    } else if (isObject(obj[key])) {
      serializeDates(obj[key]);
    }
  }
  return obj;
};

export const axiosGet = async <AxiosResponse>({
  url,
  params,
}: ParamsUrl): Promise<AxiosResponse> => {
  const query = queryString(serializeDates(params));
  try {
    return await instance.get(`${url}${query || ''}`);
  } catch (error: any) {
    return error;
  }
};

export const axiosPost = async <AxiosResponse>({
  url,
  params,
}: ParamsUrl): Promise<AxiosResponse> => {
  try {
    return await instance.post(url, serializeDates(params));
  } catch (error: any) {
    return error;
  }
};

export const axiosDelete = async <AxiosResponse>({
  url,
  params,
}: ParamsUrl): Promise<AxiosResponse> => {
  try {
    return await instance.delete(url, { data: serializeDates(params) });
  } catch (error: any) {
    return error;
  }
};

export const axiosMultipart = async <AxiosResponse>({
  url,
  formData,
}: MultipartUrl): Promise<AxiosResponse> => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return await instance.post(url, serializeDates(formData), config);
  } catch (error: any) {
    return error;
  }
};

export default class apiClient {
  static get = axiosGet;
  static post = axiosPost;
  static delete = axiosDelete;
  static multipart = axiosMultipart;
}
