import { AccessorGrid } from 'utils/types';
import {
  TabsId,
  MODULO_RIGHE_ID,
  REFERENTE_ID,
  Perm,
  AFFILIATI_ID,
  ANAGRAFICA_ID,
} from '../../store';
import { affiliato, moduloChiavi, referente } from './tables';
import { GridColumns } from '@mui/x-data-grid-pro';

export const accessorGrid = ({
  tabId,
  delete: handleDelete = () => {},
  add = () => {},
  perm,
}: AccessorGrid): GridColumns => {
  const columnModuloChiavi = moduloChiavi(handleDelete, add, perm);
  const columnReferentre = referente(handleDelete, perm);
  const columnAffiliati = affiliato(handleDelete, perm);
  const map: Record<string, any> = {
    [MODULO_RIGHE_ID]: () => columnModuloChiavi,
    [REFERENTE_ID]: columnReferentre,
    [AFFILIATI_ID]: columnAffiliati,
    [ANAGRAFICA_ID]: columnAffiliati,
  };
  return map[tabId];
};
