import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Filtro, getPermsByModule } from 'store';
import { ModelFormProps } from '../model';
import DatiGenerali from './DatiGenerail';
import ElencoFiltri from './ElencoFiltri';
import { useSelector } from 'react-redux';
import { FILTRO } from 'utils';
import { FormProvider } from '../Context';

export const FiltroEditForm = ({ id, element }: ModelFormProps<Filtro>) => {
  return (
    <FormProvider module={FILTRO}>
      <DatiGenerali />
      <ElencoFiltri />
    </FormProvider>
  );
};

export default FiltroEditForm;
