import {
  ANAGRAFICA_ID,
  MODULO_ID,
  FILTRO_ID,
  SCHEDA_ID,
  FATTURAZIONE_ID,
  TabsId,
  UTENTI_ID,
} from 'store/utils/constants/tabId';

export const LOGIN = '/auth/login';
export const LOGIN_IMPERSONA = '/auth/login/impersona';
export const START = '/';
export const HOME = '/home';
export const HOME_START = '/home/dash';
export const ANAGRAFICHE = '/anagrafiche/list';
export const MODULI = '/moduli/list';
export const FILTRI = '/filtri/list';
export const SCHEDE = '/schede/list';
export const FATTURAZIONE_LIST = '/fatturazione/list';
export const GESTIONE_UTENTI = '/gestione_utenti';

export const MAP_ROUTES_TAB_ID: Record<string, TabsId> = {
  [ANAGRAFICHE]: ANAGRAFICA_ID,
  [MODULI]: MODULO_ID,
  [FILTRI]: FILTRO_ID,
  [SCHEDE]: SCHEDA_ID,
  [FATTURAZIONE_LIST]: FATTURAZIONE_ID,
  [GESTIONE_UTENTI]: UTENTI_ID,
};
