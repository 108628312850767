import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAction,
  getSelectorListDataInfo,
  getSelectorSelectedIds,
  TabsId,
  useAppDispatch,
} from 'store';

interface IndeterminateCheckboxProps {
  tabId: TabsId;
  header?: boolean;
  id?: number | string;
  indeterminate?: any;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  option?: boolean;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({ indeterminate, header = false, tabId, id, option = false, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    const infoOption = getSelectorListDataInfo(tabId, option);

    const selectedIds = getSelectorSelectedIds(tabId) as any[];
    const [isChecked, setIsChecked] = useState(
      !header && !!selectedIds?.find((elementId) => elementId === id)
    );

    const dispatch = useAppDispatch();

    const getActionCheckbox = () => {
      if (header) {
        const action =
          Number(infoOption?.total ?? 0) > 0 && selectedIds?.length === infoOption?.total
            ? 'resetSelectedIds'
            : 'setSelectAll';
        const dispatchAction = getAction(tabId, action);
        return dispatchAction;
      }
      return getAction(tabId, 'setSelectedIds');
    };

    const onClick = async () => {
      const selectAction = getActionCheckbox();
      if (selectAction) {
        if (header) await dispatch(selectAction(option));
        else dispatch(selectAction(id));
      }
    };

    const getIsChecked = useCallback(() => {
      if (header) {
        return Number(infoOption?.total ?? 0) > 0 && selectedIds?.length === infoOption?.total;
      }
      return !!selectedIds?.includes(id);
    }, [infoOption?.total, selectedIds]);

    useEffect(() => {
      if (header) {
        resolvedRef.current.indeterminate =
          selectedIds.length > 0 && selectedIds.length !== infoOption?.total;
        resolvedRef.current.checked = selectedIds.length === infoOption?.total;
      }
      // resolvedRef.current.indeterminate =
      //   header && selectedIds.length > 0 && selectedIds.length !== infoOption?.total;
    }, [selectedIds.length]);

    return (
      <>
        <input
          {...rest}
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          checked={getIsChecked()}
          defaultValue="false"
          onClick={onClick}
          onChange={(ev: any) => console.log({ ev })}
        />
      </>
    );
  }
);

export default IndeterminateCheckbox;
