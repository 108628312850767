import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModuloFetchParams } from '../types';
import { handleError, URL_MODULO, URL_GET, URL_V1 } from '../../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../../utils';

export const fetchModulo = createAsyncThunk(
  'modulo/fetch',
  async ({ id, ...params }: ModuloFetchParams, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_MODULO}${URL_GET}/${id}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
