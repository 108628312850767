import { FormProvider, useForm } from 'react-hook-form';
import duplicaSchedaSchema from './schemaDuplica';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SCHEDA_ID,
  Scheda,
  duplicaScheda,
  fetchFiltro,
  fetchSchede,
  getSelectorElementById,
  setSchedaParams,
  useAppDispatch,
} from 'store';
import * as dayjs from 'dayjs';
import { Box, FormInput, Modal } from 'components';
import { formatNumberMonthToString } from 'helpers/date';
import { Col, Row } from 'react-bootstrap';
import { MONTHS } from 'utils';
import { useEffect, useLayoutEffect, useState } from 'react';
import { AlertMessage } from 'components/AlertMessage';

interface Props {
  id: number;
  isOpen: boolean;
  onClose: () => void;
}

const DuplicaScheda = ({ id, isOpen, onClose }: Props) => {
  const scheda = getSelectorElementById<Scheda>(SCHEDA_ID, id);

  const dayjsElement = dayjs.default();
  const currentMonth = dayjsElement.month();
  const currentYear = dayjsElement.year();

  const [confirmCheckValue, setConfirmCheckValue] = useState<string>('');

  const emptyElement = {
    nome: scheda?.nome,
    origine_id: id,
    mese: currentMonth,
    anno: currentYear,
    duplica_dati: false,
    conferma: '',
  };

  const methods = useForm<any>({
    mode: 'onBlur',
    defaultValues: emptyElement,
    resolver: yupResolver(duplicaSchedaSchema),
  });

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    trigger,
    clearErrors,
  } = methods;

  const handleClose = () => {
    onClose();
    clearErrors();
    reset({});
  };

  const duplica = async () => {
    const values = getValues();
    const { conferma, ...request } = values;
    const response = await dispatch(duplicaScheda(request));
    if (response && !response.error) {
      handleClose();
      dispatch(setSchedaParams({ mese: request.mese, anno: request.anno }));
      dispatch(fetchSchede());
      AlertMessage({
        title: 'Perfetto',
        html: '<span>Scheda duplicata con successo.</span>',
        icon: 'success',
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
    }
  };

  const handleConfirm = async () => {
    const noError = await trigger();
    if (noError) {
      if (scheda && !scheda.filtro && scheda.filtro_id) {
        AlertMessage({
          title: 'Filtro scheda non più presente',
          html: '<span>Puoi continuare nella duplicazione ma nella nuova scheda verranno importate tutte le <b>anagrafiche</b> a sistema.</span>',
          icon: 'info',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: 'Annulla',
          confirmButtonText: 'Procedi',
          preConfirm: duplica,
        });
      } else {
        duplica();
      }
    }
  };

  useEffect(() => {
    if (scheda && isOpen) {
      setValue('nome', scheda.nome);
      const meseScheda = Number(scheda.mese ?? currentMonth);
      const annoScheda = Number(scheda.anno ?? currentYear);

      setValue('mese', meseScheda === 12 ? 1 : meseScheda + 1);
      setValue('anno', meseScheda === 12 ? annoScheda + 1 : annoScheda);
      setValue('origine_id', scheda.id);
      setValue('filtro', scheda?.filtro?.id ? { id: scheda.filtro.id } : null);
      setValue('modulo', scheda?.modulo?.id ? { id: scheda.modulo.id } : null);
    }
  }, [isOpen, id]);

  return (
    <Modal
      title={`Duplicazione scheda <strong style="text-transform: uppercase">${formatNumberMonthToString(
        scheda?.mese ?? currentMonth
      )} / ${scheda?.anno ?? currentYear}</strong>`}
      open={isOpen}
      textConfirm="Duplica scheda"
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      maxWidth="md"
      sx={{ '& .MuiDialog-paper': { width: '45%', maxWidth: 'none' } }}
    >
      <div>
        <FormProvider {...methods}>
          <Row>
            <Col md={4}>
              <FormInput
                type="text"
                name="nome"
                label={'Nome'}
                placeholder="Scheda Chieri"
                minHeight="6.25rem"
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={4}>
              <FormInput
                type="select"
                register={register}
                name="mese"
                label={'Mese'}
                minHeight="6.25rem"
                options={MONTHS.map((month, index) => ({ id: index + 1, descrizione: month }))}
                errors={errors}
              />
            </Col>
            <Col md={4}>
              <FormInput
                type="number"
                register={register}
                name="anno"
                label={'Anno'}
                minHeight="6.25rem"
                min={currentYear - 30}
                max={currentYear + 30}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInput
                type="switch"
                name={'duplica_dati'}
                label="Duplica anche i dati della scheda"
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Box textAlign="center" marginBottom="1rem" marginTop="1rem">
                Digitare <strong>CONFERMA</strong> e premere <strong>Duplica scheda</strong> per
                duplicare la scheda
              </Box>
              <FormInput
                type="text"
                name="conferma"
                label={''}
                placeholder="CONFERMA"
                errors={errors}
                register={register}
              />
            </Col>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default DuplicaScheda;
