import React, { useRef } from 'react';
import { useSearchAddress } from './useSearchAddress';
import { OptionField } from './optionField';
import { FormInput } from 'components';
import { useFormContext } from 'react-hook-form';
import Icon from '@ailibs/feather-react-ts';

interface SearchAddressProps {
  label: string;
  name: string;
  fieldCap: string;
  fieldCitta: string;
  fieldProvincia: string;
  fieldProvinciaId?: string;
  fieldRegione: string;
  fieldRegioneId?: string;
  disabled?: boolean;
}

export const SearchAddress = ({
  label,
  name,
  fieldCap,
  fieldCitta,
  fieldProvincia,
  fieldProvinciaId,
  fieldRegione,
  fieldRegioneId,
  disabled = false,
}: SearchAddressProps) => {
  const { register } = useFormContext();

  const autocompleteRef = useRef<HTMLDivElement>(null);
  const {
    isOpen,
    optionsPresentation,
    optionSelected,
    resetSelection,
    handleSearch,
    selectAddress,
  } = useSearchAddress({
    name,
    fieldCap,
    fieldCitta,
    fieldProvincia,
    fieldProvinciaId,
    fieldRegione,
    fieldRegioneId,
    disabled,
    ref: autocompleteRef,
  });
  const renderOption = () =>
    optionsPresentation?.map((presentation) => (
      <OptionField key={presentation.id} presentation={presentation} select={selectAddress} />
    ));

  return (
    <div
      ref={autocompleteRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '5rem',
        paddingTop: '0rem',
        paddingBottom: '0px',
      }}
    >
      <FormInput
        type="text"
        name={name}
        label={label}
        placeholder="Inserisci CAP o città"
        onChange={!disabled ? handleSearch : () => void 0}
        minHeight="6.25rem"
        action={
          <div
            className="float-end text-muted text-unline-dashed ms-1"
            style={{ cursor: 'pointer' }}
            onClick={!disabled ? resetSelection : () => void 0}
          >
            Svuota Ricerca
          </div>
        }
        startIcon={<Icon name="search" />}
        register={register}
        disabled={disabled}
      />
      {isOpen && !disabled ? (
        <div
          style={{
            minWidth: '100%',
            width: 'max-content',
            height: '10rem',
            zIndex: 1,
            backgroundColor: 'rgb(251, 251, 251)',
            borderRadius: '5px',
            border: '1px solid #e3e3e3',
            position: 'absolute',
            top: '4.5rem',
            overflow: 'auto',
          }}
        >
          {renderOption()}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchAddress;
