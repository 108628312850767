import React from 'react';

interface CurrencyFormatterProps {
  amount: number;
  currency: string;
  locale?: string;
}

const CurrencyFormatter: React.FC<CurrencyFormatterProps> = ({
  amount,
  currency,
  locale = 'it-IT',
}) => {
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount);

  return <span>{formattedAmount}</span>;
};

export default CurrencyFormatter;
