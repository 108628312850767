import { Button } from '@mui/material';
import CurrencyFormatter from 'components/CurrencyFormatter';
import React from 'react';

interface Props {
  num_fatture: number;
  importo_netto: number;
  importo_ivato: number;
  importo_iva: number;
  actionCallback?: () => void;
}

const FattureStats = ({
  num_fatture,
  importo_netto,
  importo_ivato,
  importo_iva,
  actionCallback,
}: Props) => {
  // Dati fake da utilizzare se non vengono forniti stats

  console.log({ num_fatture, importo_netto, importo_ivato, importo_iva });

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '24px',
        maxWidth: '600px',
        margin: '0 auto',
      }}
    >
      <h2
        style={{
          fontSize: '1.5rem',
          margin: '0 0 16px',
          color: '#333',
        }}
      >
        Dettaglio fatture generate
      </h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '16px',
        }}
      >
        <StatItem label="Numero di fatture" value={num_fatture} />
        <StatItem label="Importo netto" value={Number(importo_netto).toFixed(2)} />
        <StatItem label="Importo ivato" value={Number(importo_ivato).toFixed(2)} />
        <StatItem label="Importo IVA" value={Number(importo_iva).toFixed(2)} />
      </div>

      {actionCallback ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '24px',
          }}
        >
          <Button variant="contained" color="primary" onClick={actionCallback}>
            Visualizza Fatture
          </Button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

interface StatItemProps {
  label: string;
  value: string | number;
}

const StatItem = ({ label, value }: StatItemProps) => (
  <div
    style={{
      backgroundColor: '#f5f5f5',
      padding: '16px',
      borderRadius: '4px',
    }}
  >
    <p
      style={{
        fontSize: '0.9rem',
        color: '#666',
        margin: '0 0 8px',
      }}
    >
      {label}
    </p>
    <p
      style={{
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: '#333',
        margin: '0',
      }}
    >
      <CurrencyFormatter amount={Number(value)} currency={'EUR'} />
    </p>
  </div>
);

export default FattureStats;
