import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../..';

export const getContoState = (state: RootState) => state.conto;

export const getContoLoader = createSelector(
  getContoState,
  (contoState) => contoState.loading === PENDING
);

export const getContoFailed = createSelector(
  getContoState,
  (contoState) => contoState.loading === FAILED
);

export const getContoSuccess = createSelector(
  getContoState,
  (contoState) => contoState.loading === SUCCEEDED
);

export const getContoError = createSelector(getContoState, (contoState) => contoState?.error);

export const getConto = createSelector(getContoState, (contoState) => contoState?.data);
