import { FormInput, SelectForm } from 'components';
import { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Context } from '../Context';

export const DatiContatto = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const { perms } = useContext(Context);

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Dati Contatto</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={4}>
            <FormInput
              type="text"
              name="email"
              label="E-mail"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="email"
              name="pec"
              label="PEC"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="text"
              name="web"
              label="Sito web"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormInput
              type="text"
              name="telefono"
              label="N. Telefono"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="text"
              name="cellulare"
              label="N.Cellulare"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="text"
              name="fax"
              label="FAX"
              placeholder="011 456789"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DatiContatto;
