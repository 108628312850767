import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

interface Props {
  rows: Record<string, any>[];
  columns: ColDef[];
  onCellValueChanged: (params: any) => void;
}

export const DataGridEditable: React.FC<Props> = ({ rows, columns, onCellValueChanged }: Props) => {
  const [rowData, setRowData] = React.useState<Record<string, any>[]>([]);

  const [columnDefs, setColumnDefs] = React.useState<ColDef[]>([]);

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div
      className="app-container"
      style={{ display: 'flex', flexDirection: 'column', height: '70vh' }}
    >
      <div className="ag-theme-alpine" style={{ flexGrow: 1 }}>
        <AgGridReact
          rowData={rows}
          columnDefs={columns}
          onCellValueChanged={(params: any) => onCellValueChanged(params)}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default DataGridEditable;
