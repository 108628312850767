import Icon from '@ailibs/feather-react-ts';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { MenuItem } from 'utils/types';

interface DropdownMenuProps {
  menu: MenuItem[];
  styleToggle?: React.CSSProperties;
}

export const DropdownMenu = ({ menu, styleToggle }: DropdownMenuProps) => {
  return (
    <Dropdown className="d-inline-block" align="start">
      <Dropdown.Toggle
        variant="soft-primary"
        className="cursor-pointer rounded-pill"
        style={styleToggle}
      >
        <i className="uil uil-ellipsis-v"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {menu.map(
          (
            { onClick, handleClickItem, title, key, icon, color, id, disabled }: MenuItem,
            index
          ) => {
            return (
              <Dropdown.Item
                key={key ?? `menu-${index}`}
                onClick={() => (handleClickItem && id ? handleClickItem(id) : onClick())}
                color={color}
                disabled={disabled}
              >
                {icon && (
                  <Icon
                    color={color}
                    name={icon}
                    size={14}
                    className={classNames('align-self-center, icon-xs me-1')}
                  />
                )}
                <span> {title} </span>
              </Dropdown.Item>
            );
          }
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
