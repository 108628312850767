import { createSlice } from '@reduxjs/toolkit';
import { fetchUser } from '..';
import { IDLE, PENDING, FAILED, SUCCEEDED } from '../../types';
import { User, UserState } from '../types';

const initialUser: User = {
  id: 0,
  name: '',
  lastname: '',
  email: '',
  role: '',
  perms: [],
};

const initialState: UserState = {
  statusCode: null,
  data: initialUser,
  loading: IDLE,
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        const statusCode = payload?.statusCode;
        const data = payload?.data;
        state.statusCode = statusCode;
        state.data = data;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.data = initialUser;
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      });
  },
});
