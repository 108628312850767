import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchAnagrafica,
  fetchScheda,
  getAnagrafica,
  getAnagraficaLoader,
  getScheda,
  reloadAnagraficaRigaScheda,
  resetAnagrafica,
  saveScheda,
  useAppDispatch,
} from 'store';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Flex } from 'components';

interface AnagraficaInfoParams {
  rigaScheda: any;
}

export const AnagraficaInfo = ({ rigaScheda }: AnagraficaInfoParams) => {
  const anagraficaLoader = useSelector(getAnagraficaLoader);
  const anagrafica = useSelector(getAnagrafica);
  const scheda = useSelector(getScheda);

  const [success, setSuccess] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const updateRighaScheda = async () => {
    if (anagrafica && scheda) {
      const request = { ...rigaScheda, ragione_sociale: anagrafica.ragione_sociale };
      const response = await dispatch(
        reloadAnagraficaRigaScheda({ scheda_id: scheda.id as number, row: request })
      );
      if (response) {
        dispatch(
          fetchScheda({
            id: scheda.id as number,
            edit_mode: false,
          })
        );
        setSuccess(true);
      }
    }
  };

  const closeAlert = () => setSuccess(false);

  useEffect(() => {
    dispatch(
      fetchAnagrafica({
        id: rigaScheda.anagrafica_id,
        edit_mode: false,
      })
    );

    return () => {
      dispatch(resetAnagrafica());
    };
  }, []);

  return (
    <Box sx={{ padding: '1rem' }}>
      {anagraficaLoader || !anagrafica ? (
        <React.Fragment>
          <Skeleton width="100%">
            <Typography variant="h3">.</Typography>
          </Skeleton>
          <Skeleton width="100%">
            <Typography variant="h3">.</Typography>
          </Skeleton>
          <Skeleton width="100%">
            <Typography variant="h3">.</Typography>
          </Skeleton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container spacing={2} sx={{ backgroundColor: 'white', mt: 2, mb: 0 }}>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: 17, ml: '1rem' }}
                variant="body1"
                component="div"
              >
                Info anagrafica
              </Typography>

              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Ragione Sociale"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={anagrafica.ragione_sociale}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="P.IVA"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={anagrafica.piva}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Indirizzo"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={`${anagrafica.indirizzo} ${anagrafica.civico ?? ''} - ${
                      anagrafica.cap
                    }, 
                ${anagrafica.localita} (${anagrafica.provincia})`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="E-mail"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={'campo da aggiungere'} /*anagrafica.email*/
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: 17, ml: '1rem' }}
                component="p"
                variant="body1"
              >
                Dati fatturazione
              </Typography>
              <List dense>
                {anagrafica.padre_ragione_sociale ? (
                  <ListItem>
                    <ListItemText
                      primary="Affiliato di"
                      primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                      secondary={anagrafica.padre_ragione_sociale} /*anagrafica.pec*/
                    />
                  </ListItem>
                ) : (
                  <div />
                )}

                <ListItem>
                  <ListItemText
                    primary="PEC"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={'campo da aggiungere'} /*anagrafica.pec*/
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="P.IVA"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={anagrafica.piva}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Cod. Destinatario"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={'campo da aggiungere'} /*anagrafica.codice_destinatario */
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: 17, ml: '1rem' }}
                component="p"
                variant="body1"
              >
                Dati bancari
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Nome banca"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={'campo da aggiungere'} /*anagrafica.nome_banca*/
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="IBAN"
                    primaryTypographyProps={{ fontSize: 14, fontWeight: '600' }}
                    secondary={'campo da aggiungere'} /*anagrafica.iban*/
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Divider />
          <Flex justifyContent={'center'}>
            <Button onClick={updateRighaScheda}>
              <RefreshIcon sx={{ fontSize: '1.5rem', pr: '.5rem' }} /> Aggiorna info anagrafica{' '}
            </Button>
          </Flex>
        </React.Fragment>
      )}
      {success && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={closeAlert}
          autoHideDuration={3000}
        >
          <Alert severity="success" onClose={closeAlert}>
            Allineamento effettuato con successo
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default AnagraficaInfo;
