import { useOnClickOutside } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCap,
  getAdderssPresentation,
  getAddressData,
  resetCapSearch,
  useAppDispatch,
} from 'store';

interface SearchProps {
  name: string;
  fieldCap: string;
  fieldCitta: string;
  fieldProvincia: string;
  fieldProvinciaId?: string;
  fieldRegione: string;
  fieldRegioneId?: string;
  disabled?: boolean;
  ref: React.RefObject<HTMLDivElement>;
}

export const useSearchAddress = ({
  ref,
  name,
  fieldCap,
  fieldCitta,
  fieldProvincia,
  fieldProvinciaId,
  fieldRegione,
  fieldRegioneId,
  disabled = false,
}: SearchProps) => {
  const { setValue, getValues, watch } = useFormContext();
  const dispatch = useAppDispatch();
  const [address, setAddress] = useState<any | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState<any | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const cap = watch(fieldCap);
  const provincia = watch(fieldProvincia);
  const citta = watch(fieldCitta);
  const debouncedSearchTerm = useDebounce({ searchTerm, delay: 500 });
  const options: any = useSelector(getAddressData);
  const optionsPresentation = useSelector(getAdderssPresentation);

  const handleSearch = (ev: any) => {
    const value = ev.target.value;
    setSearchTerm(value);
  };

  const selectAddress = (id: any) => {
    const address = options[id];
    setAddress(address);
    const option = optionsPresentation[id];
    setOptionSelected(option);
    setValue(name, option.description);
    setInputValue(option.description);
    dispatch(resetCapSearch());
  };

  const resetSelection = () => {
    setValue(name, null);
    setOptionSelected(undefined);
    dispatch(resetCapSearch());
  };
  const closeOptionList = () => {
    dispatch(resetCapSearch());
    setValue(name, inputValue);
  };
  useOnClickOutside(ref, closeOptionList);

  useEffect(() => {
    if (cap && provincia && citta) {
      const selected = { id: 0, description: `${cap}, ${citta} (${provincia.id ?? provincia})` };
      //setOptionSelected(selected);
      setValue(name, selected.description);
      setInputValue(selected.description);
    } else {
      setValue(name, null);
      setInputValue(null);
      dispatch(resetCapSearch());
    }
  }, [cap, provincia, citta]);
  useEffect(() => setIsOpen(options.length && !disabled), [options]);

  useEffect(() => {
    if (debouncedSearchTerm !== null && debouncedSearchTerm.length >= 3) {
      dispatch(fetchCap({ cap: debouncedSearchTerm }));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setValue(fieldCap, address ? address.cap : '');
    setValue(fieldCitta, address ? address.citta : '');
    setValue(fieldProvincia, address ? address.provincia : undefined);
    fieldProvinciaId && setValue(fieldProvinciaId, address ? address.sigla_provincia : undefined);
    setValue(fieldRegione, address ? address.regione : undefined);
    fieldRegioneId && setValue(fieldRegioneId, address ? address.regione_id : undefined);
  }, [address]);

  return {
    isOpen,
    optionsPresentation,
    optionSelected,
    address,
    resetSelection,
    handleSearch,
    selectAddress,
  };
};
