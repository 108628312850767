import { TEXT } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '5rem',
  },
  {
    Header: 'Ragione Sociale',
    accessor: 'ragione_sociale',
    sort: true,
    filterType: TEXT,
    width: '40rem',
  },
  {
    Header: 'P.IVA',
    accessor: 'piva',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'C.Fiscale',
    accessor: 'codice_fiscale',
    sort: true,
    filterType: TEXT,
    width: '12rem',
  },
  {
    Header: 'Città',
    accessor: 'localita',
    sort: true,
    filterType: TEXT,
    width: '12rem',
  },
];

const remainingColumns: ColumnCustomHeader[] = [
  {
    Header: 'CAP',
    accessor: 'cap',
    sort: true,
    filterType: TEXT,
    width: '7rem',
  },
  {
    Header: 'Provincia',
    accessor: 'provincia',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'Indirizzo',
    accessor: 'indirizzo',
    sort: true,
    filterType: TEXT,
    width: '35rem',
  },
  {
    Header: 'Telefono',
    accessor: 'telefono',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'Cellulare',
    accessor: 'cellulare',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
];

export const anagrafiche = {
  preview: baseColumns,
  complete: [...baseColumns, ...remainingColumns],
};

export const anagraficheGenericGrid = () => [
  {
    id: 'id',
    label: 'ID',
    minWidth: 70,
  },
  {
    id: 'ragione_sociale',
    label: 'Ragione Sociale',
    minWidth: 400,
  },
  {
    id: 'piva',
    label: 'P.IVA',
    minWidth: 200,
  },
  {
    id: 'codice_fiscale',
    label: 'C.Fiscale',
    minWidth: 200,
  },
  {
    id: 'localita',
    label: 'Città',
    minWidth: 200,
  },
];
