import { ActionType } from '../../..';
import {
  createAnagrafica,
  deleteAnagrafica,
  fetchAnagrafica,
  resetAllAnagrafica,
  resetAllOptionAnagrafica,
  resetAnagrafica,
} from '../actions';

export const AnagraficaMapAction: Record<ActionType | string, any> = {
  fetch: fetchAnagrafica,
  submit: createAnagrafica,
  delete: deleteAnagrafica,
  reset: resetAnagrafica,
  resetAll: resetAllAnagrafica,
  resetAllOption: resetAllOptionAnagrafica,
};
