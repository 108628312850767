import { getUtente, getUtenteFailed, getUtenteLoader, getUtenteSuccess } from '../selectors';

export const UtenteMapSelector = {
  loadingElement: getUtenteLoader,
  successElement: getUtenteSuccess,
  failedElement: getUtenteFailed,
  element: getUtente,
};

export default UtenteMapSelector;
