import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Perm } from 'store';

export const referente = (handleDelete: (id: number) => void, perm?: Perm) => {
  return [
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      width: 60,
      sortable: false,
      filterable: false,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDelete(params.row.id)}
          nonce=""
          onResize={() => void 0}
          onResizeCapture={() => void 0}
          disabled={perm && !perm.can_write}
        />,
      ],
    },
    { field: 'id', headerName: 'ID', width: 60, editable: false, type: 'number' },
    { field: 'titolo', headerName: 'Titolo', width: 140, editable: true },
    { field: 'ruolo', headerName: 'Ruolo', width: 140, editable: true },
    { field: 'nome', headerName: 'Nome', width: 230, editable: true },
    { field: 'cognome', headerName: 'Cognome', width: 230, editable: true },
    { field: 'telefono', headerName: 'Telefono', width: 200, editable: true },
    { field: 'cellulare', headerName: 'Cellulare', width: 200, editable: true },
    { field: 'email', headerName: 'E-mail', width: 230, editable: true },
    {
      field: 'presente_whatsapp',
      headerName: 'Su Whatsapp',
      width: 120,
      editable: true,
      type: 'boolean',
      valueGetter: (value: any) => !!Number(value.row['presente_whatsapp']),
    },
  ];
};
