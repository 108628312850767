import { SchedaIdParams } from '../types';
import { handleError, URL_SCHEDA, URL_VALIDATE } from '../../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../../utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const validateScheda = createAsyncThunk(
  'scheda/validate',
  async ({ id, tipo }: SchedaIdParams, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_SCHEDA}${URL_VALIDATE}/${id}`,
        params: { tipo },
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      console.log({ error });
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
