import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { addTokenCookies, apiClient, handleError } from '../../utils';

import { URL_AZIENDA, URL_INFO } from '../../utils/constants';

export const aziendaInfo = createAsyncThunk('azienda/info', async (_, thunkAPI) => {
  try {
    const response = await apiClient.get<AxiosResponse>({
      url: `${URL_AZIENDA}${URL_INFO}`,
    });
    if (response.status === 200) {
      return response.data;
    }
    return handleError(response.status, thunkAPI, response.data?.status);
  } catch (error: any) {
    return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
  }
});
