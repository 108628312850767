import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setFatturazioneParams } from '.';
import { apiClient, handleError, RootState, SchedaFatturazioneRequest } from '../../..';
import { URL_FATTURAZIONE, URL_SEND } from '../../..';

export const sendFatturazioni = createAsyncThunk(
  'fatturazioni/send',
  async (params: SchedaFatturazioneRequest, thunkAPI): Promise<any> => {
    try {
      const { scheda_id } = params;
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_FATTURAZIONE}${URL_SEND}/${scheda_id}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
