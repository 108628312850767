import { ErrorState, resetError, setError } from '../';
import { createSlice } from '@reduxjs/toolkit';
import { ERRORS, GENERIC_ERROR } from '../..';

const initialState: ErrorState = {
  errorStatus: null,
  errorTitle: null,
  errorMessage: null,
  lock: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setError, (state, { payload: { statusCode, error, lock } }) => {
        state.errorStatus = statusCode;
        state.errorTitle = statusCode || GENERIC_ERROR;
        state.errorMessage = error;
        state.lock = lock ?? null;
      })
      .addCase(resetError, (state, { payload }) => {
        state.errorStatus = null;
        state.errorTitle = null;
        state.errorMessage = null;
        state.lock = null;
      });
  },
});
