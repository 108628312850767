import { UtenteSaveParams } from '../types';
import { apiClient, handleError, URL_UTENTE, URL_SAVE } from '../../../index';
import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const saveUtente = createAsyncThunk(
  'utente/save',
  async (params: UtenteSaveParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_UTENTE}${URL_SAVE}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
