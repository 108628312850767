import { SelectorType } from '../../utils/constants/selectorTypes';
import { getConto, getContoFailed, getContoLoader, getContoSuccess } from '../selectors';

export const ContoMapSelector = {
  loadingElement: getContoLoader,
  successElement: getContoSuccess,
  failedElement: getContoFailed,
  element: getConto,
};

export default ContoMapSelector;
