import { createSelector } from 'reselect';
import { Fatturazione } from '../..';
import { PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getFatturazioniState = (state: RootState) => state.fatturazioni;

export const getFatturazioniLoader = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loading === PENDING
);

export const getFatturazioniFailed = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loading === FAILED
);

export const getFatturazioniSuccess = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loading === SUCCEEDED
);

export const getSchedeFatturazioneLoader = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingSchede === PENDING
);

export const getSchedeFatturazioneFailed = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingSchede === FAILED
);

export const getSchedeFatturazioneSuccess = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingSchede === SUCCEEDED
);

export const getFatturazioniOptionLoader = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingOption === PENDING
);

export const getFatturazioniOptionFailed = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingOption === FAILED
);

export const getFatturazioniOptionSuccess = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingOption === SUCCEEDED
);

export const getFatturazioniEnumLoader = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingEnum === PENDING
);

export const getFatturazioniEnumFailed = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingEnum === FAILED
);

export const getFatturazioniEnumSuccess = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState.loadingEnum === SUCCEEDED
);

export const getFatturazioniError = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.error
);

export const getFatturazioni = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.data
);

export const getSchedeFatturazione = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.schede
);

export const getFatturazioniInfo = createSelector(
  getFatturazioniState,
  (fatturazioniState): Info => fatturazioniState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getFatturazioniOption = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.dataOption
);

export const getFatturazioniOptionInfo = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.infoOption
);

export const getFatturazioniKeyValues = createSelector(
  getFatturazioni,
  (fatturazioni) =>
    fatturazioni?.map((fatturazione: Fatturazione) => ({
      id: fatturazione.id,
      descrizione: '',
    })) ?? []
);
export const getFatturazioniOptionKeyValues = createSelector(
  getFatturazioniOption,
  (fatturazioni) =>
    fatturazioni?.map((fatturazione: Fatturazione) => ({
      id: fatturazione.id,
      descrizione: '',
    })) ?? []
);

export const getFatturazioneById = (id: string | number) =>
  createSelector(getFatturazioni, (fatturazioni) =>
    fatturazioni?.find((fatturazioneElement: any) => fatturazioneElement.id === id)
  );

export const getFatturazioneOptionById = (id: string | number) =>
  createSelector(getFatturazioniOption, (fatturazioni) =>
    fatturazioni?.find((fatturazioneElement: any) => fatturazioneElement.id === id)
  );

export const getFatturazioniParams = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.params
);

export const getFatturazioniParamsOption = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.paramsOption
);

export const getFatturazioniSelectedIds = createSelector(
  getFatturazioniState,
  (fatturazioniState) => fatturazioniState?.selectedIds
);
