/* eslint-disable react/jsx-no-useless-fragment */
import { Alert, AlertProps, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage, getErrorStatus, resetError } from 'store';

export const ErrorAlert = () => {
  const errorMessage = useSelector(getErrorMessage);
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(resetError());
  };

  return errorMessage ? (
    <Snackbar
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={closeAlert}
      autoHideDuration={10000}
    >
      <Alert severity="error" onClose={closeAlert}>
        {errorMessage}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};

export default ErrorAlert;
