import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModuloCreateParams } from '../types';
import { apiClient, handleError, URL_MODULO, URL_CREATE, fetchModuliOptions } from '../../../index';
import { AxiosResponse } from 'axios';

export const createModulo = createAsyncThunk(
  'modulo/create',
  async (params: ModuloCreateParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_MODULO}${URL_CREATE}`,
        params,
      });
      if (response.status === 200) {
        thunkAPI.dispatch(fetchModuliOptions());
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
