import { AFFILIATI, MODULO, SCHEDA } from './../../utils/constants/modules';
import { useSelector } from 'react-redux';
import { LoginSelector } from '../auth/types/selectors';
import { AnagraficaMapSelector } from '../anagrafiche/anagrafica/types';
import { AnagraficheMapSelector } from '../anagrafiche/anagrafiche/types';
import { selectorTypes } from '../utils/constants/selectorTypes';
import { getUser } from '../user';
import { REFERENTE_ID, TabsId, TABS_IDS, CONTO_ID, VALIDAZIONE_SCHEDA_ID } from 'store/utils';
import { Info, KeyValue } from '.';
import { FiltriMapSelector, FiltroMapSelector } from 'store/filtri';
import {
  ModuliMapSelector,
  ModuloMapSelector,
  ModuloChiaveMapSelector,
  ModuloChiaviMapSelector,
} from 'store/moduli';
import { SchedaMapSelector, SchedeMapSelector } from 'store/schede';
import { ReferenteMapSelector, ReferentiMapSelector } from 'store/anagrafiche';
import { FatturazioneMapSelector, FatturazioniMapSelector } from 'store/fatturazioni';
import { UtenteMapSelector, UtentiMapSelector } from 'store/utenti';
import { ContoMapSelector } from 'store/conto';
import { TabelleIvaMapSelector } from 'store/tabIva';
import { AffiliatiMapSelector } from 'store/anagrafiche/affiliati';

export const mapGlobalSelector: Record<any, any> = {
  LOGIN: { ...LoginSelector },
  // REGISTER: {
  //   loading: getAuthLoader,
  //   success: registeredSuccess,
  //   data: getUser,
  //   params: getUser,
  //   info: getUser,
  // },
  // CONFIRM: {
  //   loading: getAuthLoader,
  //   success: getAuthSuccess,
  //   data: getUser,
  //   params: getUser,
  //   info: getUser,
  // },
  ANAGRAFICA: { ...AnagraficaMapSelector, ...AnagraficheMapSelector },
  AFFILIATI: { ...AnagraficaMapSelector, ...AffiliatiMapSelector },
  FILTRO: { ...FiltroMapSelector, ...FiltriMapSelector },
  MODULO: { ...ModuloMapSelector, ...ModuliMapSelector },
  MODULO_RIGHE: { ...ModuloChiaveMapSelector, ...ModuloChiaviMapSelector },
  SCHEDA: { ...SchedaMapSelector, ...SchedeMapSelector },
  REFERENTE: { ...ReferenteMapSelector, ...ReferentiMapSelector },
  FATTURAZIONE: { ...FatturazioneMapSelector, ...FatturazioniMapSelector },
  UTENTI: { ...UtenteMapSelector, ...UtentiMapSelector },
  CONTO: { ...ContoMapSelector },
  TAB_IVA: { ...TabelleIvaMapSelector },
  VALIDAZIONE_SCHEDA_ID: {},
  DRIVE_TEST: {},
};

export const getSelectorLoading = (tabId: TabsId, options = false): boolean =>
  useSelector(
    mapGlobalSelector[tabId]
      ? mapGlobalSelector[tabId][options ? selectorTypes.loadingOption : selectorTypes.loading]
      : () => false
  );
export const getSelectorSuccess = (tabId: TabsId, options = false) =>
  useSelector(
    mapGlobalSelector[tabId]
      ? mapGlobalSelector[tabId][options ? selectorTypes.successOption : selectorTypes.success]
      : () => false
  );
export const getSelectorListData = (tabId: TabsId, options = false) => {
  return useSelector(
    mapGlobalSelector[tabId]
      ? mapGlobalSelector[tabId][options ? selectorTypes.dataOption : selectorTypes.data]
      : () => []
  );
};
export const getSelectorListDataParams = (tabId: TabsId, options = false): Record<string, any> =>
  useSelector(
    mapGlobalSelector[tabId]
      ? mapGlobalSelector[tabId][options ? selectorTypes.paramsOption : selectorTypes.params]
      : () => ({})
  );
export const getSelectorListDataInfo = (tabId: TabsId, options = false): Info =>
  useSelector(
    mapGlobalSelector[tabId]
      ? mapGlobalSelector[tabId][options ? selectorTypes.infoOption : selectorTypes.info]
      : () => ({})
  );
export const getSelectorSelectedIds = (tabId: TabsId): any[] =>
  useSelector(
    mapGlobalSelector[tabId] ? mapGlobalSelector[tabId][selectorTypes.selectedIds] : () => []
  );

export const getSelectorLoadingElement = (tabId: TabsId): boolean =>
  useSelector(
    mapGlobalSelector[tabId] ? mapGlobalSelector[tabId][selectorTypes.loadingElement] : () => false
  );
export const getSelectorElement = <T>(tabId: TabsId): T => {
  return useSelector(
    mapGlobalSelector[tabId] ? mapGlobalSelector[tabId][selectorTypes.element] : () => undefined
  );
};

const TABS_ID_NO_ELEMENT = [CONTO_ID, VALIDAZIONE_SCHEDA_ID];
export const getSelectorElementById = <T>(tabId: TabsId, id: string | number, options = false): T =>
  useSelector(
    TABS_ID_NO_ELEMENT.includes(tabId)
      ? () => undefined
      : mapGlobalSelector[tabId][
          options ? selectorTypes.elementOptionById : selectorTypes.elementById
        ](id)
  );

export const getSelectorOptions = (tabId: TabsId, options = false): KeyValue[] =>
  useSelector(
    mapGlobalSelector[tabId][
      options ? selectorTypes.selectOptionKeyValues : selectorTypes.selectKeyValues
    ]
  ) ?? [];
