import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Anagrafica, Perm } from 'store';
import { MenuItem } from 'utils/types';
import { Dropdown } from 'react-bootstrap';
import { DropdownMenu } from 'components';

export const affiliato = (handleDelete: (id: number) => void, perm?: Perm) => {
  return [
    {
      field: 'actions',
      headerName: 'Azioni',
      type: 'actions',
      width: 150,
      sortable: false,
      filterable: false,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => handleDelete(params.row.id)}
          nonce=""
          onResize={() => void 0}
          onResizeCapture={() => void 0}
          disabled={perm && !perm.can_write}
        />,
      ],
    },
    { field: 'id', headerName: 'ID', width: 150, editable: false, type: 'number' },
    { field: 'ragione_sociale', headerName: 'Rag. sociale', width: 400, editable: false },
    { field: 'piva', headerName: 'P.IVA', width: 240, editable: false },
    { field: 'codice_fiscale', headerName: 'C.fiscale', width: 240, editable: false },
    { field: 'localita', headerName: 'Città', width: 240, editable: false },
  ];
};

export const affiliatiGenericGrid = (handleDelete: (id: number) => void, perm?: Perm) => {
  return [
    {
      id: 'action',
      label: '',
      minWidth: 70,
      renderCell: (row: Anagrafica) => {
        const menuDeleteRow: MenuItem = {
          key: `delete-affiliato-${row.id}`,
          title: 'Elimina affiliato',
          id: row.id,
          onClick: () => void 0,
          handleClickItem: handleDelete,
          icon: 'trash',
          color: 'red',
        };
        return (
          <DropdownMenu
            menu={[menuDeleteRow]}
            styleToggle={{
              padding: '2px',
              marginLeft: '.5rem',
            }}
          />
        );
      },
    },
    {
      id: 'id',
      label: 'ID',
      minWidth: 70,
    },
    {
      id: 'ragione_sociale',
      label: 'Ragione Sociale',
      minWidth: 400,
    },
    {
      id: 'piva',
      label: 'P.IVA',
      minWidth: 200,
    },
    {
      id: 'codice_fiscale',
      label: 'C.Fiscale',
      minWidth: 200,
    },
    {
      id: 'localita',
      label: 'Città',
      minWidth: 200,
    },
  ];
};
