import {
  AnagraficaClass,
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_ID,
  FiltroClass,
  ModuloClass,
  TabsId,
  MODULO_RIGHE_ID,
  ModuloChiaveClass,
  SchedaClass,
  SCHEDA_ID,
  REFERENTE_ID,
  ReferenteClass,
  FATTURAZIONE_ID,
  UTENTI_ID,
  CONTO_ID,
  TAB_IVA_ID,
  AFFILIATI_ID,
  VALIDAZIONE_SCHEDA_ID,
} from 'store';
import { ContoClass } from 'store/conto';
import { FatturazioneClass } from 'store/fatturazioni';
import { UtenteClass } from 'store/utenti';

export const MapEmptyElement: Record<TabsId | string, any> = {
  [ANAGRAFICA_ID]: new AnagraficaClass(),
  [FILTRO_ID]: new FiltroClass(),
  [MODULO_ID]: new ModuloClass(),
  [MODULO_RIGHE_ID]: new ModuloChiaveClass(),
  [SCHEDA_ID]: new SchedaClass(),
  [VALIDAZIONE_SCHEDA_ID]: null,
  [REFERENTE_ID]: new ReferenteClass(),
  [FATTURAZIONE_ID]: new FatturazioneClass(),
  [UTENTI_ID]: new UtenteClass(),
  [CONTO_ID]: new ContoClass(),
  [TAB_IVA_ID]: null,
  [AFFILIATI_ID]: new AnagraficaClass(),
};

export default MapEmptyElement;
