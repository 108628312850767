import React, { memo, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUser, LockStatus } from 'store';

interface FormButtonsProps {
  id: number | null;
  handleClose: (cancel: boolean) => void;
  handleSave: () => void;
  isDisabled: boolean;
  handleSaveAndClose?: () => void;
  handleLockStatus?: () => Promise<LockStatus | null>;
  handleUnlock?: () => void;
  isLoading?: boolean;
  hasLockState?: boolean;
  isDirty?: boolean;
  hasSave?: boolean;
  revertAndCloseText?: string;
  closeText?: string;
  saveText?: string;
  saveAndCloseText?: string;
  hasOnlySaveAndClose?: boolean;
  hideSaveButtons?: boolean;
}

const FormButtons = ({
  handleLockStatus,
  handleUnlock,
  handleClose,
  handleSave,
  handleSaveAndClose,
  id,
  isDisabled = false,
  hasLockState = false,
  isLoading = true,
  isDirty = true,
  hasSave = true,
  revertAndCloseText = 'Annulla e Chiudi',
  closeText = 'Chiudi',
  saveText = 'Salva',
  saveAndCloseText = 'Salva e Chiudi',
  hasOnlySaveAndClose = false,
  hideSaveButtons = false,
}: FormButtonsProps): React.ReactElement => {
  const [isEditable, setIsEditable] = React.useState(false);
  const currentUser = useSelector(getUser);

  const handleEdit = async () => {
    if (handleLockStatus) {
      const lock = await handleLockStatus();
      setIsEditable(lock?.email === currentUser.email);
    }
  };

  const unlock = () => {
    if (hasLockState && isEditable && handleUnlock) {
      handleUnlock();
      setIsEditable(false);
    }
  };

  const save = (close: boolean) => {
    close ? handleSaveAndClose && handleSaveAndClose() : handleSave();
    unlock();
  };

  const onClose = () => {
    unlock();
    handleClose(true);
  };

  const saveButton = useMemo(() => {
    if (hideSaveButtons) return <div />;
    else {
      return (
        <>
          {hasLockState && isDisabled && id && (
            <>
              <Button
                variant="primary"
                onClick={handleEdit}
                style={{
                  backgroundColor: 'primary',
                  margin: '0.2rem',
                }}
              >
                Modifica
              </Button>
            </>
          )}
          {hasOnlySaveAndClose && (!hasLockState || !isDisabled) && (
            <Button
              variant="primary"
              onClick={() => save(true)}
              style={{
                backgroundColor: 'primary',
                margin: '0.2rem',
              }}
            >
              {saveAndCloseText}
            </Button>
          )}
          {hasSave && !hasOnlySaveAndClose && (!hasLockState || !isDisabled) && (
            <>
              <Button
                variant="soft-primary"
                onClick={() => save(false)}
                style={{
                  backgroundColor: 'primary',
                  margin: '0.2rem',
                }}
              >
                {saveText}
              </Button>
              <Button
                variant="primary"
                onClick={() => save(true)}
                style={{
                  backgroundColor: 'primary',
                  margin: '0.2rem',
                }}
              >
                {saveAndCloseText}
              </Button>
            </>
          )}
        </>
      );
    }
  }, [hideSaveButtons, hasLockState, isDisabled, id, hasSave, hasOnlySaveAndClose]);

  return (
    <div
      id="form-buttons"
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        alignItems: 'center',
        borderTop: '1px solid',
        position: 'absolute',
        bottom: '0',
        background: 'white',
        padding: '1rem 2.8rem',
      }}
    >
      <div>
        <Button
          variant="soft-secondary"
          onClick={onClose}
          style={{
            backgroundColor: 'gray-300',
            margin: '0.2rem',
          }}
        >
          {!isDisabled ? revertAndCloseText : closeText}
        </Button>

        {saveButton}
      </div>
    </div>
  );
};

export default memo(FormButtons);
