import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState } from '../../..';
import { URL_FATTURAZIONE, URL_LIST } from '../../..';

export const fetchFatturazioniOptions = createAsyncThunk(
  'fatturazioni/fetchOPtions',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = state?.fatturazioni?.paramsOption ?? {};
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_FATTURAZIONE}${URL_LIST}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
