import { MODULO_RIGHE } from './modules';
import {
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_ID,
  TabsId,
  MODULO_RIGHE_ID,
  SCHEDA_ID,
  schedeSlice,
  REFERENTE_ID,
  FATTURAZIONE_ID,
  UTENTI_ID,
  CONTO_ID,
  TAB_IVA_ID,
  AFFILIATI_ID,
  VALIDAZIONE_SCHEDA_ID,
} from '../../store';
import {
  anagrafiche,
  ColumnCustomHeader,
  convertAccessorSchedeToColumnData,
  convertAccessorTabIvaToColumnData,
  fatturazione,
  filtri,
  moduli,
  referente,
  schede,
  utenti,
} from './tables';
import { GridColumns } from '@mui/x-data-grid';
import { ColumnData } from 'utils/types';
export const accessor = (tabId: TabsId, isComplete = true): ColumnCustomHeader[] | GridColumns => {
  const map: Record<TabsId | string, any> = {
    [ANAGRAFICA_ID]: anagrafiche,
    [AFFILIATI_ID]: anagrafiche,
    [FILTRO_ID]: filtri,
    [MODULO_ID]: moduli,
    [MODULO_RIGHE_ID]: null,
    [SCHEDA_ID]: schede,
    [REFERENTE_ID]: referente,
    [FATTURAZIONE_ID]: fatturazione,
    [UTENTI_ID]: utenti,
    [CONTO_ID]: null,
    [TAB_IVA_ID]: null,
    [VALIDAZIONE_SCHEDA_ID]: null,
  };
  return map[tabId] ? map[tabId][isComplete ? 'complete' : 'preview'] : map[MODULO_ID]['complete'];
};

export const convertAccessorToColumnData = (tabId: TabsId) => {
  const map: Record<TabsId | string, () => ColumnData<any>[]> = {
    [ANAGRAFICA_ID]: () => [],
    [AFFILIATI_ID]: () => [],
    [FILTRO_ID]: () => [],
    [MODULO_ID]: () => [],
    [MODULO_RIGHE_ID]: () => [],
    [SCHEDA_ID]: convertAccessorSchedeToColumnData,
    [REFERENTE_ID]: () => [],
    [FATTURAZIONE_ID]: () => [],
    [UTENTI_ID]: () => [],
    [CONTO_ID]: () => [],
    [TAB_IVA_ID]: convertAccessorTabIvaToColumnData,
    [VALIDAZIONE_SCHEDA_ID]: () => [],
  };

  return Object.keys(map).includes(tabId) ? map[tabId]() : null;
};
