import { FormInput, SearchAddress } from 'components';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Context } from '../Context';
import { useContext } from 'react';

export const SedeLegale = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const { perms } = useContext(Context);

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Sede</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={3}>
            <FormInput
              type="text"
              name="indirizzo"
              label={'Indirizzo e n.civico'}
              placeholder="via Dei matti"
              minHeight="6.25rem"
              register={register}
              disabled={perms && !perms.can_write}
            />
          </Col>

          <Col md={1}>
            <FormInput
              type="text"
              name="cap"
              label={'CAP'}
              placeholder="20121"
              minHeight="6.25rem"
              register={register}
              disabled={perms && !perms.can_write}
            />
          </Col>

          <Col md={3}>
            <FormInput
              type="text"
              name="localita"
              label={'CIttà'}
              placeholder="Milano"
              minHeight="6.25rem"
              register={register}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={2}>
            <FormInput
              type="text"
              name="provincia"
              label={'Provincia'}
              placeholder="Milano"
              minHeight="6.25rem"
              register={register}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={3}>
            <SearchAddress
              label="Ricerca località"
              fieldCap="cap"
              fieldCitta="localita"
              fieldProvincia="provincia"
              fieldProvinciaId="provincia_id"
              fieldRegione="regione"
              fieldRegioneId="regione_id"
              name="ricerca"
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SedeLegale;
