import { useSelector } from 'react-redux';
import {
  ANAGRAFICA_ID,
  CONTO_ID,
  FATTURAZIONE_ID,
  FILTRO_ID,
  MODULO_ID,
  SCHEDA_ID,
  TAB_IVA_ID,
} from 'store/utils/constants/tabId';

export const USER = 'user';
export const GEO = 'geo';
export const NAZIONE = 'nazione';
export const PROVINCIA = 'provincia';
export const REGIONE = 'regione';
export const COMUNE = 'comune';
export const CITTA = 'citta';
export const GEO_CAP = 'geo_cap';
export const EMAIL = 'email';
export const ATTACHMENT = 'attachment';
export const ANAGRAFICA = 'anagrafica';
export const FILTRO = 'filtro';
export const FILTRO_CHIAVI = 'filtro_chiavi';
export const IMPORTAZIONE = 'importazione';
export const MODULO = 'modulo';
export const MODULO_RIGHE = 'modulo_righe';
export const SCHEDA = 'scheda';
export const FATTURAZIONE = 'fatturazione';
export const REFERENTE = 'referente';
export const UTENTE = 'utente';
export const CONTO = 'conto';
export const TAB_IVA = 'tab_iva';
export const AFFILIATI = 'affiliati';
export type ModuleName =
  | typeof USER
  | typeof GEO
  | typeof NAZIONE
  | typeof PROVINCIA
  | typeof REGIONE
  | typeof COMUNE
  | typeof CITTA
  | typeof GEO_CAP
  | typeof EMAIL
  | typeof ATTACHMENT
  | typeof ANAGRAFICA
  | typeof IMPORTAZIONE
  | typeof FILTRO
  | typeof FILTRO_CHIAVI
  | typeof MODULO
  | typeof MODULO_RIGHE
  | typeof SCHEDA
  | typeof FATTURAZIONE
  | typeof CONTO
  | typeof TAB_IVA
  | typeof REFERENTE
  | typeof AFFILIATI;

export const MAP_TAB_ID_MODULE_PAGE: Record<string, ModuleName> = {
  [ANAGRAFICA_ID]: ANAGRAFICA,
  [FILTRO_ID]: FILTRO,
  [MODULO_ID]: MODULO,
  [SCHEDA_ID]: SCHEDA,
  [FATTURAZIONE_ID]: FATTURAZIONE,
  [CONTO_ID]: CONTO,
  [TAB_IVA_ID]: TAB_IVA,
};

export const MAP_TAB_ID_TITLE_PAGE: Record<string, string> = {
  [ANAGRAFICA_ID]: 'Anagrafica Clienti',
  [FILTRO_ID]: 'Filtri Clienti',
  [MODULO_ID]: 'Moduli Schede',
  [SCHEDA_ID]: 'Schede',
  [FATTURAZIONE_ID]: 'Fatturazione Clienti',
  [CONTO_ID]: 'Conto aziendale',
  [TAB_IVA_ID]: 'Aliquote IVA',
};

export const getTabIdPageByModule = (module: ModuleName) => {
  const values = Object.values(MAP_TAB_ID_MODULE_PAGE);
  const tabId = Object.keys(MAP_TAB_ID_MODULE_PAGE)[values.findIndex((item) => item === module)];
  return tabId;
};
