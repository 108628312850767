import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setAffiliatiSelectedIds } from '.';
import {
  apiClient,
  handleError,
  RootState,
  URL_AFFILIATI,
  URL_ASSOCIA_PADRE,
  URL_ITEM,
  URL_ANAGRAFICA,
} from '../../..';
import { AssociaAffiliatoRequest } from '../types';

export const associaAffiliatoPadre = createAsyncThunk(
  'affiliati/associaPadre',
  async (params: AssociaAffiliatoRequest, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_ITEM}/${params.padre_id}${URL_AFFILIATI}/${params.affiliato_id}${URL_ASSOCIA_PADRE}`,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
