import Icon from '@ailibs/feather-react-ts';
import { TableCell } from '@mui/material';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DRIVE_TEST_ID,
  FiltroChiave,
  getSelectorListDataParams,
  TabsId,
  TYPE_CEDOLINI,
  TYPE_CURRENCY,
  TYPE_INTEGER,
  TYPE_NUMBER,
} from 'store';
import { ASC, DESC } from 'utils/constants/constants';
import { ColumnCustomHeader } from 'utils/constants/tables';
import { FilterColumn } from './Filters/FilterColumn';
import { StyledTableCell } from './StyledTableCell';

interface ThProps {
  column: ColumnCustomHeader;
  tabId: TabsId;
  params: any;
  updateParams: (params: any) => void;
  filters: FiltroChiave[];
  setFilters: (filters: FiltroChiave[]) => void;
  columnSort: string | null;
  setColumnSort: (columnSort: string | null) => void;
}

interface Sort {
  isSorted: boolean;
  typeSort: typeof DESC | typeof ASC | null;
}

export const Th = ({
  column,
  tabId,
  params: parameters,
  updateParams,
  filters,
  setFilters,
  columnSort,
  setColumnSort,
}: ThProps) => {
  if (tabId === DRIVE_TEST_ID) {
    return (
      <StyledTableCell
        align={
          column.type === TYPE_NUMBER || TYPE_INTEGER || TYPE_CEDOLINI || TYPE_CURRENCY
            ? 'right'
            : 'left'
        }
        style={{ width: column.width }}
      >
        {column.Header}
      </StyledTableCell>
    );
  }

  const [isSorted, setIsSorted] = useState<boolean>(false);
  const [typeSort, setTypeSort] = useState<string | null>(null);
  const params: Record<string, any> = getSelectorListDataParams(tabId);

  const onClick = () => {
    if (column.sort) {
      if (!isSorted) {
        setOrderParameters({ isSorted: true, typeSort: DESC });
      }
      if (isSorted && typeSort === DESC) {
        setOrderParameters({ isSorted: true, typeSort: ASC });
      }
      if (isSorted && typeSort === ASC) {
        setOrderParameters({ isSorted: false, typeSort: ASC });
      }
    }
  };

  const setOrderParameters = useCallback(
    ({ isSorted, typeSort }: Sort) => {
      const orderBy = [];
      if (isSorted) {
        orderBy.push(`${column.accessor ?? column.id} ${typeSort}`);
      }
      // if (orderBy) {
      //   const indexOf = orderBy.indexOf(
      //     typeSort === DESC ? `${column.id} ${ASC}` : `${column.id} ${DESC}`,
      //     0
      //   );
      //   indexOf >= 0 && orderBy.splice(indexOf, 1);
      // }
      // if (isSorted) {
      //   orderBy.push(`${column.id} ${typeSort}`);
      // } else if (!!orderBy) {
      //   const index = orderBy.indexOf(
      //     orderBy.find((element: string) => {
      //       const indexElement = element?.indexOf(column.id);
      //       return indexElement >= 0;
      //     })
      //   );
      //   index >= 0 && orderBy.splice(index, 1);
      // }
      isSorted && setColumnSort(column.accessor ?? (column.id as any));
      setIsSorted(isSorted);
      setTypeSort(typeSort);
      updateParams({ orderBy: [...orderBy] });
    },
    [params]
  );

  // useEffect(() => {}, [isSorted, typeSort]);
  return (
    <StyledTableCell
      align={
        column.type === TYPE_NUMBER || TYPE_INTEGER || TYPE_CEDOLINI || TYPE_CURRENCY
          ? 'right'
          : 'left'
      }
      style={{ width: column.width }}
    >
      <div
        // {...column.getHeaderProps(column.sort && column.getSortByToggleProps())}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginRight: '0 !important',
          marginLeft: '0 !important',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {column.Header}
        {/* Add a sort direction indicator */}
        {column.sort && isSorted && column.id === columnSort && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
            }}
          >
            <Icon name={typeSort === DESC ? 'chevrons-down' : 'chevrons-up'} size={16} />
          </div>
        )}
      </div>
      {/* {column.filterType ? (
        <FilterColumn
          type={column.filterType}
          tabId={tabId}
          field={column.accessor as any}
          params={params}
          updateParams={updateParams}
          filters={filters}
          setFilters={setFilters}
          options={column.options}
        />
      ) : (
        <div style={{ height: '30px' }}></div>
      )} */}
    </StyledTableCell>
  );
};
