import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { Box, Dialog, DropdownMenu, FiltroForm, Flex } from 'components';
import { ConfrimModal } from 'components/ConfirmModal';
import TablePagination from 'components/TablePagination';
import { useDisclosure, useWindowSize } from 'hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  FATTURAZIONE_ID,
  Fatturazione,
  FiltroChiave,
  TYPE_CEDOLINI,
  TYPE_CURRENCY,
  TYPE_INTEGER,
  TYPE_NUMBER,
  TYPE_STRING,
  TabsId,
  getPermsByModule,
  getSelectorSelectedIds,
  sendFatturazioni,
  useAppDispatch,
} from 'store';
import { ModuleName } from 'utils/constants';
import { ColumnCustomHeader } from 'utils/constants/tables';
import { MenuItem } from 'utils/types';
import { Header } from './Header';
import IndeterminateCheckbox from './IndeterminateCheck';
import { StyledTableCell } from './StyledTableCell';
import { Th } from './Th';
import { useGrid } from './hook';
import './style.css';
import { AlertMessage } from 'components/AlertMessage';
import { Typography } from '@mui/material';

const StyledTable = styled(Table)(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '10px 10px 0 0',
}));

const TableWrapper = styled('div')({
  overflowX: 'auto',
});

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  backgroundColor: 'var(--bs-gray-400) !important',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
}));

interface TableProps {
  tabId: TabsId;
  title: string;
  selectedId: number;
  menu?: MenuItem[];
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  hasCreateFilter?: boolean;
  module: ModuleName;
  selectRow?: (id: number) => void;
  open?: (isOpen: boolean) => void;
  handleOpenNew?: () => void;
  handleOpenImport?: () => void;
  hasSelectorTime?: boolean;
  hasImport?: boolean;
  hasExport?: boolean;
  hasNew?: boolean;
  hasSchedeSelector?: boolean;
  hasFatturazioneStats?: boolean;
  hasReload?: boolean;
  additionalRowMenuOption?: MenuItem[];
  customHeaderButtons?: React.ReactElement[];
}

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '1.8rem', // Imposta l'altezza desiderata qui
          // Aggiungi altre sovrascritture di stile se necessario
        },
      },
    },
  },
});

const THEAD = 40;
const TR = 16 * 1.8;
const HEADER = 70;
const NAVBAR = 55;
const OPTION = 30;
const PAGINATION = 72;

export default function StickyHeadTable(props: TableProps) {
  const [page, setPage] = React.useState(1);
  const [tableHeight, settableHeight] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [limitRaw, setLimitRaw] = React.useState(0);
  const [filters, setFilters] = React.useState<FiltroChiave[]>([]);
  const [columnSort, setColumnSort] = React.useState<string | null>(null);

  const selectedIds = getSelectorSelectedIds(props.tabId);

  const {
    isOpen: isOpenCreateFiltri,
    onOpen: handleOpenFiltri,
    onClose: handleCloseFiltri,
  } = useDisclosure();

  const permsModule = useSelector(getPermsByModule(props.module));

  const dispatch = useAppDispatch();

  const {
    data,
    params,
    loading,
    updateParams,
    columnSettings,
    getData,
    show,
    onConfirm,
    handleClose,
    handleOpenDelete,
    crudId,
  } = useGrid({
    tabId: props.tabId,
    initialParams: { skip: 0, limit: limitRaw },
  });

  const onChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    const newSkip = newPage * params.limit;
    updateParams({ skip: (newPage - 1) * params.limit });
  };

  const { height } = useWindowSize();

  const tableRef = React.useRef<HTMLTableElement>(null);

  const sendFatture = async (fattura: Fatturazione) => {
    if (fattura && fattura.scheda?.id) {
      const scheda_id = fattura.scheda.id;
      const response = await dispatch(sendFatturazioni({ scheda_id: scheda_id }));
      if (response && !response.error) {
        AlertMessage({
          title: 'Invio Fattura24',
          html: (
            <Flex justifyContent="center">
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Processo di invio fatture avviato con successo
              </Typography>
            </Flex>
          ),
          icon: 'success',
          allowOutsideClick: false,
        });
      }
    }
  };

  const menuSendFatture: MenuItem = {
    key: `send-${FATTURAZIONE_ID}`,
    title: 'Invia Fattura24',
    onClick: () => sendFatture(data[0]),
    icon: 'upload-cloud',
  };

  const getMenuItems = () => {
    if (props.tabId === FATTURAZIONE_ID) {
      return [menuSendFatture, ...(props.menu ?? [])];
    }
    return props.menu;
  };

  React.useEffect(() => {
    if (height) {
      const tbodyHeight =
        height - HEADER - NAVBAR - OPTION - THEAD - (props.pagination ? PAGINATION : 0);
      if (tbodyHeight > 0) {
        const limit = tbodyHeight / TR;
        settableHeight(tbodyHeight + THEAD);
        setLimitRaw(Math.round(limit));
      }
    }
  }, [height]);

  React.useEffect(() => {
    limitRaw && updateParams({ limit: limitRaw });
  }, [limitRaw]);
  return (
    <>
      <Box
        display="grid"
        gridTemplateRows={`40px ${tableHeight}px auto`}
        // flexDirection="column"
        // justifyContent={'space-between'}
        ref={tableRef}
        // position="relative"
        // height="100vh"
      >
        <Header
          title={props.title}
          permsModule={permsModule}
          columnSettings={columnSettings}
          menu={getMenuItems()}
          tabId={props.tabId}
          params={params}
          updateParams={updateParams}
          handleOpenNew={props.handleOpenNew}
          handleOpenImport={props.handleOpenImport}
          handleOpenFiltri={handleOpenFiltri}
          hasCreateFilter={props.hasCreateFilter}
          filters={filters}
          setFilters={setFilters}
          hasSelectorTime={!!props.hasSelectorTime}
          hasNew={props.hasNew}
          hasImport={props.hasImport}
          hasExport={props.hasExport}
          hasSchedeSelector={!!props.hasSchedeSelector}
          hasFatturazioneStats={!!props.hasFatturazioneStats}
          customButtons={props.customHeaderButtons}
        />
        <ThemeProvider theme={theme}>
          <TableWrapper>
            <StyledTable
              stickyHeader
              aria-label="sticky table"
              size="small"
              width={'100%'}
              sx={{ position: 'relative', overflow: 'auto' }}
            >
              <TableHead style={{ display: 'flex', flexDirection: 'column' }}>
                <TableRow style={{ display: 'flex' }}>
                  <StyledTableCell
                    padding="checkbox"
                    style={{ width: '5rem', borderTopLeftRadius: '10px' }}
                  >
                    <IndeterminateCheckbox tabId={props.tabId} header />
                  </StyledTableCell>
                  {columnSettings.map((column: any, indexColumn: any) => (
                    <Th
                      column={column}
                      tabId={props.tabId}
                      params={params}
                      updateParams={updateParams}
                      filters={filters}
                      setFilters={setFilters}
                      columnSort={columnSort}
                      setColumnSort={setColumnSort}
                      key={`grid-col-${indexColumn}`}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ display: 'flex', flexDirection: 'column' }}>
                {data?.map((row: any, index: number) => {
                  const menuDeleteRow: MenuItem = {
                    key: `delete-${props.tabId}`,
                    title: 'Elimina righa',
                    id: row.id,
                    onClick: () => void 0,
                    handleClickItem: handleOpenDelete,
                    icon: 'trash',
                    color: 'red',
                  };
                  let menuOptions: MenuItem[] = [];
                  if (props.additionalRowMenuOption && props.additionalRowMenuOption.length > 0) {
                    menuOptions = props.additionalRowMenuOption.map((item: MenuItem) => {
                      if (item.needId && !item.id) {
                        item.id = row.id;
                      }
                      return item;
                    });
                  }
                  menuOptions = [...menuOptions, menuDeleteRow];
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`row-${index}`}
                      style={{ display: 'flex' }}
                    >
                      <TableCell
                        style={{
                          display: 'flex',
                          width: '5rem',
                        }}
                      >
                        <Flex alignContent="center">
                          <IndeterminateCheckbox
                            tabId={props.tabId}
                            id={row?.id}
                            style={{ marginBottom: '0.6rem', marginTop: 0 }}
                          />
                          <Flex>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              {menuOptions && menuOptions.length > 0 && (
                                <DropdownMenu
                                  menu={menuOptions}
                                  styleToggle={{
                                    padding: '2px',
                                    marginLeft: '.5rem',
                                  }}
                                />
                              )}
                            </div>
                          </Flex>
                        </Flex>
                      </TableCell>
                      {columnSettings.map((column: ColumnCustomHeader) => {
                        const value = row[column.accessor];
                        return (
                          <TableCell
                            key={column.accessor}
                            className={
                              row.id === props.selectedId ? `tr-soft-primary` : `hover-soft-primary`
                            }
                            onDoubleClick={() => {
                              if (permsModule && permsModule.can_read) {
                                props?.selectRow && props.selectRow(row.id);
                                props?.open && props.open(true);
                              }
                            }}
                            align={
                              [TYPE_NUMBER, TYPE_INTEGER, TYPE_CEDOLINI, TYPE_CURRENCY].includes(
                                column.type ?? TYPE_STRING
                              )
                                ? 'right'
                                : 'left'
                            }
                            sx={{
                              width: `${column.width}`,
                            }}
                          >
                            <div>
                              {column.actionComponent
                                ? column.actionComponent(row)
                                : column.formatComponent
                                ? column.formatComponent(value)
                                : column.format
                                ? column.format(value)
                                : value}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          </TableWrapper>
        </ThemeProvider>
        <Flex width="100%" alignItems="center" padding="1rem" backgroundColor="var(--bs-gray-100)">
          {props?.pagination && (
            <StyledTablePagination
              tabId={props.tabId}
              params={params}
              updateParams={updateParams}
              initialLimit={limitRaw}
              {...(props.hasReload ? { handleFetch: () => getData() } : {})}
            />
          )}
        </Flex>
      </Box>
      {props.hasCreateFilter && (
        <Dialog
          title="Nuovo Filtro"
          show={isOpenCreateFiltri}
          fullscreen="sm-down"
          setShow={handleOpenFiltri}
        >
          <FiltroForm
            id={0}
            module={props.module}
            handleClose={handleCloseFiltri}
            filters={filters}
            selectedIds={selectedIds}
          />
        </Dialog>
      )}
      <ConfrimModal
        show={show}
        setShow={(show: boolean) => handleClose()}
        title={'Elimina riga'}
        onConfirm={onConfirm}
      >
        <Flex justifyContent="center" marginBottom=".5rem">
          Confermi di voler eliminare la riga con ID: {crudId}?
        </Flex>
      </ConfrimModal>
    </>
  );
}
