import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './style.css';

type AlertProps = SweetAlertOptions & {
  isOpen?: boolean;
};

export const AlertMessage = ({ isOpen, ...rest }: AlertProps) => {
  const customClass = {
    container: 'sweetalert2-custom-zindex',
  };
  const Alert = withReactContent(Swal);
  return Alert.fire({ ...rest, customClass });
};
