import { Anagrafica } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import {
  createAnagrafica,
  deleteAnagrafica,
  fetchAnagrafica,
  resetAnagrafica,
  resetAllAnagrafica,
  resetAllOptionAnagrafica,
} from '../actions';
import { FAILED, IDLE, PENDING, StateSingular, SUCCEEDED } from '../../../types';

const initialState: StateSingular<Anagrafica> = {
  data: null,
  loading: IDLE,
  error: null,
};

export const anagraficaSlice = createSlice({
  name: 'anagrafica',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnagrafica.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchAnagrafica.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchAnagrafica.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(createAnagrafica.fulfilled, (state, { payload }) => {
        state.data = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(createAnagrafica.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(createAnagrafica.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(resetAnagrafica, (state) => {
        state.data = null;
        state.loading = IDLE;
        state.error = null;
      })
      .addCase(deleteAnagrafica.fulfilled, (state) => {
        state.data = null;
        state.loading = SUCCEEDED;
      })
      .addCase(deleteAnagrafica.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(deleteAnagrafica.rejected, (state, action) => {
        state.error = action?.error?.code || action?.error?.name || '';
        state.loading = FAILED;
      })
      .addCase(resetAllAnagrafica, (state) => initialState)
      .addCase(resetAllOptionAnagrafica, (state) => initialState);
  },
});
