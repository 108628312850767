import { UtenteFetchParams } from '../types';
import { handleError, URL_UTENTE, URL_GET } from '../../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../../utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUtente = createAsyncThunk(
  'utente/fetch',
  async ({ id, ...params }: UtenteFetchParams, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_UTENTE}${URL_GET}/${id}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
