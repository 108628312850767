import { useCallback, useEffect, useState } from 'react';
import {
  DELETE,
  DRIVE_TEST_ID,
  FETCHLIST,
  getAction,
  getSelectorListData,
  getSelectorListDataParams,
  getSelectorLoading,
  getSelectorSelectedIds,
  mapAction,
  SETPARAMS,
  SETSELECTALL,
  SETSELECTEDIDS,
  SUBMIT,
  TabsId,
  useAppDispatch,
} from '../../store';
import { accessorGenericGrid, accessorGrid, ModuleName } from 'utils';
import { useFormContext } from 'react-hook-form';

interface DataGridProps {
  tabId: TabsId;
  initialParams: any;
  parentId?: number;
  dataRows: any[];
  setSearch: (search: string) => void;
  setIsNew: (isNew: boolean) => void;
  setDataRows: (dataRows: any[]) => void;
  handleSaveCurrentTab?: () => Promise<any> | undefined;
}

interface DataGridReturn {
  data: any;
  loading: boolean;
  columnSettings: any;
  rowSelection: any;
  getData: () => void;
  handleDelete: (id: number) => Promise<void>;
  onClickNew: (parentId: number, position?: number, row?: any) => void;
  show: boolean;
  onConfirm: () => void;
  handleClose: () => void;
  handleOpenDelete: (crudId: number) => void;
  saveRow: (row: any) => Promise<any>;
  handleSelect: (id: number | string) => void;
  handleSelectAll: () => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const defaultGridReturn: DataGridReturn = {
  data: [],
  loading: false,
  getData: () => void 0,
  handleDelete: (id: number) => new Promise<void>((resolve) => void 0),
  columnSettings: [
    // {
    //   Header: 'ID',
    //   accessor: 'id',
    //   isVisible: true,
    // },
    // {
    //   Header: 'Nome',
    //   accessor: 'nome',
    //   isVisible: true,
    //   sort: false,
    // },
    // {
    //   Header: 'Descrizione',
    //   accessor: 'descrizione',
    //   isVisible: true,
    //   sort: false,
    // },
  ],
  show: false,
  onConfirm: () => void 0,
  handleClose: () => void 0,
  handleOpenDelete: (id: number) => void 0,
  rowSelection: {
    selectedIds: [],
    onSelect: (record: any, selected: any, selectedRows: any) => {
      void 0;
    },
    onSelectAll: () => {
      void 0;
    },
  },
  onClickNew: (parentId: number, position?: number, row?: any) => void 0,
  saveRow: (row: any) =>
    new Promise<any>((resolve, reject) =>
      setTimeout(() => {
        if (row.name?.trim() === '') {
          reject(new Error("Error while saving row: name can't be empty."));
        } else {
          resolve({ ...row, name: row.name?.toUpperCase() });
        }
      }, 200)
    ),
  handleSelect: (id: number | string) => void 0,
  handleSelectAll: () => void 0,
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void 0,
};

export const useDataModal = ({
  tabId,
  parentId,
  dataRows,
  initialParams,
  setSearch,
  setIsNew,
  setDataRows,
  handleSaveCurrentTab,
}: DataGridProps): DataGridReturn => {
  if (tabId === DRIVE_TEST_ID) return defaultGridReturn;
  const dispatch = useAppDispatch();
  const data: any = getSelectorListData(tabId, true);
  const params: Record<string, any> = getSelectorListDataParams(tabId);
  const [fetchParams, setFetchParams] = useState(
    params ?? { skip: 0, limit: 0, parentId, ...(initialParams ?? {}) }
  );
  const loading: boolean = getSelectorLoading(tabId);
  const selectedIds = getSelectorSelectedIds(tabId);
  const [show, setShow] = useState<boolean>(false);

  const [crudId, setCrudId] = useState<number | null>(null);

  const onConfirm = async () => {
    await handleDelete(crudId as number);
    setCrudId(null);
    handleClose();
  };

  const handleClose = () => {
    setShow(false);
    setCrudId(null);
  };

  const handleOpenDelete = (crudId: number) => {
    setCrudId(crudId);
    setShow(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearch(search);
    if (!search) {
      setDataRows(data);
    } else {
      const searchWords = search.toLowerCase().split(' ');

      const filteredData = dataRows.filter((row) => {
        const rowValues = Object.values(row).join(' ').toLowerCase();

        return searchWords.every((word) => rowValues.includes(word));
      });

      setDataRows(filteredData);
    }
  };

  const getData = useCallback(async () => {
    const actionFetch = getAction(tabId, FETCHLIST, true);
    if (actionFetch) {
      const response = await dispatch(actionFetch());
      return response;
    }
  }, [tabId]);

  const onSelectedRowKeysChange = useCallback(
    (selectedRowId: any) => {
      const actionSelectedIds = mapAction[tabId][SETSELECTEDIDS];
      actionSelectedIds && dispatch(actionSelectedIds(selectedRowId));
    },
    [tabId]
  );

  const onSelectAllRecord = useCallback(() => {
    const actionSelectedIds = mapAction[tabId][SETSELECTALL];
    actionSelectedIds && dispatch(actionSelectedIds());
  }, []);

  const rowSelection = {
    selectedIds,
    onSelect: (record: any, selected: any, selectedRows: any) => {
      onSelectedRowKeysChange(record?.id);
    },
    onSelectAll: () => {
      onSelectAllRecord();
    },
  };
  const handleSelect = (id: number | string) => {
    const action = getAction(tabId, SETSELECTEDIDS);
    action && dispatch(action(id));
  };

  const handleSelectAll = () => {
    const action = getAction(tabId, SETSELECTALL);
    action && dispatch(action());
  };
  const handleDelete = async (id: number) => {
    const action = getAction(tabId, DELETE);
    action && (await dispatch(action({ id, parentId })));
    getData();
  };

  const onClickNew = async (parentId?: number, position?: number, row?: any) => {
    const newPosition: number =
      position || position === 0
        ? -position
        : data[data.length - 1]?.posizione
        ? Number(data[data.length - 1]?.posizione) + 1
        : 1;

    // const newPosition = data?.length ?? 1;
    const request = { posizione: newPosition, parentId };
    const actionCreate = getAction(tabId, SUBMIT);
    const rowResponse = await dispatch(actionCreate(request));
    // await getData();
    if (!!rowResponse && !rowResponse.error) {
      const actionSetParams = getAction(tabId, SETPARAMS, true);
      actionSetParams &&
        dispatch(actionSetParams({ parentId, skip: 0, limit: 0, ...(initialParams ?? {}) }));
      const actionFetchList = getAction(tabId, FETCHLIST, true);
      const newData = await dispatch(actionFetchList());
      setDataRows(newData?.payload?.data);
      setIsNew(true);
    }
  };

  const saveRow = async (row: any) => {
    const actionCreate = getAction(tabId, SUBMIT);
    if (actionCreate) {
      const response = await dispatch(actionCreate(row));
      const actionFetch = getAction(tabId, FETCHLIST, true);
      actionFetch && (await dispatch(actionFetch()));
      return response.payload?.data;
    }
    return null;
  };

  const columnSettings = accessorGenericGrid({ tabId, delete: handleOpenDelete, add: onClickNew });

  useEffect(() => {
    const actionSetParams = getAction(tabId, SETPARAMS, true);
    dispatch(actionSetParams({ parentId, skip: 0, limit: 0, ...(initialParams ?? {}) }));
    getData();
  }, [parentId]);

  useEffect(() => {
    getData();
  }, []);

  const handleCloseRow = (): void => {
    setDataRows(dataRows.filter((row: any) => row.id));
  };

  useEffect(() => {
    //TODO in setDataRows è da sistemare invocando se presente in columnsSettings il metodo GridColumn.renderCell(row)
    setDataRows(data);
  }, [parentId, data, data?.length]);

  return {
    data,
    loading,
    columnSettings,
    rowSelection,
    getData,
    handleDelete,
    onClickNew,
    show,
    onConfirm,
    handleClose,
    handleOpenDelete,
    saveRow,
    handleSelect,
    handleSelectAll,
    handleSearchChange,
  };
};
