import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components

import AuthLayout from './AuthLayout';

import {
  postSignIn,
  getErrorMessage,
  getAuthLoader,
  getIsAuthenticated,
  useAppDispatch,
  fetchUser,
} from '../../../store';
import { aziendaInfo, getAzienda, getAziendaLogo } from 'store/azienda';
import { LOGIN } from 'utils';

const { REACT_APP_IPERPHONO_BE_MASTER_HOST } = process.env || {};

const URL_MASTER = REACT_APP_IPERPHONO_BE_MASTER_HOST;

interface UserData {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useAppDispatch();

  const azienda = useSelector(getAzienda);
  const aziendaLogo = useSelector(getAziendaLogo);
  const navigate = useNavigate();
  /*
    form validation schema
    */
  const [searchParams] = useSearchParams();

  /*
  handle form submission
  */
  const redirectUrl = '/anagrafiche/list';
  const loginUrl = LOGIN;
  const onSubmit = async (username: string, password: string) => {
    const response = await dispatch(postSignIn({ username, password }));
    if (response && !response.error) {
      await dispatch(fetchUser());
      navigate(redirectUrl);
    } else {
      navigate(loginUrl);
    }
  };

  useEffect(() => {
    const username = searchParams.get('username');
    const password = searchParams.get('password');
    if (username && password) {
      onSubmit(username || '', password || '');
    }
  }, [searchParams]);

  const [logo, setLogo] = React.useState<string>('');

  useEffect(() => {
    if (!azienda) {
      dispatch(aziendaInfo());
    } else {
      setLogo(`${URL_MASTER}/${aziendaLogo}`);
    }
  }, [azienda?.id, aziendaLogo]);

  return (
    <>
      {/* {userLoggedIn ? <Navigate to={redirectUrl} state={{ from: location }} replace /> : null} */}

      <AuthLayout>
        <div className="auth-logo mx-auto">
          <Link to="/" className="logo logo-dark text-center">
            <span className="logo-lg">
              <img src={logo} alt="" height="70" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light text-center">
            <span className="logo-lg">
              <img src={logo} alt="" height="70" />
            </span>
          </Link>
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;
