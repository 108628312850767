import { createAsyncThunk } from '@reduxjs/toolkit';
import { DuplicateFatturaParams } from '../types';
import { apiClient, handleError, URL_FATTURAZIONE, URL_DUPLICA } from '../../../index';
import { AxiosResponse } from 'axios';

export const duplicateFatturazione = createAsyncThunk(
  'fatturazione/duplicate',
  async (parameters: DuplicateFatturaParams, thunkAPI) => {
    try {
      const { documento_tipo, id, ...params } = parameters;
      console.log({ params });
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_FATTURAZIONE}/${documento_tipo}${URL_DUPLICA}/${id}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
