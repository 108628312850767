import { BOOLEAN, TEXT } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';
import { SwitchRowGrid } from 'components';
import {
  KeyValue,
  Roles,
  TYPE_BOOLEAN,
  enableUtente,
  store,
  updateSingleRecordUtenti,
} from 'store';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '5rem',
  },
  {
    Header: 'Nome',
    accessor: 'name',
    sort: true,
    filterType: TEXT,
    width: '20rem',
  },
  {
    Header: 'Cognome',
    accessor: 'lastname',
    sort: true,
    filterType: TEXT,
    width: '20rem',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    sort: true,
    filterType: TEXT,
    width: '40rem',
  },
  {
    Header: 'Profilo',
    accessor: 'role',
    sort: true,
    format: (value: any) => {
      const role = Roles.find((item: KeyValue) => item.id === value);
      return role && role.descrizione ? role.descrizione : '';
    },
    width: '15rem',
  },
  {
    Header: 'Abilitato',
    accessor: 'enable',
    type: TYPE_BOOLEAN,
    sort: false,
    actionComponent: (row: any) => {
      const user = store.getState().user?.data;
      return (
        <SwitchRowGrid
          value={row.enable ?? false}
          disabled={row.email === user?.email}
          id={row.id}
          action={enableUtente}
          updateSingleRecord={updateSingleRecordUtenti}
          checkId={`switch-${row.id}`}
        />
      );
    },
    width: '10rem',
  },
];

export const utenti = {
  preview: baseColumns,
  complete: baseColumns,
};
