import { createSelector } from 'reselect';
import { Utente } from 'store/utenti/utente';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getUtentiState = (state: RootState) => state.utenti;

export const getUtentiLoader = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loading === PENDING
);

export const getUtentiFailed = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loading === FAILED
);

export const getUtentiSuccess = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loading === SUCCEEDED
);

export const getUtentiOptionLoader = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loadingOption === PENDING
);

export const getUtentiOptionFailed = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loadingOption === FAILED
);

export const getUtentiOptionSuccess = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loadingOption === SUCCEEDED
);

export const getUtentiEnumLoader = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loadingEnum === PENDING
);

export const getUtentiEnumFailed = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loadingEnum === FAILED
);

export const getUtentiEnumSuccess = createSelector(
  getUtentiState,
  (utentiState) => utentiState.loadingEnum === SUCCEEDED
);

export const getUtentiError = createSelector(getUtentiState, (utentiState) => utentiState?.error);

export const getUtenti = createSelector(
  getUtentiState,
  (utentiState): Utente[] => utentiState?.data
);

export const getUtentiInfo = createSelector(
  getUtentiState,
  (utentiState): Info => utentiState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getUtentiOption = createSelector(
  getUtentiState,
  (utentiState) => utentiState?.dataOption
);

export const getUtentiKeyValues = createSelector(
  getUtenti,
  (utenti) =>
    utenti?.map((utente: Utente) => ({
      id: utente.id,
      descrizione: `${utente.name} ${utente.lastname}`,
    })) ?? []
);
export const getUtentiOptionKeyValues = createSelector(
  getUtentiOption,
  (utenti) =>
    utenti?.map((utente: Utente) => ({
      id: utente.id,
      descrizione: `${utente.name} ${utente.lastname}`,
    })) ?? []
);

export const getUtentiOptionInfo = createSelector(
  getUtentiState,
  (utentiState) => utentiState?.infoOption
);

export const getUtenteById = (id: string | number) =>
  createSelector(getUtenti, (utenti) => utenti?.find((utenteElement) => utenteElement.id === id));

export const getUtenteOptionById = (id: string | number) =>
  createSelector(getUtentiOption, (utenti) =>
    utenti?.find((utenteElement) => utenteElement.id === id)
  );

export const getUtentiParams = createSelector(getUtentiState, (utentiState) => utentiState?.params);

export const getUtentiParamsOption = createSelector(
  getUtentiState,
  (utentiState) => utentiState?.paramsOption
);

export const getUtentiSelectedIds = createSelector(
  getUtentiState,
  (utentiState) => utentiState?.selectedIds
);
