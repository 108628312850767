import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContoCreateParams } from '../types';
import { apiClient, handleError, URL_CONTO, URL_CREATE } from '../../index';
import { AxiosResponse } from 'axios';

export const createConto = createAsyncThunk(
  'conto/create',
  async (params: ContoCreateParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_CONTO}${URL_CREATE}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
