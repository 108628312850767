import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setFiltroSelectedIds } from '.';
import { apiClient, handleError, RootState, setError } from '../../..';
import { URL_FILTRO, URL_DELETE_MULTI } from '../../..';

export const deleteFiltriMulti = createAsyncThunk(
  'filtri/fetch',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const params = {}; //state?.filtri?.selectedIds ?? [];
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_FILTRO}${URL_DELETE_MULTI}`,
        params: { ids: params },
      });
      if (response.status === 200) {
        const deleted = response.data.data.deleted;
        const notDeleted = response.data.data.notDeleted;
        if (deleted) {
          deleted.forEach((id: number) => {
            if (state?.filtri?.selectedIds.includes(id)) {
              thunkAPI.dispatch(setFiltroSelectedIds(id));
            }
          });
        }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
