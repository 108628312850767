import { MODULO_RIGHE } from './modules';
import { AFFILIATI_ID, ANAGRAFICA_ID, MODULO_ID, TabsId } from '../../store';
import { affiliatiGenericGrid, anagraficheGenericGrid } from './tables';
import { GridColumn } from 'components/GenericGrid';
import { AccessorGrid } from 'utils/types';
export const accessorGenericGrid = ({
  tabId,
  delete: handleDelete = () => {},
  add = () => {},
  perm,
}: AccessorGrid): GridColumn[] => {
  const map: Record<TabsId | string, any> = {
    [ANAGRAFICA_ID]: () => anagraficheGenericGrid(),
    [AFFILIATI_ID]: () => affiliatiGenericGrid(handleDelete),
  };
  return map[tabId]();
};
