import { TEXT, DATE } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';

const baseColumns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '5rem',
  },
  {
    Header: 'Sezione',
    accessor: 'module',
    sort: true,
    filterType: TEXT,
    width: '20rem',
  },
  {
    Header: 'Nome',
    accessor: 'nome',
    sort: true,
    filterType: TEXT,
    width: '20rem',
  },
  {
    Header: 'Data Creazione',
    accessor: 'created',
    sort: true,
    width: '20rem',
  },
];

export const filtri = {
  preview: baseColumns,
  complete: [...baseColumns],
};
