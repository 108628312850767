import { ColumnCustomHeader } from 'utils/constants/tables';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { accessor } from '../../utils/constants';
import {
  DELETE,
  DRIVE_TEST_ID,
  FETCHLIST,
  getAction,
  getActionType,
  getSelectorListData,
  getSelectorListDataParams,
  getSelectorLoading,
  getSelectorSelectedIds,
  mapAction,
  SETPARAMS,
  SETSELECTALL,
  SETSELECTEDIDS,
  TabsId,
} from '../../store';
import { Console } from 'console';

interface DataGridProps {
  tabId: TabsId;
  initialParams: any;
}

interface DataGridReturn {
  data: any;
  params: any;
  loading: boolean;
  updateParams: ({ skip, limit, ...rest }: any) => void;
  columnSettings: any;
  rowSelection: any;
  getData: () => void;
  show: boolean;
  onConfirm: () => void;
  handleClose: () => void;
  handleOpenDelete: (crudId: number) => void;
  crudId: number | null;
}

const defaultGridReturn: DataGridReturn = {
  data: [],
  params: { skip: 0, limit: 0 },
  loading: false,
  updateParams: (parameters: any) => void 0,
  columnSettings: [
    {
      Header: 'ID',
      accessor: 'id',
      isVisible: true,
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      isVisible: true,
      sort: false,
    },
    {
      Header: 'Descrizione',
      accessor: 'descrizione',
      isVisible: true,
      sort: false,
    },
  ],
  rowSelection: {
    selectedIds: [],
    onSelect: (record: any, selected: any, selectedRows: any) => {
      void 0;
    },
    onSelectAll: () => {
      void 0;
    },
  },
  getData: () => void 0,
  show: false,
  onConfirm: () => void 0,
  handleClose: () => void 0,
  handleOpenDelete: (crudId: number) => void 0,
  crudId: null,
};

export const useGrid = ({ tabId }: DataGridProps): DataGridReturn => {
  if (tabId === DRIVE_TEST_ID) return defaultGridReturn;
  const dispatch = useDispatch();
  const data = getSelectorListData(tabId);
  const params: Record<string, any> = getSelectorListDataParams(tabId);
  const loading: boolean = getSelectorLoading(tabId);
  const selectedIds = getSelectorSelectedIds(tabId);
  const [search, setSearch] = useState(false);
  // const [paramsLength, setParamsLength] = useState<number>(params ? Object.keys(params).length : 0);

  const [show, setShow] = useState<boolean>(false);

  const [crudId, setCrudId] = useState<number | null>(null);

  const handleDelete = async (id: number) => {
    const action = getAction(tabId, DELETE);
    action && (await dispatch(action({ id })));
    getData();
  };

  const handleClose = () => {
    setShow(false);
    setCrudId(null);
  };

  const handleOpenDelete = (crudId: number) => {
    setCrudId(crudId);
    setShow(true);
  };

  const onConfirm = async () => {
    await handleDelete(crudId as number);
    setCrudId(null);
    handleClose();
  };

  const updateParams = (parameters: any) => {
    if (parameters) {
      const action = getAction(tabId, SETPARAMS);
      action && dispatch(action(parameters));
      setSearch(true);
    }
  };

  const columnSettings = accessor(tabId);

  const getData = useCallback(async () => {
    setSearch(false);
    const actionFetch = getAction(tabId, FETCHLIST);
    actionFetch && (await dispatch(actionFetch()));
  }, [tabId]);

  const onSelectedRowKeysChange = useCallback(
    (selectedRowId: any) => {
      const actionSelectedIds = mapAction[tabId][SETSELECTEDIDS];
      actionSelectedIds && dispatch(actionSelectedIds(selectedRowId));
    },
    [tabId]
  );

  const onSelectAllRecord = useCallback(() => {
    const actionSelectedIds = mapAction[tabId][SETSELECTALL];
    actionSelectedIds && dispatch(actionSelectedIds());
  }, []);

  const rowSelection = {
    selectedIds,
    onSelect: (record: any, selected: any, selectedRows: any) => {
      onSelectedRowKeysChange(record?.id);
    },
    onSelectAll: () => {
      onSelectAllRecord();
    },
  };

  // useEffect(() => {
  //   if (fetchParams) {
  //     const action = getAction(tabId, SETPARAMS);
  //     console.log({ fetchParams });
  //     action && dispatch(action(fetchParams));
  //     setSearch(true);
  //   }
  // }, [{ ...fetchParams }]);

  useEffect(() => {
    params?.limit && search && getData();
  }, [search, params?.limit]);

  return {
    data,
    params,
    loading,
    updateParams,
    columnSettings,
    rowSelection,
    getData,
    show,
    onConfirm,
    handleClose,
    handleOpenDelete,
    crudId,
  };
};
