import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAction,
  getSelectorListDataInfo,
  getSelectorSelectedIds,
  TabsId,
  useAppDispatch,
} from 'store';

interface IndeterminateCheckboxProps {
  tabId: TabsId;
  header?: boolean;
  id?: number | string;
  indeterminate?: any;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({ indeterminate, header = false, tabId, id, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    const { total } = getSelectorListDataInfo(tabId);

    const selectedIds = getSelectorSelectedIds(tabId) as any[];
    const [isChecked, setIsChecked] = useState(
      !header && !!selectedIds?.find((elementId) => elementId === id)
    );

    const dispatch = useAppDispatch();

    const getActionCheckbox = () => {
      if (header) {
        return getAction(
          tabId,
          selectedIds?.length === total ? 'resetSelectedIds' : 'setSelectAll'
        );
      }
      return getAction(tabId, 'setSelectedIds');
    };

    const onClick = () => {
      const selectAction = getActionCheckbox();
      selectAction && dispatch(header ? selectAction() : selectAction(id));
    };

    useEffect(() => {
      resolvedRef.current.indeterminate =
        header && selectedIds.length > 0 && selectedIds.length !== total;
    }, [selectedIds.length]);

    return (
      <>
        <input
          {...rest}
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          checked={!header ? !!selectedIds?.includes(id) : selectedIds?.length === total}
          defaultValue="false"
          onClick={onClick}
          onChange={(ev: any) => console.log({ ev })}
        />
      </>
    );
  }
);

export default IndeterminateCheckbox;
