import { createSelector } from 'reselect';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState } from '../..';
import { ModuleName } from 'utils';

export const getUserState = (state: RootState) => state.user;

export const getUserLoader = createSelector(
  getUserState,
  (userState) => userState.loading === PENDING
);

export const getUserFailed = createSelector(
  getUserState,
  (userState) => userState.loading === FAILED
);

export const getUserError = createSelector(getUserState, (userState) => userState?.error);

export const getUser = createSelector(getUserState, (userState) => userState?.data ?? null);
export const getUserRole = createSelector(getUser, (user) => user?.role ?? 'admin');
export const getPerms = createSelector(getUser, (user) => user?.perms ?? []);
export const getPermsByModule = (module: ModuleName) =>
  createSelector(getPerms, (perms) => perms?.find((perm) => perm.module === module));
