import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setReferenteParams } from '.';
import { apiClient, handleError, RootState } from '../../../..';
import { URL_REFERENTE, URL_LIST } from '../../../..';

export const fetchReferenti = createAsyncThunk(
  'referenti/fetch',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const parameters = state?.referenti?.params ?? {};
      const { parentId, ...params } = parameters;
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_REFERENTE}/${parentId}${URL_LIST}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
