export const ITALIAN_IBAN_REGEX = /^IT[0-9]{2}[a-zA-Z][0-9]{5}[0-9]{5}[a-zA-Z0-9]{12}$/;
export const INTERNATIONAL_IBAN_REGEX =
  /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i;

export const isValidItalianIBAN = (iban?: string): boolean => {
  // Rimuovi spazi e converti in maiuscolo
  if (!iban) return false;
  iban = iban.replace(/\s/g, '').toUpperCase();

  // Controlla la lunghezza (27 caratteri per l'Italia)
  if (iban.length !== 27) return false;

  // Controlla il codice paese (IT per l'Italia)
  if (iban.slice(0, 2) !== 'IT') return false;

  // Sposta i primi 4 caratteri alla fine
  iban = iban.slice(4) + iban.slice(0, 4);

  // Converti le lettere in numeri (A=10, B=11, ..., Z=35)
  iban = iban
    .split('')
    .map((char) => {
      const code = char.charCodeAt(0);
      return code >= 65 && code <= 90 ? (code - 55).toString() : char;
    })
    .join('');

  // Calcola il modulo
  let remainder = iban;
  while (remainder.length > 2) {
    const block = remainder.slice(0, 9);
    remainder = (parseInt(block, 10) % 97).toString() + remainder.slice(9);
  }

  // Il numero risultante dovrebbe essere 1
  return parseInt(remainder, 10) === 1;
};
