import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loader from 'components/Loader';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TabsId, getSelectorListData, getSelectorLoading } from 'store';
import { ColumnData, convertAccessorToColumnData } from 'utils';

interface ModalTableProps {
  tabId: TabsId;
  hasSelectRow?: boolean;
}

type RowModalTable = Record<string, any>;

export const ModalTable = ({ tabId, hasSelectRow = false }: ModalTableProps) => {
  const columns = convertAccessorToColumnData(tabId) as ColumnData<RowModalTable>[];
  const data: any = getSelectorListData(tabId);
  const isLoading = getSelectorLoading(tabId);
  const [selected, setSelected] = useState<RowModalTable[]>([]);
  const [rows, setRows] = useState<RowModalTable[]>([]);

  const onSelectAllClick = () => {
    if (selected.length < data.length) {
      setSelected(data.filter((item: any) => item.fatturabile));
    }
    if (selected.length === data.length) setSelected([]);
  };

  const handleSelect = (event: any, id: number) => {
    const selectedIndex = selected.findIndex((item: RowModalTable) => item.id === id);
    if (selectedIndex >= 0) {
      return setSelected(selected.filter((item) => item.id !== id) ?? []);
    }
    setSelected([...selected, data.find((item: RowModalTable) => item.id === id)]);
  };

  useMemo(() => {
    setRows(data && data.length ? data.filter((item: any) => item.fatturabile) : []);
  }, [JSON.stringify(data)]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {hasSelectRow ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={onSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
              ) : (
                <div />
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Loader />
            ) : data.length === 0 || !data ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="h5" align="center">
                    Non sono presenti datai
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row: RowModalTable, index: number) => {
                const isSelected = !!selected.find((sel: RowModalTable) => sel.id === row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={(event) =>
                      hasSelectRow ? handleSelect(event, row.id as number) : null
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${row.id}`}
                    style={{ backgroundColor: 'white' }}
                    selected={isSelected}
                  >
                    {hasSelectRow ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    ) : (
                      <div />
                    )}
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format(value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
};

export default ModalTable;
