import { useEffect, useState } from 'react';
import { Box, Flex } from 'components';
import { Button } from 'react-bootstrap';
import { Drawer, Typography } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import { FiltroChiave, TabsId } from 'store';
import { FilterColumn } from './FilterColumn';
import { FormProvider, useForm } from 'react-hook-form';
import { keyify } from 'helpers';

interface Props {
  tabId: TabsId;
  filters: FiltroChiave[];
  params: any;
  hasCreateFilter?: boolean;
  columnSettings?: any;
  setFilters: (filters: FiltroChiave[]) => void;
  updateParams: (params: any) => void;
  handleOpenFiltri: () => void;
}

const PARAMS_NO_CLEAR = ['limit', 'qvalue', 'skip', 'orderBy', 'mese', 'anno'];

export const Filters = ({
  tabId,
  columnSettings,
  params,
  filters,
  hasCreateFilter = false,
  setFilters,
  updateParams,
  handleOpenFiltri,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const methods = useForm<any>({
    mode: 'onBlur',
    defaultValues: {},
  });

  const { setValue, reset, register } = methods;

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const checkParams = () => {
    if (params) {
      const filteredKeys = Object.keys(params).filter((key) => !PARAMS_NO_CLEAR.includes(key));

      return filteredKeys.some((key) => params[key] !== undefined && params[key] !== null);
    }
    return false;
  };

  const handleClearParams = () => {
    console.log({ params });
    if (params) {
      const copyParams = { ...params };
      const keys = Object.keys(params);
      keys.forEach((key: string) => {
        if (!PARAMS_NO_CLEAR.includes(key)) {
          copyParams[key] = undefined;
          setValue(key as any, undefined);
        }
      });
      console.log({ copyParams });
      updateParams(copyParams);
      reset(copyParams);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (params) {
      const keys = keyify(params) as string[];
      const filteredKeys = keys.filter((key) => !PARAMS_NO_CLEAR.includes(key));
      filteredKeys.forEach((key) => {
        if (params) {
          return setValue(key as any, params[key]);
        }
      });
    }
  }, [tabId, setValue, isOpen]);

  return columnSettings ? (
    <Flex paddingRight="2rem" paddingBottom="0.2rem">
      <Button
        variant={`soft-${checkParams() ? 'danger' : 'primary'}`}
        className="cursor-pointer"
        style={{
          margin: '0', // Rimuove il padding del pulsante
          borderRadius: '50%', // Rende il pulsante perfettamente rotondo
          width: '30px', // Imposta una larghezza fissa
          height: '30px', // Imposta un'altezza fissa per assicurarsi che sia uguale alla larghezza
          display: 'flex', // Usa flex per centrare l'icona all'interno del pulsante
          justifyContent: 'center', // Centra orizzontalmente l'icona
          alignItems: 'center', // Centra verticalmente l'icona
          marginRight: '.5rem',
        }}
        onClick={toggleDrawer(true)}
      >
        <i className="uil uil-filter"></i>
      </Button>
      <Flex fontWeight="700" fontSize="0.9rem" alignItems="center">
        Filtri
      </Flex>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        sx={{ width: 350 }}
        ModalProps={{
          // Sovrascrive gli stili del backdrop per renderlo trasparente
          BackdropProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        <FormProvider {...methods}>
          <Box
            display="grid"
            gridTemplateRows={`90px auto 90px`}
            // flexDirection="column"
            // justifyContent={'space-between'}
            // position="relative"
            height="100vh"
          >
            <Flex
              width="30vw"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              padding="2rem"
              backgroundColor="var(--bs-gray-200)"
              justifyContent="space-between"
            >
              <Typography
                variant="body2" // Scegli la variante che meglio si adatta alle tue esigenze
                style={{ fontWeight: 600, fontSize: '1.7em' }} // Peso del font non in grassetto e dimensione leggermente più piccola
              >
                Filtri
              </Typography>
              {hasCreateFilter ? (
                <Flex paddingRight="1rem" paddingTop="0rem">
                  <Button
                    variant={`soft-primary`}
                    className="cursor-pointer"
                    style={{
                      margin: '0', // Rimuove il padding del pulsante
                      borderRadius: '50%', // Rende il pulsante perfettamente rotondo
                      width: '30px', // Imposta una larghezza fissa
                      height: '30px', // Imposta un'altezza fissa per assicurarsi che sia uguale alla larghezza
                      display: 'flex', // Usa flex per centrare l'icona all'interno del pulsante
                      justifyContent: 'center', // Centra orizzontalmente l'icona
                      alignItems: 'center', // Centra verticalmente l'icona
                      marginRight: '1rem',
                    }}
                    onClick={handleOpenFiltri}
                  >
                    <i className="uil uil-filter"></i>
                  </Button>
                  <Flex fontWeight="700" fontSize="0.9rem" alignItems="center">
                    Crea filtro modello
                  </Flex>
                </Flex>
              ) : (
                <div />
              )}
            </Flex>
            <Box overflowY="auto" padding="2rem">
              {columnSettings.map((column: any) => {
                return column.filterType ? (
                  <FilterColumn
                    type={column.filterType}
                    tabId={tabId}
                    title={column.Header}
                    field={column.accessor as any}
                    params={params}
                    updateParams={updateParams}
                    filters={filters}
                    setFilters={setFilters}
                    options={column.options}
                    register={register}
                  />
                ) : (
                  <div style={{ height: '30px' }}></div>
                );
              })}
            </Box>
            <Flex
              justifyContent="end"
              backgroundColor="var(--bs-gray-100)"
              padding="2rem"
              alignItems="center"
            >
              <MuiButton
                variant="outlined"
                onClick={handleClearParams}
                sx={{
                  borderRadius: '9999px',
                  fontSize: '.9em',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Svuota e Chiudi
              </MuiButton>
            </Flex>
          </Box>
        </FormProvider>
      </Drawer>
    </Flex>
  ) : (
    <div />
  );
};
