import TextField from '@mui/material/TextField';
import { Flex, GenericGrid } from 'components';
import Box from 'components/Box';
import React from 'react';
import { TabsId } from 'store';
import { useSimpleTable } from './hook';
import SearchIcon from '@mui/icons-material/Search';
import { ConfrimModal } from 'components/ConfirmModal';

export interface Props<T> {
  tabId: TabsId;
  parentId?: number;
  additionalParams?: Record<string, any>;
  height?: string;
  confirmDeleteText?: string;
}

export const DataOptionTable: React.FC<Props<any>> = ({
  parentId,
  tabId,
  additionalParams,
  height,
  confirmDeleteText,
}) => {
  const [search, setSearch] = React.useState('');

  const [isNew, setIsNew] = React.useState<boolean>(false);
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  const {
    columnSettings: columns,
    handleSearchChange,
    show,
    onConfirm,
    handleClose,
    handleOpenDelete,
    crudId,
  } = useSimpleTable({
    tabId,
    initialParams: additionalParams,
    parentId,
    dataRows,
    setDataRows,
    setIsNew,
    setSearch,
  });

  const getConfirmText = () => {
    if (confirmDeleteText) return confirmDeleteText;
    return `Confermi di voler eliminare la riga con ID: ${crudId}?`;
  };

  return (
    <Box>
      <Flex justifyContent="end" paddingBottom="1rem">
        <TextField
          variant="outlined"
          size="small"
          placeholder="Ricerca"
          value={search}
          onChange={handleSearchChange}
          InputProps={{ startAdornment: <SearchIcon /> }}
        />
      </Flex>
      <div style={{ overflowX: 'auto' }}>
        <GenericGrid columns={columns} data={dataRows} tabId={tabId} height={height} />
      </div>
      <ConfrimModal
        show={show}
        setShow={(show: boolean) => handleClose()}
        title={'Elimina riga'}
        onConfirm={onConfirm}
      >
        <Flex justifyContent="center" marginBottom=".5rem">
          {getConfirmText()}
        </Flex>
      </ConfrimModal>
    </Box>
  );
};

export default DataOptionTable;
