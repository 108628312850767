import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  apiClient,
  FatturazioneRigheFetchParams,
  handleError,
  URL_ITEM,
  URL_RIGHE,
} from '../../..';
import { URL_FATTURAZIONE, URL_LIST } from '../../..';

export const fetchRigheFatturazione = createAsyncThunk(
  'fatturazioni/fetchRighe',
  async (params: FatturazioneRigheFetchParams, thunkAPI): Promise<any> => {
    try {
      const { id } = params;
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_FATTURAZIONE}${URL_ITEM}/${id}${URL_RIGHE}${URL_LIST}`,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
