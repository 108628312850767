/**
 * mese_riferimento?: number;
 * anno_riferimento?: number;
 * intestatario_id?: string;
 * tipologia?: string;
 * categoria?: string;
 * ragione_sociale?: string;
 * indirizzo?: string;
 * cap?: string;
 * localita?: string;
 * provincia?: string;
 * regione?: string;
 * nazione?: string;
 * piva?: string;
 * codice_fiscale?: string;
 * pec?: string;
 * codice_destinatario?: string;
 * data?: string;
 * f24_doc_id?: string;
 * f24_description?: string;
 * f24_doc_number?: string;
 * f24_response?: string;
 * totale_netto?: string;
 * totale_iva?: string;
 * totale_ivato?: string;
 * scheda?: Scheda;
 * riga?: Record<string, any>;
 * inviato_sdi?: boolean;
 */

import { NUMBER, SELECT, TEXT } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';
import { CheckInvioSDI, CurrencyFormatter } from 'components';
import {
  DOCUMENTO_FATTURA_VENDITA,
  DocumentoSiglaColor,
  DocumentoSiglaLabels,
  DocumentoSiglaOptions,
  StatoInvio,
  StatoInvioLabels,
  StatoInvioOptions,
  TYPE_BOOLEAN,
  TYPE_CURRENCY,
} from 'store';
import { GenericObject } from 'utils/types';
import { Badge } from '@mui/material';
import { getVariantForInvioStato } from './utils';

const columns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '5rem',
  },
  {
    Header: 'N. Fattura',
    accessor: 'f24_doc_number',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'Tipo documento',
    accessor: 'tipo',
    sort: true,
    filterType: SELECT,
    options: DocumentoSiglaOptions,
    width: '12rem',
    actionComponent: (row: GenericObject) => {
      if (row.tipo) {
        const value = DocumentoSiglaLabels[row.tipo ?? DOCUMENTO_FATTURA_VENDITA];
        const bgColor = DocumentoSiglaColor[row.tipo];
        console.log({ tipo: row.tipo, value, bgColor });
        return (
          <Badge
            key={`stato-${row.id}`}
            className={`badge bg-${bgColor}`}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '1.2rem',
              fontSize: '0.8rem',
              backgroundColor: bgColor,
            }}
          >
            {value}
          </Badge>
        );
      }
      return <div>-</div>;
    },
  },
  {
    Header: 'Class. documento',
    accessor: 'doc_type',
    sort: false,
    filterType: TEXT,
    width: '12rem',
  },
  {
    Header: 'Stato invio',
    accessor: 'invio_stato',
    sort: true,
    filterType: SELECT,
    options: StatoInvioOptions,
    width: '10rem',
    actionComponent: (row: GenericObject) => {
      if (row.invio_stato) {
        const value = StatoInvioLabels[row.invio_stato];
        const variantStato = getVariantForInvioStato(row.invio_stato as StatoInvio);
        return (
          <Badge
            key={`stato-${row.id}`}
            className={`soft-${variantStato} badge bg-soft-${variantStato}`}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '1.2rem',
              fontSize: '0.8rem',
            }}
          >
            {value}
          </Badge>
        );
      }
      return <div>-</div>;
    },
  },
  {
    Header: 'Ragione Sociale',
    accessor: 'ragione_sociale',
    sort: true,
    filterType: TEXT,
    width: '40rem',
  },
  {
    Header: 'P.IVA',
    accessor: 'piva',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'C.Fiscale',
    accessor: 'codice_fiscale',
    sort: true,
    filterType: TEXT,
    width: '12rem',
  },
  {
    Header: 'Città',
    accessor: 'localita',
    sort: true,
    filterType: TEXT,
    width: '12rem',
  },
  {
    Header: 'CAP',
    accessor: 'cap',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'Provincia',
    accessor: 'provincia',
    sort: true,
    filterType: TEXT,
    width: '10rem',
  },
  {
    Header: 'Indirizzo',
    accessor: 'indirizzo',
    sort: true,
    filterType: TEXT,
    width: '35rem',
  },
  {
    Header: 'Tot. Netto',
    accessor: 'totale_netto',
    type: TYPE_CURRENCY,
    sort: true,
    formatComponent: (value: any) => {
      return <CurrencyFormatter amount={Number(value)} currency={'EUR'} />;
    },
    width: '15rem',
  },
  {
    Header: 'Tot. IVA',
    accessor: 'totale_iva',
    type: TYPE_CURRENCY,
    sort: true,
    formatComponent: (value: any) => {
      return <CurrencyFormatter amount={Number(value)} currency={'EUR'} />;
    },
    width: '15rem',
  },
  {
    Header: 'Totale',
    accessor: 'totale_ivato',
    type: TYPE_CURRENCY,
    sort: true,
    formatComponent: (value: any) => {
      return <CurrencyFormatter amount={Number(value)} currency={'EUR'} />;
    },
    width: '15rem',
  },
  {
    Header: 'Inviata SDI',
    accessor: 'inviato_sdi',
    type: TYPE_BOOLEAN,
    sort: false,
    actionComponent: (row: any) => {
      return <CheckInvioSDI value={row.inviato_sdi ?? false} row={row} showSwitch />;
    },
    width: '10rem',
  },
];

export const fatturazione = {
  preview: columns,
  complete: columns,
};
