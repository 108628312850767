import { CampiScheda, SchedaState, ValidationError } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import {
  cambioStatoScheda,
  createScheda,
  deleteScheda,
  fetchScheda,
  resetScheda,
  campiFatturazioneScheda,
} from '../actions';
import { FAILED, IDLE, PENDING, SUCCEEDED } from '../../../types';
import { validateScheda } from '../actions/validate';

const initialState: SchedaState & {
  validationError: ValidationError | null;
  campiScheda: CampiScheda[];
} = {
  data: null,
  loading: IDLE,
  error: null,
  config: null,
  list: [],
  validationError: null,
  campiScheda: [],
};

export const schedaSlice = createSlice({
  name: 'scheda',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchScheda.fulfilled, (state: any, { payload }: any) => {
        state.data = payload?.data?.scheda;
        state.list = payload?.data?.list;
        state.config = payload?.data?.config;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        fetchScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(validateScheda.fulfilled, (state: any, { payload }: any) => {
        state.validationError = payload?.data;
        state.list = payload?.data?.righe;
        state.loading = SUCCEEDED;
      })
      .addCase(validateScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        validateScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(campiFatturazioneScheda.fulfilled, (state: any, { payload }: any) => {
        state.campiScheda = payload?.data;
        state.loading = SUCCEEDED;
      })
      .addCase(campiFatturazioneScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        campiFatturazioneScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(createScheda.fulfilled, (state: any, { payload }: any) => {
        state.data = payload;
        state.list = [];
        state.config = null;
        state.loading = SUCCEEDED;
      })
      .addCase(createScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        createScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(resetScheda, (state: any) => {
        state.data = null;
        state.list = [];
        state.config = null;
        state.loading = IDLE;
        state.error = null;
        state.validationError = null;
        state.campiScheda = [];
      })
      .addCase(
        deleteScheda.fulfilled,
        (state: { data: null; list: never[]; config: null; loading: string }) => {
          state.data = null;
          state.list = [];
          state.config = null;
          state.loading = SUCCEEDED;
        }
      )
      .addCase(deleteScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        deleteScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      // .addCase(validateScheda.fulfilled, (state: any, { payload }: any) => {
      //   console.log({ state, payload });
      //   // state.validationError = payload.data;
      //   // if (payload.anagrafiche && payload.anagrafiche.length > 0) {
      //   //   state.validationError.anagrafiche = payload.anagrafiche;
      //   // }
      //   // if (payload.righe && payload.righe.length > 0) {
      //   //   state.validationError.righe = payload.righe;
      //   // }
      //   // state.loading = SUCCEEDED;
      // })
      // .addCase(validateScheda.pending, (state: any) => (state.loading = PENDING))
      // .addCase(
      //   validateScheda.rejected,
      //   (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
      //     state.error = action?.error?.code || action?.error?.name || '';
      //     state.loading = FAILED;
      //   }
      // )
      .addCase(cambioStatoScheda.fulfilled, (state: any, { payload }: any) => {
        state.data = payload?.data?.scheda;
        state.list = payload?.data?.list;
        state.config = payload?.data?.config;
        state.loading = SUCCEEDED;
      })
      .addCase(cambioStatoScheda.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        cambioStatoScheda.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      );
  },
});
