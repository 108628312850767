import { Scheda } from 'store/schede';
import { KeyValue } from 'store/types';
import { Generics } from 'utils';

export interface Fatturazione {
  id?: number;
  mese_riferimento?: number;
  anno_riferimento?: number;
  intestatario_id?: string;
  tipo?: DocumentoSigla;
  doc_type?: string;
  ragione_sociale?: string;
  indirizzo?: string;
  cap?: string;
  localita?: string;
  provincia?: string;
  regione?: string;
  nazione?: string;
  piva?: string;
  codice_fiscale?: string;
  pec?: string;
  codice_destinatario?: string;
  data?: string;
  f24_doc_id?: string;
  f24_description?: string;
  f24_doc_number?: string;
  f24_response?: string;
  totale_netto?: string;
  totale_iva?: string;
  totale_ivato?: string;
  scheda?: Scheda;
  riga?: Record<string, any>;
  inviato_sdi?: boolean;
  created?: Date;
  updated?: Date;
}

export class FatturazioneClass implements Fatturazione {
  id = 0;
  created = undefined;
  updated = undefined;
}

export const INVIO_STATO_DI = 'DI';
export const INVIO_STATO_WINV = 'WINV';
export const INVIO_STATO_IN = 'IN';
export const INVIO_STATO_ERR = 'ERR';

export type StatoInvio =
  | typeof INVIO_STATO_DI
  | typeof INVIO_STATO_WINV
  | typeof INVIO_STATO_IN
  | typeof INVIO_STATO_ERR;

export const StatoInvioLabels: Generics<StatoInvio | string, string> = {
  [INVIO_STATO_DI]: 'Da inviare',
  [INVIO_STATO_WINV]: 'Invio in corso',
  [INVIO_STATO_IN]: 'Inviata',
  [INVIO_STATO_ERR]: 'Errore invio',
};

export const StatoInvioOptions: KeyValue[] = [
  { id: '', descrizione: '' },
  { id: INVIO_STATO_DI, descrizione: StatoInvioLabels[INVIO_STATO_DI] },
  { id: INVIO_STATO_WINV, descrizione: StatoInvioLabels[INVIO_STATO_WINV] },
  { id: INVIO_STATO_IN, descrizione: StatoInvioLabels[INVIO_STATO_IN] },
  { id: INVIO_STATO_ERR, descrizione: StatoInvioLabels[INVIO_STATO_ERR] },
];

export const StatoInvioColors: Generics<StatoInvio | string, string> = {
  [INVIO_STATO_DI]: '#FFA000',
  [INVIO_STATO_WINV]: '#1976D2',
  [INVIO_STATO_IN]: '#43A047',
  [INVIO_STATO_ERR]: '#D32F2F',
};

export const FATTURA_ELETTRONICA = 'fattura_elettronica';
export const NOTA_DI_CREDITO = 'nota_credito';

export type DocumentoTipo = typeof FATTURA_ELETTRONICA | typeof NOTA_DI_CREDITO;

export const DOCUMENTO_FATTURA_VENDITA = 'FE';
export const DOCUMENTO_NOTA_CREDITO = 'N';

export type DocumentoSigla = typeof DOCUMENTO_FATTURA_VENDITA | typeof DOCUMENTO_NOTA_CREDITO;

export const DocumentoSiglaColor: Generics<DocumentoSigla | string, string> = {
  [DOCUMENTO_FATTURA_VENDITA]: '#1976D2',
  [DOCUMENTO_NOTA_CREDITO]: '#43A047',
};

export const DocumentoSiglaLabels: Generics<DocumentoSigla | string, string> = {
  [DOCUMENTO_FATTURA_VENDITA]: 'Fattura di vendita',
  [DOCUMENTO_NOTA_CREDITO]: 'Nota di credito',
};

export const DocumentoSiglaOptions: KeyValue[] = [
  { id: '', descrizione: '' },
  { id: DOCUMENTO_FATTURA_VENDITA, descrizione: DocumentoSiglaLabels[DOCUMENTO_FATTURA_VENDITA] },
  { id: DOCUMENTO_NOTA_CREDITO, descrizione: DocumentoSiglaLabels[DOCUMENTO_NOTA_CREDITO] },
];
