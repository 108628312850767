import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModuloChiaveCreateParams, TipologiaChiaviSistema } from '../types';
import {
  apiClient,
  handleError,
  URL_MODULO,
  URL_CREATE,
  URL_MODULO_RIGHE,
  TYPE_CEDOLINI,
  TYPE_DATA_FATTURAZIONE,
} from '../../../index';
import { AxiosResponse } from 'axios';

export const createModuloChiave = createAsyncThunk(
  'moduloChiave/create',
  async (parameters: ModuloChiaveCreateParams, thunkAPI) => {
    const { modulo_id, parentId, ...rest } = parameters;
    const campo_sistema = [TYPE_CEDOLINI].find((type) => type === rest.tipo) ?? false;
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_MODULO}${URL_MODULO_RIGHE}${URL_CREATE}/${modulo_id ?? parentId}`,
        params: {
          ...rest,
          campo_sistema,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data);
    }
  }
);
