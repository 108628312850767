import React, { useEffect } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@ailibs/feather-react-ts';
// components

import AuthLayout from './AuthLayout';

// images
import logoDark from '../../../assets/images/logo-dark.png';
import logoLight from '../../../assets/images/logo-light.png';
import {
  RootState,
  AppDispatch,
  postSignIn,
  getErrorMessage,
  getAuthLoader,
  getIsAuthenticated,
  useAppDispatch,
  fetchUser,
  ANAGRAFICA_ID,
  setPage,
  PageInfo,
} from '../../../store';
import { FormInput, VerticalForm } from '../../../components';
import { aziendaInfo, getAzienda, getAziendaLogo } from 'store/azienda';
import ErrorAlert from 'components/ErrorAlert';
import { ANAGRAFICA } from 'utils';

const { REACT_APP_IPERPHONO_BE_MASTER_HOST } = process.env || {};

const URL_MASTER = REACT_APP_IPERPHONO_BE_MASTER_HOST;

interface UserData {
  email: string;
  password: string;
}

/* bottom links */
const BottomLink = () => {
  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          Non hai un account?
          <Link to={'/auth/register'} className="text-primary fw-bold ms-1">
            Sign Up
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Login = () => {
  const dispatch = useAppDispatch();

  const error: string = useSelector(getErrorMessage);
  const loading: boolean = useSelector(getAuthLoader);
  const userLoggedIn: boolean = useSelector(getIsAuthenticated);
  const azienda = useSelector(getAzienda);
  const aziendaLogo = useSelector(getAziendaLogo);
  const location = useLocation();
  const navigate = useNavigate();
  /*
    form validation schema
    */
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup.string().nullable().required('campo obbligatorio'),
      password: yup.string().nullable().required('campo obbligatorio'),
      checkbox: yup.bool().oneOf([true]),
    })
  );

  /*
  handle form submission
  */
  const redirectUrl = '/anagrafiche/list';
  const onSubmit = async (formData: UserData) => {
    const response = await dispatch(
      postSignIn({ username: formData['email'], password: formData['password'] })
    );
    if (response && !response.error) {
      await dispatch(fetchUser());
      const pageInfo = {
        tabId: ANAGRAFICA_ID,
        module: ANAGRAFICA,
        url: redirectUrl,
        title: 'Anagrafica Clienti',
      };
      dispatch(setPage(pageInfo as PageInfo));
      navigate(redirectUrl);
    }
  };

  const [logo, setLogo] = React.useState<string>('');

  useEffect(() => {
    if (!azienda) {
      dispatch(aziendaInfo());
    } else {
      setLogo(`${URL_MASTER}/${aziendaLogo}`);
    }
  }, [azienda?.id, aziendaLogo]);

  return (
    <>
      {/* {userLoggedIn ? <Navigate to={redirectUrl} state={{ from: location }} replace /> : null} */}

      <AuthLayout bottomLinks={<BottomLink />}>
        <div className="auth-logo mx-auto">
          <Link to="/" className="logo logo-dark text-center">
            <span className="logo-lg">
              <img src={logo} alt="" height="70" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light text-center">
            <span className="logo-lg">
              <img src={logo} alt="" height="70" />
            </span>
          </Link>
        </div>

        <h6 className="h5 mb-0 mt-3">{'Welcome back!'}</h6>
        <p className="text-muted mt-1 mb-4">
          Inserisci email e password per accedere al tuo account
        </p>

        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}

        <VerticalForm<UserData>
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{ email: null, password: null }}
          formClass="authentication-form"
        >
          <FormInput
            type="email"
            name="email"
            label={'Email Address'}
            startIcon={<Icon name="mail" className="icon-dual" />}
            placeholder="hello@coderthemes.com"
            containerClass={'mb-3'}
          />
          <FormInput
            type="password"
            name="password"
            label="Password"
            startIcon={<Icon name="lock" className="icon-dual" />}
            action={
              <Link
                to="/auth/forget-password"
                className="float-end text-muted text-unline-dashed ms-1"
              >
                Password Dimenticata?
              </Link>
            }
            placeholder="Inserisci la password"
            containerClass={'mb-3'}
          ></FormInput>

          <FormInput
            type="checkbox"
            name="checkbox"
            label="Ricordami"
            containerClass={'mb-3'}
            defaultChecked
          />

          <div className="mb-3 text-center d-grid">
            <Button type="submit" disabled={loading}>
              Log In
            </Button>
          </div>

          <ErrorAlert />
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default Login;
