import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Anagrafica, getPermsByModule } from 'store';
import { ModelFormProps } from '../model';
import DatiBancari from './DatiBancari';
import DatiContatto from './DatiContatto';
import DatiFatturazione from './DatiFatturazione';
import DatiGenerali from './DatiGenerali';
import DatiReferenti from './DatiReferenti';
import Note from './Note';
import SedeLegale from './SedeLegale';
import { FormProvider } from '../Context';
import { ANAGRAFICA, FATTURAZIONE } from 'utils';
import { useSelector } from 'react-redux';
import Affiliati from './Affiliati';

export const AnagraficaForm = ({ id, element }: ModelFormProps<Anagrafica>) => {
  const [activeKey, setActiveKey] = useState<string>('dati_anagrafici');
  const perms = useSelector(getPermsByModule(FATTURAZIONE));
  const { trigger } = useFormContext();
  return (
    <FormProvider module={ANAGRAFICA}>
      <Tabs
        id="noanim-tab-example"
        defaultActiveKey="dati_anagrafici"
        activeKey={activeKey}
        onSelect={async (eventKey: any, ev: any) => {
          const noErrors = await trigger();
          noErrors && setActiveKey(eventKey);
        }}
      >
        <Tab eventKey="dati_anagrafici" title="Azienda">
          <div>
            <DatiGenerali />
            <DatiContatto />
            <SedeLegale />
          </div>
        </Tab>
        <Tab eventKey="dati:referenti" title="Referenti">
          <DatiReferenti />
        </Tab>

        {perms && perms.can_read && (
          <Tab eventKey="dati_fiscali" title="Fatturazione">
            <DatiFatturazione />
            <Affiliati />
          </Tab>
        )}
        <Tab eventKey="dati_bancari" title="Dati bancari">
          <DatiBancari />
        </Tab>
        <Tab eventKey="note" title="Note">
          <Note />
        </Tab>
      </Tabs>
    </FormProvider>
  );
};

export default AnagraficaForm;
