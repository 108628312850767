import * as Yup from 'yup';

const duplicaSchedaSchema = Yup.object().shape({
  nome: Yup.string().nullable().required('campo obbligatorio'),
  mese: Yup.string().nullable().required('campo obbligatorio'),
  anno: Yup.string().nullable().required('campo obbligatorio'),
  conferma: Yup.string()
    .nullable()
    .required('campo obbligatorio')
    .test('conferma_valid', 'Digitare CONFERMA', (value: any) => value === 'CONFERMA'),
});

export default duplicaSchedaSchema;
