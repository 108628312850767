import { UpdateDataFatturazioneScheda } from '../types';
import { apiClient, handleError, URL_SCHEDA, URL_DATA_FATTURAZIONE } from '../../../index';
import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateDataFatturazioneScheda = createAsyncThunk(
  'scheda/updateDataFatturazione',
  async (params: UpdateDataFatturazioneScheda, thunkAPI: any) => {
    try {
      console.log({ request: params });
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_SCHEDA}${URL_DATA_FATTURAZIONE}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
