import {
  Divider,
  InputBase,
  Paper,
  Input,
  TextField,
  InputAdornment,
  Drawer,
  Typography,
} from '@mui/material';
import {
  AdvancedConfrimModal,
  Box,
  DropdownMenu,
  Flex,
  SelectorTime,
  StatisticheFatturazione,
} from 'components';
import { ChangeEvent, useState } from 'react';
import { Row, Button, Dropdown } from 'react-bootstrap';
import {
  DELETEMULTI,
  FETCHLIST,
  FiltroChiave,
  Perm,
  RESETSELECTEDIDS,
  RangeDate,
  TabsId,
  getAction,
  getPermsByModule,
  getSelectorSelectedIds,
  mapAction,
  useAppDispatch,
} from 'store';
import { MenuItem } from 'utils/types';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import React from 'react';
import { FATTURAZIONE, FILTRO, IMPORTAZIONE, SCHEDA } from 'utils';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from '@mui/material';
import { Filters } from './Filters';

interface HeaderProps {
  tabId: TabsId;
  params: any;
  updateParams: (params: any) => void;
  handleOpenFiltri: () => void;
  columnSettings?: any;
  permsModule?: Perm;
  title?: string;
  menu?: MenuItem[];
  hasNew?: boolean;
  hasImport?: boolean;
  hasExport?: boolean;
  hasDeleteMulti?: boolean;
  handleOpenNew?: () => void;
  handleOpenImport?: () => void;
  hasCreateFilter?: boolean;
  filters: FiltroChiave[];
  setFilters: (filters: FiltroChiave[]) => void;
  hasSelectorTime: boolean;
  hasSchedeSelector: boolean;
  hasFatturazioneStats: boolean;
  customButtons?: React.ReactElement[];
}

const SEARCH_FIELD = 'qvalue';

export const Header = ({
  tabId,
  permsModule,
  title,
  columnSettings,
  menu,
  params,
  hasNew = true,
  hasImport = true,
  hasExport = true,
  hasDeleteMulti = true,
  setFilters,
  updateParams,
  handleOpenFiltri,
  handleOpenNew,
  handleOpenImport,
  hasCreateFilter = false,
  filters,
  hasSelectorTime,
  hasSchedeSelector,
  hasFatturazioneStats,
  customButtons,
}: HeaderProps) => {
  const [time, setTime] = useState<RangeDate | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const permsFiltri = useSelector(getPermsByModule(FILTRO));
  const permsImportazione = useSelector(getPermsByModule(IMPORTAZIONE));
  const permsScheda = useSelector(getPermsByModule(SCHEDA));
  const permsFatturazione = useSelector(getPermsByModule(FATTURAZIONE));

  const selectedIds = getSelectorSelectedIds(tabId);

  const [show, setShow] = useState<boolean>(false);

  const actionDeleteMulti = getAction(tabId, DELETEMULTI);

  const dispatch = useAppDispatch();

  const menuNew: MenuItem = {
    key: `new-${tabId}`,
    title: 'Aggiungi',
    onClick: handleOpenNew ?? (() => void 0),
    icon: 'plus-circle',
  };
  const menuImport: MenuItem = {
    key: `import-${tabId}`,
    title: 'Importa',
    onClick: handleOpenImport ?? (() => void 0),
    icon: 'upload-cloud',
  };

  const menuExport: MenuItem = {
    key: `export-${tabId}`,
    title: 'Esporta',
    onClick: () => void 0,
    icon: 'download-cloud',
  };

  const menuDeleteSelected: MenuItem = {
    key: `delete-selected-${tabId}`,
    title: 'Elimina selezionati',
    onClick: () => setShow(true),
    icon: 'trash-2',
    color: 'red',
    disabled: !selectedIds || selectedIds.length === 0,
  };
  const menuOptions: MenuItem[] = [
    ...(menu && menu.length > 0 ? menu : []),
    ...(hasNew && permsModule && permsModule.can_write ? [menuNew] : []),
    ...(hasImport && permsImportazione && permsImportazione.can_write ? [menuImport] : []),
    ...(hasExport ? [menuExport] : []),
    ...(hasDeleteMulti && permsModule && permsModule.can_write ? [menuDeleteSelected] : []),
  ];
  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    if (!value.length) {
      const { ...otherParams } = params;
      otherParams[SEARCH_FIELD] = undefined;
      return updateParams({ ...otherParams });
    }
    value.length >= 3 && updateParams({ ...params, [SEARCH_FIELD]: value });
  };

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef?.current?.value) {
      const { ...otherParams } = params;
      inputRef.current.value = '';
      otherParams[SEARCH_FIELD] = undefined;
      return updateParams({ ...otherParams });
    }
  };

  const handelDeleteMulti = async () => {
    if (actionDeleteMulti) {
      setShow(false);
      const response = await dispatch(actionDeleteMulti());
      if (response && !response.error) {
        const actionList = getAction(tabId, FETCHLIST);
        const response = await dispatch(actionList());
        const resetSelectedIds = getAction(tabId, RESETSELECTEDIDS);
        resetSelectedIds && dispatch(resetSelectedIds());
        return response;
      }
    }
  };

  const sizeMenu = menuOptions ? 4 : 0;
  const sizeSelectorTime = hasSelectorTime ? 4 : 0;
  const sizeClear = 12 - sizeMenu - sizeSelectorTime;

  return (
    <Flex justifyContent={'space-between'} width="100vw">
      <TextField
        inputRef={inputRef}
        onChange={onChange}
        placeholder="Ricerca generale"
        sx={{
          ml: 1,
          paddingLeft: '.5rem',
          '& .MuiInputBase-root': {
            width: '500px',
            borderRadius: '10px',
            border: '1px solid var(--primary)',
            height: '2rem',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                color="primary"
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={inputRef?.current?.value ? handleClick : void 0}
              >
                {inputRef?.current?.value ? <ClearIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Flex alignItems="center">
        {customButtons &&
          customButtons.map((button, index) => (
            <Flex key={index} justifyContent="center" alignItems="center" marginLeft="1rem">
              {button}
            </Flex>
          ))}
        {hasSelectorTime && permsScheda && permsScheda.can_read && (
          <SelectorTime
            tabId={tabId}
            params={params}
            updateParams={updateParams}
            hasSchedeSelector={hasSchedeSelector}
          />
        )}
        {hasFatturazioneStats && permsFatturazione && permsFatturazione.can_read && (
          <Flex>
            <StatisticheFatturazione params={params} updateParams={updateParams} />
          </Flex>
        )}
        <Filters
          tabId={tabId}
          columnSettings={columnSettings}
          filters={filters}
          setFilters={setFilters}
          params={params}
          updateParams={updateParams}
          hasCreateFilter={
            (hasCreateFilter &&
              permsFiltri &&
              permsFiltri.can_write &&
              (filters.length || (selectedIds && selectedIds.length))) as boolean
          }
          handleOpenFiltri={handleOpenFiltri}
        />

        {menuOptions && menuOptions.length > 0 && (
          <Flex paddingRight="1rem" paddingTop=".2rem">
            <DropdownMenu menu={menuOptions} />
          </Flex>
        )}
      </Flex>

      <AdvancedConfrimModal
        show={show}
        setShow={(show: boolean) => setShow(false)}
        title={'Elimina riga'}
        onConfirm={handelDeleteMulti}
      >
        <Flex justifyContent="center" marginBottom=".5rem">
          Confermi di voler eliminare gli elementi selezionati?
        </Flex>
      </AdvancedConfrimModal>
    </Flex>
  );
};
