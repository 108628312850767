import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setAffiliatiSelectedIds } from '.';
import { apiClient, handleError, RootState, setError, URL_AFFILIATI, URL_ITEM } from '../../..';
import { URL_ANAGRAFICA, URL_DELETE_MULTI } from '../../..';
import { AffiliatiPadreIdRequest } from '../types';

export const deleteAffiliatiMulti = createAsyncThunk(
  'affiliati/deleteMulti',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const parameters = state?.affiliati?.selectedIds ?? [];
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_AFFILIATI}${URL_DELETE_MULTI}`,
        params: { children_ids: parameters },
      });
      if (response.status === 200) {
        const deleted = response.data.data.deleted;
        if (deleted) {
          deleted.forEach((id: number) => {
            if (state?.affiliati?.selectedIds.includes(id)) {
              thunkAPI.dispatch(setAffiliatiSelectedIds(id));
            }
          });
        }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
