/* eslint-disable react/jsx-no-useless-fragment */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorLock, getErrorStatus, resetError } from 'store';
import { SCHEDA } from 'utils';
interface LockAlertProps {
  element?: Record<string, any>;
}

export const LockAlert = ({ element }: LockAlertProps) => {
  const errorStatus = useSelector(getErrorStatus);
  const errorLock = useSelector(getErrorLock);
  const { module, module_id, user, email, date_lock } = errorLock ?? {};

  const [open, setOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const dispatch = useDispatch();

  const getDesc = () => {
    if (element && module) {
      const map: Record<string, () => string> = {
        [SCHEDA]: () => {
          return `Scheda ${element.nome} ${element.mese}/${element.anno}`;
        },
      };
      return map[module]();
    }

    return 'Record';
  };

  const closeAlert = () => {
    setOpen(false);
    dispatch(resetError());
  };

  React.useLayoutEffect(() => {
    if (errorStatus === 423 && !!errorLock) {
      setOpen(true);
    } else setOpen(false);
  }, [errorStatus]);

  return (
    <Dialog onClose={closeAlert} open={open}>
      <DialogTitle>Record già in modifica</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="list-group">
            <Typography variant="h5" align="center">
              {getDesc()} in uso dall'utente
              <Typography variant="h5" align="center">
                {user}
              </Typography>
              <Typography variant="h5" align="center">
                {email}
              </Typography>
            </Typography>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAlert}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LockAlert;
