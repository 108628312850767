import anagraficaSchema from 'components/Forms/Anagrafica/validation';
import contoSchema from 'components/Forms/Conto/schema';
import filtroSchema from 'components/Forms/Filtro/valdiation';
import moduloChiaviSchema from 'components/Forms/Modulo/chiaviValidation';
import moduloSchema from 'components/Forms/Modulo/valdiation';
import schedaSchema from 'components/Forms/Scheda/schedaSchema';
import utenteSchema from 'components/Forms/Utente/schema';
import {
  AFFILIATI_ID,
  ANAGRAFICA_ID,
  CONTO_ID,
  FATTURAZIONE_ID,
  FILTRO_ID,
  MODULO_ID,
  MODULO_RIGHE_ID,
  REFERENTE_ID,
  SCHEDA_ID,
  TAB_IVA_ID,
  TabsId,
  UTENTI_ID,
  VALIDAZIONE_SCHEDA_ID,
} from 'store';

export const MapValidationSchemas: Record<TabsId | string, any> = {
  [ANAGRAFICA_ID]: anagraficaSchema,
  [AFFILIATI_ID]: anagraficaSchema,
  [FILTRO_ID]: filtroSchema,
  [MODULO_ID]: moduloSchema,
  [MODULO_RIGHE_ID]: moduloChiaviSchema,
  [SCHEDA_ID]: schedaSchema,
  [VALIDAZIONE_SCHEDA_ID]: null,
  [FATTURAZIONE_ID]: null,
  [REFERENTE_ID]: null,
  [UTENTI_ID]: utenteSchema,
  [CONTO_ID]: contoSchema,
  [TAB_IVA_ID]: null,
};

export default MapValidationSchemas;
