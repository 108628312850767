import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchAffiliati,
  fetchAffiliatiOptions,
  resetAffiliatiParams,
  resetAffiliatiParamsOption,
  resetAffiliatiSelectedIds,
  resetAffiliati,
  resetAffiliatiOption,
  setAffiliatiParams,
  setAffiliatiParamsOption,
  setAffiliatiSelectedIds,
  selectAffiliatiAll,
  deleteAffiliatiMulti,
  updateSingleRecordAffiliati,
  deleteAffiliato,
} from '../actions';

export const AffiliatiMapAction: Record<ActionType | string, any> = {
  fetchList: fetchAffiliati,
  fetchListOption: fetchAffiliatiOptions,
  setParams: setAffiliatiParams,
  setParamsOption: setAffiliatiParamsOption,
  setSelectedIds: setAffiliatiSelectedIds,
  resetSelectedIds: resetAffiliatiSelectedIds,
  resetParams: resetAffiliatiParams,
  resetParamsOption: resetAffiliatiParamsOption,
  resetList: resetAffiliati,
  resetListOption: resetAffiliatiOption,
  setSelectAll: selectAffiliatiAll,
  deleteMulti: deleteAffiliatiMulti,
  updateSingleRecord: updateSingleRecordAffiliati,
};

export const AffiliatoMapAction: Record<ActionType | string, any> = {
  fetch: null,
  submit: null,
  delete: deleteAffiliato,
  reset: null,
};
