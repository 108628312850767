import { setError } from '../..';
import { ERRORS_FOR_ACTION, GENERIC_ERROR } from '../constants';

export function translateError(status: string | number) {
  return ERRORS_FOR_ACTION[status] || GENERIC_ERROR;
}

export function handleError(status: string | number, thunkAPI: any, error?: any) {
  try {
    // const statusResponse = error?.statusCode
    //   ? `${error.statusCode} - ${error.error?.type ?? 'SERVER_ERROR'}`
    //   : GENERIC_ERROR;
    const statusResponse = `${status}${error?.type ? ` - ${error?.type}` : ''}`;
    console.error('Error', { status, statusResponse, error });
    thunkAPI.dispatch(setError({ statusCode: statusResponse, error: error?.description }));

    return thunkAPI.rejectWithValue(translateError(status));
  } catch (error: any) {
    console.error(error);
  }
}

export function handleErrorLock(status: string | number, thunkAPI: any, lockData?: any) {
  try {
    // const statusResponse = error?.statusCode
    //   ? `${error.statusCode} - ${error.error?.type ?? 'SERVER_ERROR'}`
    //   : GENERIC_ERROR;
    const statusResponse = 423;
    console.error('Error', { lockData, statusResponse });
    thunkAPI.dispatch(setError({ statusCode: statusResponse, error: null, lock: lockData }));

    return thunkAPI.rejectWithValue(translateError(status));
  } catch (error: any) {
    console.error(error);
  }
}
