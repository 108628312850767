import { KeyValue } from 'store';
import dayjs from 'dayjs';
/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.toJSON = () => dayjs(this).format('YYYY-MM-DD HH:mm:ss');

export const YYYY_MM_DD = 'YYYY-MM-DD';
export const YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm';
export const YYYY_MM_DD_HH_MM_ss = 'YYYY-MM-DD HH:mm:ss';
export const YYYY_MM_DDTHH_MM = 'YYYY-MM-DDTHH:mm';
export const YYYY = 'YYYY';
export const DD_MM_YYYY = 'DD/MM/YYYY';
export const DD_MM_YYYY_HH_MM = 'DD/MM/YYYY HH:mm';
export const DD_MM_YYYY__HH_MM = 'YYYY-MM-DD\xa0\xa0HH:mm:ss';
export const DD_MM_YYYY_WITH_MINUS = 'DD-MM-YYYY';

export type DateForma =
  | typeof YYYY_MM_DD
  | typeof YYYY_MM_DD_HH_MM
  | typeof YYYY_MM_DD_HH_MM_ss
  | typeof YYYY_MM_DDTHH_MM
  | typeof YYYY
  | typeof DD_MM_YYYY
  | typeof DD_MM_YYYY_HH_MM
  | typeof DD_MM_YYYY__HH_MM
  | typeof DD_MM_YYYY_WITH_MINUS;

export const DATE_FORMAT = {
  [YYYY_MM_DD]: YYYY_MM_DD,
  [YYYY_MM_DD_HH_MM]: YYYY_MM_DD_HH_MM,
  [YYYY_MM_DD_HH_MM_ss]: YYYY_MM_DD_HH_MM_ss,
  [YYYY_MM_DDTHH_MM]: YYYY_MM_DDTHH_MM,
  [YYYY]: YYYY,
  [DD_MM_YYYY]: DD_MM_YYYY,
  [DD_MM_YYYY_HH_MM]: DD_MM_YYYY_HH_MM,
  [DD_MM_YYYY__HH_MM]: DD_MM_YYYY__HH_MM,
  [DD_MM_YYYY_WITH_MINUS]: DD_MM_YYYY_WITH_MINUS,
};

export const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

export const Months: KeyValue[] = [
  { id: 0, descrizione: '' },
  { id: 1, descrizione: 'Gennaio' },
  { id: 2, descrizione: 'Febbraio' },
  { id: 3, descrizione: 'Marzo' },
  { id: 4, descrizione: 'Aprile' },
  { id: 5, descrizione: 'Maggio' },
  { id: 6, descrizione: 'Giugno' },
  { id: 7, descrizione: 'Luglio' },
  { id: 8, descrizione: 'Agosto' },
  { id: 9, descrizione: 'Settembre' },
  { id: 10, descrizione: 'Ottobre' },
  { id: 11, descrizione: 'Novembre' },
  { id: 12, descrizione: 'Dicembre' },
];

export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  const isValid = !isNaN(date.getTime());
  return isValid;
};

export function getLastDayOfNextMonth(month: number, year: number): Date {
  // Assicuriamoci che il mese sia nel range 1-12
  if (month < 1 || month > 12) {
    throw new Error('Il mese deve essere compreso tra 1 e 12');
  }

  // Calcoliamo il mese successivo e l'anno
  let nextMonth = month + 1;
  let nextYear = year;

  // Se il mese è dicembre (12), passiamo a gennaio dell'anno successivo
  if (nextMonth > 12) {
    nextMonth = 1;
    nextYear++;
  }

  // Creiamo una data con il primo giorno del mese successivo e impostiamo il giorno a 0
  // per ottenere l'ultimo giorno del mese corrente
  const lastDay = new Date(Date.UTC(nextYear, nextMonth, 0));

  return lastDay;
}

export const formatDate = (
  date: Date | string,
  format: string = DATE_FORMAT[DD_MM_YYYY]
): string => {
  return dayjs(date).format(format);
};

export const parseDate = (dateString: string): Date => {
  return dayjs(dateString).toDate();
};
