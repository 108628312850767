import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../..';

export const getAziendaState = (state: RootState) => state.azienda;

export const getAziendaLoader = createSelector(
  getAziendaState,
  (aziendaState) => aziendaState.loading === PENDING
);

export const getAziendaFailed = createSelector(
  getAziendaState,
  (aziendaState) => aziendaState.loading === FAILED
);

export const getAziendaSuccess = createSelector(
  getAziendaState,
  (aziendaState) => aziendaState.loading === SUCCEEDED
);

export const getAziendaError = createSelector(
  getAziendaState,
  (aziendaState) => aziendaState?.error
);

export const getAzienda = createSelector(getAziendaState, (aziendaState) => aziendaState?.data);

export const getAziendaLogo = createSelector(getAzienda, (azienda) =>
  azienda ? `${azienda.path_logo}/${azienda.logo}` : ''
);
