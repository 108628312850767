import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { apiClient, handleError } from '../../utils';
import { URL_REFRESH, URL_USER } from '../../utils/constants';

export const getRefreshToken = createAsyncThunk(
  'auth/refreshToken',
  async ({ refreshToken }: { refreshToken: string }, thunkAPI) => {
    try {
      const authResponse = await apiClient.post<AxiosResponse>({
        url: `${URL_USER}${URL_REFRESH}`,
        params: { refreshToken },
      });
      if (authResponse.status === 200) {
        Cookies.set('token', authResponse.data.token);
        Cookies.set('refreshToken', authResponse.data.refresh_token);
        return { token: '', refresh_token: '', error: 'invalidToken' };
      }

      return authResponse.data;
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
