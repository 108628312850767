import { useCallback, useMemo, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Container,
  CircularProgress,
} from '@mui/material';
import { Box, Flex } from 'components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { StepProps } from 'utils';

interface Props {
  steps: StepProps[];
  buttonStepLabel?: string[];
  completedComponent?: React.ReactNode;
}

function Wizard({ steps, buttonStepLabel = [], completedComponent }: Props) {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    setActiveStep((prevActiveStep) => {
      steps[prevActiveStep] &&
        steps[prevActiveStep].actionNext &&
        steps[prevActiveStep]?.actionNext();
      setLoading(false);
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getButtonText = useMemo(() => {
    if (buttonStepLabel && buttonStepLabel.length > 0) {
      return buttonStepLabel[activeStep];
    }
    return <div></div>;
  }, [activeStep, buttonStepLabel]);

  const getCompletedComponent = useMemo(() => {
    if (completedComponent) return completedComponent;
    else
      return (
        <Typography variant="h5" align="center" gutterBottom>
          Tutti i passaggi sono completati
        </Typography>
      );
  }, [activeStep]);

  return (
    <Flex marginTop="1rem" flexDirection="column" paddingInline="2rem">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box marginTop="2rem" marginBottom="2rem">
        {activeStep === steps.length ? (
          getCompletedComponent
        ) : (
          <Box>
            {steps[activeStep].component}
            <Flex justifyContent="space-between" marginTop="1rem">
              <Button
                variant="contained"
                color="primary"
                onClick={handleBack}
                disabled={activeStep === 0 || loading}
                startIcon={<ArrowBackIcon />}
              >
                Indietro
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={loading}
                endIcon={activeStep === steps.length - 1 ? <CheckIcon /> : <ArrowForwardIcon />}
              >
                {loading ? <CircularProgress size={24} /> : getButtonText}
              </Button>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
}

export default Wizard;
