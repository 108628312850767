import { ChangeEvent, useContext, useState } from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Anagrafica, Roles } from 'store';
import { ModelFormProps } from '../model';
import { Link } from 'react-router-dom';

import { Context, FormProvider } from '../Context';
import { ANAGRAFICA, USER } from 'utils';
import { FormInput, SelectForm } from 'components';

import Icon from '@ailibs/feather-react-ts';

export const UtenteForm = ({ id, element }: ModelFormProps<Anagrafica>) => {
  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();

  const [show, setShow] = useState<boolean>(true);

  const { perms } = useContext(Context);
  return (
    <FormProvider module={USER}>
      <Row>
        <Col md={6}>
          <FormInput
            type="text"
            name="name"
            label="Nome"
            minHeight="6.25rem"
            register={register}
            errors={errors}
            disabled={perms && !perms.can_write}
          />
        </Col>
        <Col md={6}>
          <FormInput
            type="text"
            name="lastname"
            label="Cognome"
            minHeight="6.25rem"
            register={register}
            errors={errors}
            disabled={perms && !perms.can_write}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormInput
            type="email"
            name="email"
            label="E-mail"
            minHeight="6.25rem"
            register={register}
            errors={errors}
            disabled={perms && !perms.can_write}
          />
        </Col>
        <Col md={6}>
          <FormInput
            type={show ? 'text' : 'password'}
            name="pwd"
            label="Password"
            onBlur={(event: any) => {
              const value = event.target.value;
              setValue('pwd', value);
            }}
            startIcon={
              <Icon
                name={show ? 'eye' : 'eye-off'}
                className="icon-dual"
                onClick={() => setShow(!show)}
              />
            }
            placeholder="Inserisci la password"
            containerClass={'mb-3'}
            errors={errors}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormInput
            type="select"
            name="role"
            label="Ruolo utente"
            minHeight="6.25rem"
            register={register}
            errors={errors}
            disabled={perms && !perms.can_write}
            options={Roles}
          />
        </Col>
        <Col md={6} style={{ marginTop: '2.4rem' }}>
          <FormInput
            type="switch"
            name="enable"
            label="Utenza abilitata"
            minHeight="6.25rem"
            register={register}
            errors={errors}
            disabled={perms && !perms.can_write}
          />
        </Col>
      </Row>
    </FormProvider>
  );
};

export default UtenteForm;
