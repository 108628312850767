import { SchedaSaveParams } from '../types';
import {
  apiClient,
  handleError,
  URL_SCHEDA,
  URL_RIGA_ANAGRAFICA,
  URL_RELOAD,
} from '../../../index';
import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const reloadAnagraficaRigaScheda = createAsyncThunk(
  'scheda/reloadAnagraficaRiga',
  async (params: SchedaSaveParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_SCHEDA}${URL_RIGA_ANAGRAFICA}${URL_RELOAD}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
