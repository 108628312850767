import React, { useState, forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState(new Date(props.value));

  useImperativeHandle(ref, () => ({
    getValue: () => value.toISOString().split('T')[0], // Restituisce la data nel formato 'YYYY-MM-DD'
  }));

  return (
    <DatePicker
      selected={value}
      onChange={(date) => setValue(date as Date)}
      dateFormat="yyyy-MM-dd"
    />
  );
});

export default DateEditor;
