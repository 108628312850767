import { createSlice } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import {
  FAILED,
  IDLE,
  PENDING,
  resetAllAnagrafica,
  resetAllOptionAnagrafica,
  State,
  SUCCEEDED,
} from '../../../../../store';
import { merge } from '../../../../../utils';
import { Referente } from '../../referente/types/model';
import {
  fetchReferentiOptions,
  fetchReferenti,
  setReferenteParams,
  resetReferenteParams,
  setReferenteParamsOption,
  resetReferenteParamsOption,
  setReferenteSelectedIds,
  resetReferenteSelectedIds,
  deleteReferentiMulti,
  updateSingleRecordReferenti,
} from '../actions';

import { resetReferenti } from '../actions/reset';
import { resetReferentiOption } from '../actions/resetOption';
import { selectReferenteAll } from '../actions/selectAll';

const initialState: State<Referente> = {
  data: [],
  info: undefined,
  dataOption: [],
  infoOption: undefined,
  selectedIds: [],
  params: undefined,
  paramsOption: undefined,
  categorie: [],
  tipologie: [],
  error: null,
};

export const referentiSlice = createSlice({
  name: 'referenti',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferenti.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchReferenti.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchReferenti.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(selectReferenteAll.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedIds = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(selectReferenteAll.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(selectReferenteAll.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(fetchReferentiOptions.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.dataOption = data;
        state.loadingOption = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchReferentiOptions.pending, (state) => {
        state.loadingOption = PENDING;
      })
      .addCase(fetchReferentiOptions.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })
      .addCase(resetReferenti, (state) => {
        state.data = [];
        state.info = undefined;
        state.loading = IDLE;
        state.params = undefined;
        state.selectedIds = [];
      })
      .addCase(resetReferentiOption, (state) => {
        state.dataOption = [];
        state.infoOption = undefined;
        state.loadingOption = IDLE;
        state.paramsOption = undefined;
      })
      .addCase(setReferenteParams, (state, { payload }) => {
        state.params = { ...state.params, ...payload };
      })
      .addCase(resetReferenteParams, (state) => {
        state.params = undefined;
      })
      .addCase(setReferenteParamsOption, (state, { payload }) => {
        state.paramsOption = { ...state.paramsOption, ...payload };
      })
      .addCase(resetReferenteParamsOption, (state) => {
        state.paramsOption = undefined;
      })
      .addCase(setReferenteSelectedIds, (state, { payload }) => {
        const ids = state.selectedIds;
        state.selectedIds = ids.includes(payload)
          ? ids.filter((currentId: string | number) => currentId !== payload)
          : [payload, ...state.selectedIds];
      })
      .addCase(resetReferenteSelectedIds, (state) => {
        state.selectedIds = [];
      })
      .addCase(updateSingleRecordReferenti, (state, { payload }) => {
        if (state.data) {
          state.data = state.data?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
        if (state.dataOption) {
          state.dataOption = state.dataOption?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
      })
      .addCase(resetAllAnagrafica, (state) => {
        state.data = [];
        state.info = undefined;
        state.dataOption = [];
        state.infoOption = undefined;
        state.selectedIds = [];
        state.params = undefined;
        state.paramsOption = undefined;
        state.categorie = [];
        state.tipologie = [];
        state.error = null;
      });
  },
});
