import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown, ButtonGroup, Card, Button } from 'react-bootstrap';
import Icon from '@ailibs/feather-react-ts';
import { Dialog, Form, Grid, ImportazioneForm } from '../../components';
import {
  dispatchAction,
  FETCH,
  Anagrafica,
  getSelectorElement,
  getSelectorElementById,
  getSelectorListData,
  RESET,
  useAppDispatch,
  getAction,
  FILTRO_ID,
  MODULO_ID,
  getPermsByModule,
  setPage,
} from '../../store';
import { Link } from 'react-router-dom';
import Tasks from 'components/Tasks';
import {
  ANAGRAFICA,
  MAP_TAB_ID_MODULE_PAGE,
  MAP_TAB_ID_TITLE_PAGE,
  MODULI,
  MODULO,
} from 'utils/constants';
import { resetImportazione } from 'store/importazioni/importazione';
import { useDisclosure } from 'hooks';
import { useSelector } from 'react-redux';

// dummy data

const Moduli = () => {
  const dispatch = useAppDispatch();

  const perms = useSelector(getPermsByModule(MODULO));

  const [dateRange, setDateRange] = useState<any>([
    new Date(),
    new Date().setDate(new Date().getDate() + 7),
  ]);
  const [startDate, endDate] = dateRange;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };

  const reset = () => {
    const actionReset = getAction(MODULO_ID, RESET);
    actionReset && dispatch(actionReset);
    setSelectedId(0);
  };

  const showDialogImport = (show: boolean) => {
    setShowImport(show);
    !show && dispatch(resetImportazione());
  };

  const handleOpenImport = () => {
    setShowImport(true);
  };

  const handleOpenNew = () => {
    reset();
    setIsOpen(true);
  };

  const onClose = () => {
    reset();
    setIsOpen(false);
  };

  /*
   * handle date change
   */
  const onDateChange = (date: Date) => {
    if (date) {
      setDateRange(date);
    }
  };

  useEffect(() => {
    if (selectedId) {
      const actionFetch = getAction(MODULO_ID, FETCH);
      actionFetch && dispatch(actionFetch({ id: selectedId }));
    }
  }, [selectedId]);

  useEffect(() => {
    const module = MAP_TAB_ID_MODULE_PAGE[MODULO_ID];
    const pageInfo = {
      tabId: MODULO_ID,
      module,
      url: MODULI,
      title: MAP_TAB_ID_TITLE_PAGE[MODULO_ID],
    };
    dispatch(setPage(pageInfo));
  }, []);

  return (
    <Row>
      <Col style={{ paddingTop: '1rem' }}>
        <Grid
          tabId={MODULO_ID}
          module={MODULO}
          pagination
          title=""
          selectedId={selectedId}
          selectRow={setSelectedId}
          open={setIsOpen}
          handleOpenNew={handleOpenNew}
          hasImport={false}
          hasExport={false}
        />
      </Col>
      <Form
        id={selectedId}
        isOpen={isOpen}
        tabId={MODULO_ID}
        handleClose={onClose}
        module={MODULO}
        hasSave
        perms={perms}
      />
    </Row>
  );
};

export default Moduli;
