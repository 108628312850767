import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState, URL_AFFILIATI, URL_ITEM } from '../../..';
import { URL_ANAGRAFICA, URL_LIST } from '../../..';
import { AffiliatiFetchRequest, AffiliatiPadreIdRequest } from '../types';

export const fetchAffiliatiOptions = createAsyncThunk(
  'affiliati/fetchOptions',
  async (_, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const parameters = state?.affiliati?.paramsOption ?? {};
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_ITEM}/${parameters.parentId}${URL_AFFILIATI}${URL_LIST}`,
        params: parameters,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
