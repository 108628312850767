import { createSlice } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { FAILED, IDLE, Loading, PENDING, State, SUCCEEDED } from '../../../../store';
import { merge } from '../../../../utils';
import { Fatturazione } from '../../fatturazione/types/model';
import {
  fetchFatturazioniOptions,
  fetchFatturazioni,
  setFatturazioneParams,
  resetFatturazioneParams,
  setFatturazioneParamsOption,
  resetFatturazioneParamsOption,
  setFatturazioneSelectedIds,
  resetFatturazioneSelectedIds,
  updateSingleRecordFatturazioni,
  fetchSchedeFatturazione,
  resetSchedeFatturazione,
  sendFatturazioni,
} from '../actions';

import { resetFatturazioni } from '../actions/reset';
import { resetFatturazioniOption } from '../actions/resetOption';
import { selectFatturazioneAll } from '../actions/selectAll';
import { SchedaFatturazione } from '../types/response';

const initialState: State<Fatturazione> & {
  schede: SchedaFatturazione[];
  loadingSchede?: Loading;
} = {
  data: [],
  info: undefined,
  dataOption: [],
  infoOption: undefined,
  selectedIds: [],
  params: undefined,
  paramsOption: undefined,
  categorie: [],
  tipologie: [],
  error: null,
  schede: [],
};

export const fatturazioniSlice = createSlice({
  name: 'fatturazioni',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFatturazioni.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data?.list;
        state.info = data?.info;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchFatturazioni.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchFatturazioni.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loading = FAILED;
      })

      .addCase(sendFatturazioni.fulfilled, (state, { payload }) => {
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(sendFatturazioni.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(sendFatturazioni.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loading = FAILED;
      })

      .addCase(fetchSchedeFatturazione.fulfilled, (state, { payload }) => {
        const schede = payload?.data;
        state.schede = schede;
        state.loadingSchede = SUCCEEDED;
      })
      .addCase(fetchSchedeFatturazione.pending, (state) => {
        state.loadingSchede = PENDING;
      })
      .addCase(fetchSchedeFatturazione.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingSchede = FAILED;
      })

      .addCase(selectFatturazioneAll.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedIds = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(selectFatturazioneAll.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(selectFatturazioneAll.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loading = FAILED;
      })

      .addCase(fetchFatturazioniOptions.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.dataOption = data?.list;
        state.infoOption = data?.info;
        state.loadingOption = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchFatturazioniOptions.pending, (state) => {
        state.loadingOption = PENDING;
      })
      .addCase(fetchFatturazioniOptions.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })
      .addCase(resetSchedeFatturazione, (state) => {
        state.schede = [];
        state.loadingSchede = IDLE;
      })
      .addCase(resetFatturazioni, (state) => {
        state.data = [];
        state.info = undefined;
        state.loading = IDLE;
        state.params = undefined;
        state.selectedIds = [];
        state.schede = [];
        state.loadingSchede = IDLE;
      })
      .addCase(resetFatturazioniOption, (state) => {
        state.dataOption = [];
        state.infoOption = undefined;
        state.loadingOption = IDLE;
        state.paramsOption = undefined;
      })
      .addCase(setFatturazioneParams, (state, { payload }) => {
        state.params = { ...state.params, ...payload };
      })
      .addCase(resetFatturazioneParams, (state) => {
        state.params = undefined;
      })
      .addCase(setFatturazioneParamsOption, (state, { payload }) => {
        state.paramsOption = { ...state.paramsOption, ...payload };
      })
      .addCase(resetFatturazioneParamsOption, (state) => {
        state.paramsOption = undefined;
      })
      .addCase(setFatturazioneSelectedIds, (state, { payload }) => {
        const ids = state.selectedIds;
        state.selectedIds = ids.includes(payload)
          ? ids.filter((currentId: string | number) => currentId !== payload)
          : [payload, ...state.selectedIds];
      })
      .addCase(resetFatturazioneSelectedIds, (state) => {
        state.selectedIds = [];
      })
      .addCase(updateSingleRecordFatturazioni, (state, { payload }) => {
        if (state.data) {
          state.data = state.data?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
        if (state.dataOption) {
          state.dataOption = state.dataOption?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
      });
  },
});
