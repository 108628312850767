import React from 'react';

type Props = {
  formId: number | null;
  element?: Record<string, any>;
  isDisabled: boolean;
  parentId?: number | null;
  isParentFormDisabled?: boolean;
  closeForm?: () => void;
  handleSaveCurrentTab?: () => Promise<any>;
};

const values: Props = {
  formId: null,
  isDisabled: false,
  parentId: null,
};

export const FormContext = React.createContext(values);

export default FormContext;
