import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReferenteFetchParams } from '../types';
import { handleError, URL_REFERENTE, URL_GET, URL_V1 } from '../../../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../../../utils';

export const fetchReferente = createAsyncThunk(
  'referente/fetch',
  async ({ id, ...params }: ReferenteFetchParams, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_REFERENTE}${URL_GET}/${id}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
