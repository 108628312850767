import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchTabelleIva,
  fetchTabelleIvaOptions,
  resetTabelleIvaParams,
  resetTabelleIvaParamsOption,
  resetTabelleIvaSelectedIds,
  resetTabelleIva,
  resetTabelleIvaOption,
  setTabelleIvaParams,
  setTabelleIvaParamsOption,
  setTabelleIvaSelectedIds,
  selectTabelleIvaAll,
  updateSingleRecordTabelleIva,
} from '../actions';

export const TabelleIvaMapAction: Record<ActionType | string, any> = {
  fetchList: fetchTabelleIva,
  fetchListOption: fetchTabelleIvaOptions,
  setParams: setTabelleIvaParams,
  setParamsOption: setTabelleIvaParamsOption,
  setSelectedIds: setTabelleIvaSelectedIds,
  resetSelectedIds: resetTabelleIvaSelectedIds,
  resetParams: resetTabelleIvaParams,
  resetParamsOption: resetTabelleIvaParamsOption,
  resetList: resetTabelleIva,
  resetListOption: resetTabelleIvaOption,
  setSelectAll: selectTabelleIvaAll,
  deleteMulti: null,
  updateSingleRecord: updateSingleRecordTabelleIva,
};
