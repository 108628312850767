import { useDispatch } from 'react-redux';
import { ActionType, getActionType, TabsId } from 'store';
import {
  AnagraficaMapAction,
  AnagraficheMapAction,
  ReferenteMapAction,
  ReferentiMapAction,
} from '../anagrafiche';
import { LoginActions } from '../auth/types/mapAction';
import { FiltroMapAction, FiltriMapAction } from 'store/filtri';
import { SchedaMapAction, SchedeMapAction } from '../schede';
import {
  ModuliMapAction,
  ModuloMapAction,
  ModuloChiaveMapAction,
  ModuloChiaviMapAction,
} from 'store/moduli';
import { FatturazioneMapAction, FatturazioniMapAction } from 'store/fatturazioni';
import { UtenteMapAction, UtentiMapAction } from 'store/utenti';
import { ContoMapAction } from 'store/conto';
import { TabelleIvaMapAction } from 'store/tabIva';
import { AffiliatiMapAction, AffiliatoMapAction } from 'store/anagrafiche/affiliati';

export const mapAction: Record<TabsId, Record<ActionType | string, any>> = {
  LOGIN: { ...LoginActions },
  // [REGISTER_ID]: {
  //   submit: registerUser,
  // },
  // [CONFIRM_ID]: {
  //   submit: confirmUser,
  // },
  ANAGRAFICA: { ...AnagraficheMapAction, ...AnagraficaMapAction },
  AFFILIATI: { ...AffiliatiMapAction, ...AffiliatoMapAction },
  FILTRO: { ...FiltroMapAction, ...FiltriMapAction },
  MODULO: { ...ModuloMapAction, ...ModuliMapAction },
  MODULO_RIGHE: { ...ModuloChiaveMapAction, ...ModuloChiaviMapAction },
  SCHEDA: { ...SchedaMapAction, ...SchedeMapAction },
  REFERENTE: { ...ReferenteMapAction, ...ReferentiMapAction },
  FATTURAZIONE: { ...FatturazioneMapAction, ...FatturazioniMapAction },
  UTENTI: { ...UtenteMapAction, ...UtentiMapAction },
  CONTO: { ...ContoMapAction },
  TAB_IVA: { ...TabelleIvaMapAction },
};

export const getAction = (tabId: TabsId, actionType: ActionType, options = false) => {
  const typeAction = getActionType(actionType, options);
  const item = mapAction[tabId];
  if (!item) {
    return null;
  }
  const action = item[typeAction];
  return action;
};

export function dispatchAction(
  tabId: TabsId,
  actionType: ActionType,
  params: { [key: string]: any } | Record<string, any> | undefined = undefined,
  options: boolean = false
) {
  const dispatch = useDispatch();
  const action = getAction(tabId, actionType, options);
  return action && dispatch(action(params));
}
