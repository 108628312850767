import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Anagrafica } from 'store';
import { ModelFormProps } from '../model';

import { FormProvider } from '../Context';
import { CONTO } from 'utils';
import { FormInput } from 'components';

export const ContoForm = ({ id, element }: ModelFormProps<Anagrafica>) => {
  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();

  const [show, setShow] = useState<boolean>(true);

  return (
    <FormProvider module={CONTO}>
      <Row>
        <Col>
          <FormInput
            type="text"
            name="nome_banca"
            label="Banca"
            minHeight="6.25rem"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            type="text"
            name="iban"
            label="IBAN"
            minHeight="6.25rem"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
    </FormProvider>
  );
};

export default ContoForm;
