import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  Anagrafica,
  Fatturazione,
  fetchRigheFatturazione,
  getPermsByModule,
  useAppDispatch,
} from 'store';
import { ModelFormProps } from '../model';

import { FormProvider } from '../Context';
import { CONTO, FATTURAZIONE } from 'utils';
import { Box, CheckInvioSDI, CurrencyFormatter, Flex, FormInput, GenericGrid } from 'components';
import { Grid, Typography } from '@mui/material';
import FormContext from 'components/Form/FormContext';
import React from 'react';
import { GridColumn } from 'components/GenericGrid';
import { useSelector } from 'react-redux';
import NotaCredito from './notaCredito';

export const FatturazioneForm = ({ id }: ModelFormProps<Anagrafica>) => {
  const { element } = React.useContext(FormContext);

  const [inviata, setInviata] = useState<boolean>(element?.inviato_sdi ?? false);
  const [rows, setRows] = useState<Record<string, any>[]>([]);
  const [columns, setColumns] = useState<GridColumn[]>([]);

  const dispatch = useAppDispatch();
  const perms = useSelector(getPermsByModule(FATTURAZIONE));

  const getRows = async (id: number) => {
    const request = { id };
    setColumns([
      {
        id: 'id',
        label: 'ID',
      },
      {
        id: 'causale',
        label: 'Descrizione',
      },
      {
        id: 'quantita',
        label: 'QT',
      },
      {
        id: 'iva',
        label: 'Aiquota IVA',
      },
      {
        id: 'totale_netto',
        label: 'Importo netto',
      },
      {
        id: 'totale_iva',
        label: 'Importa IVA',
      },
      {
        id: 'totale_ivato',
        label: 'Importo totale',
      },
    ]);
    const response = await dispatch(fetchRigheFatturazione(request));
    if (response && !response.error) {
      const data = response.payload.data;
      if (data && data.length > 0) {
        setRows(
          data.map((row: any) => {
            return {
              id: row.id,
              causale: row.causale,
              quantita: Number(row.quantita ?? 1),
              iva: row?.iva?.descrizione ?? '22%',
              totale_netto: <CurrencyFormatter amount={row?.totale_netto} currency={'EUR'} />,
              totale_iva: <CurrencyFormatter amount={row?.totale_iva} currency={'EUR'} />,
              totale_ivato: <CurrencyFormatter amount={row?.totale_ivato} currency={'EUR'} />,
            };
          })
        );
      }
    }
  };

  useEffect(() => {
    if (element?.id) {
      getRows(element.id);
    }
  }, [element?.id]);

  return (
    <React.Fragment>
      <Flex justifyContent={'space-between'} width="100vw" paddingInline="1rem" paddingTop="1rem">
        <Typography noWrap variant="h5">
          Dati fattura
        </Typography>

        <Flex justifyContent="space-between">
          <Flex>
            <NotaCredito />
            <span>
              <b>{inviata ? 'inviato allo SDI' : 'NON inviato allo SDI'}</b>
              <CheckInvioSDI
                row={element as Fatturazione}
                value={inviata}
                onChangeCallback={() => setInviata(!inviata)}
              />
            </span>
          </Flex>
        </Flex>
      </Flex>

      <Col md={12}>
        <Card className="mb-3" style={{ border: 'unset', marginTop: '1rem' }}>
          <Card.Body>
            <Row>
              <Col md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {element?.ragione_sociale}
                  </Grid>
                  <Grid item xs={12}>
                    {element?.indirizzo}
                  </Grid>
                  <Grid item xs={12}>
                    {element?.cap} {element?.localita} ({element?.provincia})
                  </Grid>
                  <Grid item xs={12}>
                    {element?.nazione ?? 'Italia'}
                  </Grid>
                </Grid>
              </Col>
              <Col md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    Partita IVA: {element?.piva}
                  </Grid>
                  <Grid item xs={12}>
                    Codice fiscale: {element?.codice_fiscale}
                  </Grid>
                  <Grid item xs={12}>
                    Codice destinatario: {element?.codice_destinatario ?? '-'}
                  </Grid>
                  <Grid item xs={12}>
                    PEC destinatario: {element?.pec ?? '-'}
                  </Grid>
                </Grid>
              </Col>
              <Col md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <b>
                      Documento n.{element?.f24_doc_number}{' '}
                      {element?.data ? `del ${element.data}` : ''}
                    </b>
                  </Grid>
                  <Grid item xs={12}>
                    Totale netto:{' '}
                    <b>
                      <CurrencyFormatter amount={element?.totale_netto} currency={'EUR'} />
                    </b>
                  </Grid>
                  <Grid item xs={12}>
                    Totale documento:{' '}
                    <b>
                      <CurrencyFormatter amount={element?.totale_ivato} currency={'EUR'} />
                    </b>
                  </Grid>
                  <Grid item xs={12}>
                    Totale iva:{' '}
                    <b>
                      <CurrencyFormatter amount={element?.totale_iva} currency={'EUR'} />
                    </b>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Row>
        <GenericGrid data={rows} columns={columns} />
      </Row>
    </React.Fragment>
  );
};

export default FatturazioneForm;
