import {
  getFatturazioni,
  getFatturazioniFailed,
  getFatturazioniInfo,
  getFatturazioniLoader,
  getFatturazioniOption,
  getFatturazioniOptionFailed,
  getFatturazioniOptionInfo,
  getFatturazioniOptionLoader,
  getFatturazioniOptionSuccess,
  getFatturazioniParams,
  getFatturazioniParamsOption,
  getFatturazioniSelectedIds,
  getFatturazioniSuccess,
  getFatturazioneById,
  getFatturazioneOptionById,
  getFatturazioniKeyValues,
  getFatturazioniOptionKeyValues,
} from '../selectors';

export const FatturazioniMapSelector = {
  loading: getFatturazioniLoader,
  success: getFatturazioniSuccess,
  failed: getFatturazioniFailed,
  data: getFatturazioni,
  info: getFatturazioniInfo,
  params: getFatturazioniParams,
  loadingOption: getFatturazioniOptionLoader,
  successOption: getFatturazioniOptionSuccess,
  failedOption: getFatturazioniOptionFailed,
  dataOption: getFatturazioniOption,
  infoOption: getFatturazioniOptionInfo,
  paramsOption: getFatturazioniParamsOption,
  selectedIds: getFatturazioniSelectedIds,
  selectKeyValues: getFatturazioniKeyValues,
  selectOptionKeyValues: getFatturazioniOptionKeyValues,
  elementById: getFatturazioneById,
  elementOptionById: getFatturazioneOptionById,
};

export default FatturazioniMapSelector;
