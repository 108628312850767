import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, RootState, URL_SELECT_ALL } from '../../..';
import { URL_MODULO, URL_LIST } from '../../..';

export const selectModuloAll = createAsyncThunk(
  'moduli/selectAll',
  async (options: boolean | undefined, thunkAPI): Promise<any> => {
    const state = thunkAPI?.getState() as RootState;
    const params = state?.moduli?.[options ? 'paramsOption' : 'params'] ?? {};
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_MODULO}${URL_SELECT_ALL}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
