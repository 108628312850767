import { TEXT, NUMBER } from 'components/Grid/Filters/model';
import { ColumnCustomHeader } from './model';
import { ColumnData } from 'utils/types';

const columns: ColumnCustomHeader[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: '5rem',
  },
  {
    Header: 'Descrizione',
    accessor: 'descrizione',
    sort: true,
    filterType: TEXT,
    width: '40rem',
  },
  {
    Header: 'Descrizione fattura',
    accessor: 'descrizione_fattura',
    sort: true,
    filterType: TEXT,
    width: '35rem',
  },
  {
    Header: 'Aliquota',
    accessor: 'aliquota',
    sort: true,
    filterType: NUMBER,
    width: '10rem',
  },
  {
    Header: 'Natura',
    accessor: 'natura',
    sort: true,
    filterType: TEXT,
    width: '20rem',
  },
];

export const tabIva = {
  preview: columns,
  complete: columns,
};

export const convertAccessorTabIvaToColumnData = <T>(): ColumnData<T>[] =>
  columns.map((column: any) => ({
    id: column.accessor,
    label: column.Header,
    align: ['aliquota'].includes(column.accessor) ? 'right' : 'left',
    format: (value: any) =>
      ['string', 'number'].includes(typeof value) ? value : value.descrizione,
  }));
