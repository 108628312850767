import { createSlice } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import {
  Anagrafica,
  FAILED,
  IDLE,
  PENDING,
  resetAllAnagrafica,
  resetAllOptionAnagrafica,
  State,
  SUCCEEDED,
} from '../../../../store';
import { merge } from '../../../../utils';
import {
  fetchAffiliatiOptions,
  fetchAffiliati,
  setAffiliatiParams,
  resetAffiliatiParams,
  setAffiliatiParamsOption,
  resetAffiliatiParamsOption,
  setAffiliatiSelectedIds,
  resetAffiliatiSelectedIds,
  deleteAffiliatiMulti,
  updateSingleRecordAffiliati,
} from '../actions';

import { resetAffiliati } from '../actions/reset';
import { resetAffiliatiOption } from '../actions/resetOption';
import { selectAffiliatiAll } from '../actions/selectAll';

const initialState: State<Anagrafica> = {
  data: [],
  info: undefined,
  dataOption: [],
  infoOption: undefined,
  selectedIds: [],
  params: undefined,
  paramsOption: undefined,
  categorie: [],
  tipologie: [],
  error: null,
};

export const affiliatiSlice = createSlice({
  name: 'affiliati',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAffiliati.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.data = data?.list;
        state.info = data?.info;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchAffiliati.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(fetchAffiliati.rejected, (state, action) => {
        // const statusCode = payload?.statusCode ?? 500;
        // const error = payload?.error;
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(selectAffiliatiAll.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.selectedIds = data;
        state.loading = SUCCEEDED;
        state.error = null;
      })
      .addCase(selectAffiliatiAll.pending, (state) => {
        state.loading = PENDING;
      })
      .addCase(selectAffiliatiAll.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(fetchAffiliatiOptions.fulfilled, (state, { payload }) => {
        const data = payload?.data;
        state.dataOption = data?.list;
        state.infoOption = data?.info;
        state.loadingOption = SUCCEEDED;
        state.error = null;
      })
      .addCase(fetchAffiliatiOptions.pending, (state) => {
        state.loadingOption = PENDING;
      })
      .addCase(fetchAffiliatiOptions.rejected, (state, action) => {
        const payload = action?.error;
        const statusCode = payload?.code ?? payload?.name;
        state.error = payload?.message ?? payload?.name ?? statusCode;
        state.loadingOption = FAILED;
      })

      .addCase(resetAffiliati, (state) => {
        state.data = [];
        state.info = undefined;
        state.loading = IDLE;
        state.params = undefined;
        state.selectedIds = [];
      })
      .addCase(resetAffiliatiOption, (state) => {
        state.dataOption = [];
        state.infoOption = undefined;
        state.loadingOption = IDLE;
        state.paramsOption = undefined;
      })
      .addCase(setAffiliatiParams, (state, { payload }) => {
        state.params = { ...state.params, ...payload };
      })
      .addCase(resetAffiliatiParams, (state) => {
        state.params = undefined;
      })
      .addCase(setAffiliatiParamsOption, (state, { payload }) => {
        state.paramsOption = { ...state.paramsOption, ...payload };
      })
      .addCase(resetAffiliatiParamsOption, (state) => {
        state.paramsOption = undefined;
      })
      .addCase(setAffiliatiSelectedIds, (state, { payload }) => {
        const ids = state.selectedIds;
        state.selectedIds = ids.includes(payload)
          ? ids.filter((currentId: string | number) => currentId !== payload)
          : [payload, ...state.selectedIds];
      })
      .addCase(resetAffiliatiSelectedIds, (state) => {
        state.selectedIds = [];
      })
      .addCase(updateSingleRecordAffiliati, (state, { payload }) => {
        if (state.data) {
          state.data = state.data?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
        if (state.dataOption) {
          state.dataOption = state.dataOption?.map((row) =>
            Number(row.id) === Number(payload.id) ? payload : row
          );
        }
      })
      .addCase(resetAllAnagrafica, (state) => {
        state.data = [];
        state.info = undefined;
        state.dataOption = [];
        state.infoOption = undefined;
        state.selectedIds = [];
        state.params = undefined;
        state.paramsOption = undefined;
        state.categorie = [];
        state.tipologie = [];
        state.error = null;
      });
  },
});
