import { FormInput, SelectForm } from 'components';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Context } from '../Context';
import { useContext } from 'react';

export const Note = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { perms } = useContext(Context);

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Note Cliente</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <FormInput
              type="switch"
              name="pagamento"
              label="Pagamento F24"
              minHeight="2.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormInput
              type="textarea"
              name="nota"
              label="Note"
              placeholder="Note"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              rows={5}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <FormInput
              type="textarea"
              name="note_cedolini"
              label="Note per elaborazione cedolini"
              placeholder="Note cedolini"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              rows={5}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <FormInput
              type="textarea"
              name="note_f24"
              label="Note per pagamento F24"
              placeholder="Note F24"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              rows={5}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Note;
