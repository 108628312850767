import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import {
  checkTokenValidity,
  fetchTabelleIva,
  fetchUser,
  getIsAuthenticated,
  getUserLoader,
  setTabelleIvaParams,
  useAppDispatch,
} from '../../store';
import { fetchConto } from 'store/conto';

const useInit = (): void => {
  const dispatch = useAppDispatch();

  const userLoader = useSelector(getUserLoader);

  const fetchData = async () => {
    const token = await checkTokenValidity();
    if (token !== null) {
      await dispatch(fetchUser());
      await dispatch(fetchConto());
      dispatch(setTabelleIvaParams({ limit: 0 }));
      await dispatch(fetchTabelleIva());
    }
  };

  const isLogged = async () => {
    const token = await checkTokenValidity();
    return token !== null;
  };
  const loader = userLoader || isLogged();

  useEffect(() => {
    fetchData().then();
  }, []);
};

export default useInit;
