import { ColDef } from 'ag-grid-community';
import { FattureStats } from 'components';
import { DataGridEditable, DateEditor } from 'components/DataGridEditable';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ACTION_USER,
  AddLogSchedaParams,
  Anagrafica,
  Azioni,
  AzioniLabels,
  BLOCK,
  COMPLETED,
  CampiScheda,
  ERROR_CORRECTION_LABEL,
  IGNORE_ERROR,
  IGNORE_NOW,
  KeyValue,
  RIGA,
  RIGA_ANAGRAFICA,
  SchedaCamnbioStatoParams,
  SchedaSaveParams,
  TYPE_CEDOLINI,
  TYPE_DATA_FATTURAZIONE,
  TipoValidazione,
  VALIDATE,
  VALIDAZIONE_ANAGRAFICA,
  VALIDAZIONE_COMPLETA,
  VALIDAZIONE_SCHEDA,
  addLogsScheda,
  cambioStatoScheda,
  createAnagrafica,
  deleteFatturazioniScheda,
  esportaFatturazione,
  getCampiFatturazione,
  getSchedaById,
  getValidationError,
  saveScheda,
  sendFatturazioni,
  setFatturazioneSelectedIds,
  useAppDispatch,
  validateScheda,
} from 'store';
import {
  StepProps,
  StepValidazione,
  InfoStepsProps,
  STEP_ANAGRAFICA,
  STEP_SCHEDA,
  STEP_FATTURAZIONE,
  STEP_INVIO_FATTURE,
  GenericObject,
} from 'utils';

interface UseValidateProps {
  id: number;
  closeForm?: () => void;
}

export const useValidate = ({ id, closeForm }: UseValidateProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const validationError = useSelector(getValidationError);
  const campiScheda = useSelector(getCampiFatturazione);
  const scheda = useSelector(getSchedaById(id));

  const [isLoading, setIsLoading] = useState(false);
  const [fattureDaInviare, setFattureDaInviare] = useState([]);
  const [fattureStats, setfattureStats] = useState<GenericObject | null>();
  const [completedComponent, setCompletedComponent] = useState<React.ReactNode | null>(null);

  const initialInfoStep: Record<StepValidazione, InfoStepsProps> = {
    [STEP_ANAGRAFICA]: {
      stato: 'idle',
      title: 'Validazione Anagrafiche',
    },
    [STEP_SCHEDA]: {
      stato: 'idle',
      title: 'Validazione Scheda',
    },
    [STEP_FATTURAZIONE]: {
      stato: 'idle',
      title: 'Creazione Fatture',
    },
    [STEP_INVIO_FATTURE]: {
      stato: 'idle',
      title: 'Invio Fatture',
    },
  };

  const [infoSteps, setInfoSteps] =
    useState<Record<StepValidazione, InfoStepsProps>>(initialInfoStep);

  const [steps, setSteps] = useState<StepProps[]>([]);
  const [buttonSteps, setButtonSteps] = useState<string[]>([]);

  const updateButtonLabelsByDataValidate = (data: any, tipo?: TipoValidazione) => {
    const buttonLabel = 'Valida Scheda';
    const buttonLabelAnagrafiche = 'Valida Anagrafiche';
    const buttonLabelFatture = 'Valida Fatture';

    if (!tipo || tipo === VALIDAZIONE_ANAGRAFICA) {
      const buttonsLabel: string[] = [];
      if (data.anagrafica?.length > 0) buttonsLabel.push(buttonLabelAnagrafiche);
      if (data.righe?.length > 0) buttonsLabel.push(buttonLabel);
      if (data.anagrafica?.length === 0 && data.righe?.length === 0)
        buttonsLabel.push(buttonLabelFatture);
      setButtonSteps(buttonsLabel);
    }
    if (tipo === VALIDAZIONE_SCHEDA) {
      if (data.righe?.length > 0) setButtonSteps([buttonLabel]);
      else {
        setButtonSteps([buttonLabelFatture]);
      }
    }
  };

  const validateAnagrafica = async () => {
    const buttonLabelAnagrafiche = 'Valida Anagrafiche';
    const currentInfoStep = { ...infoSteps };
    currentInfoStep[STEP_ANAGRAFICA].stato = 'waiting';
    setInfoSteps(currentInfoStep);
    const response = await dispatch(validateScheda({ id, tipo: VALIDAZIONE_ANAGRAFICA }));
    if (response && !response.error) {
      const data = response.payload.data;
      console.log({ data, response });
      if (data.anagrafiche?.length > 0) {
        setButtonSteps([buttonLabelAnagrafiche]);
        setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
          const anagrafica = prev[STEP_ANAGRAFICA];
          anagrafica.stato = 'error';
          const current = { ...prev };
          current[STEP_ANAGRAFICA] = anagrafica;
          return current;
        });
        addFormAnagrafiche(data.anagrafiche);
        return false;
      } else {
        setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
          const anagrafica = prev[STEP_ANAGRAFICA];
          anagrafica.stato = 'success';
          const current = { ...prev };
          current[STEP_ANAGRAFICA] = anagrafica;
          return current;
        });
        return true;
      }
    }
    return false;
  };

  const validateSchede = async () => {
    const buttonLabelSchede = 'Valida Schede';
    const currentInfoStep = { ...infoSteps };
    currentInfoStep[STEP_SCHEDA].stato = 'waiting';
    setInfoSteps(currentInfoStep);
    const response = await dispatch(validateScheda({ id, tipo: VALIDAZIONE_SCHEDA }));
    if (response && !response.error) {
      const data = response.payload.data;
      if (data.righe?.length > 0) {
        setButtonSteps((prev: string[]) => {
          return [...prev, buttonLabelSchede];
        });
        setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
          const scheda = prev[STEP_SCHEDA];
          scheda.stato = 'error';
          const current = { ...prev };
          current[STEP_SCHEDA] = scheda;
          return current;
        });
        addFormRighe(data.righe);
        return false;
      } else {
        setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
          const scheda = prev[STEP_SCHEDA];
          scheda.stato = 'success';
          const current = { ...prev };
          current[STEP_SCHEDA] = scheda;
          return current;
        });
        return true;
      }
    }
    return false;
  };

  const createFatture = async () => {
    const buttonLabelSchede = 'Crea Fatture';
    const currentInfoStep = { ...infoSteps };
    currentInfoStep[STEP_FATTURAZIONE].stato = 'waiting';
    setInfoSteps(currentInfoStep);

    const responseFatture = await dispatch(
      esportaFatturazione({
        mese: Number(scheda?.mese),
        anno: Number(scheda?.anno),
        schede_id: [id],
      })
    );
    if (responseFatture && !responseFatture.error) {
      setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
        const fatturazione = prev[STEP_FATTURAZIONE];
        fatturazione.stato = 'success';
        const current = { ...prev };
        current[STEP_FATTURAZIONE] = fatturazione;
        return current;
      });
      const fatture = responseFatture.payload.data;
      setFattureDaInviare(fatture);
    } else {
      setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
        const fatturazione = prev[STEP_FATTURAZIONE];
        fatturazione.stato = 'error';
        const current = { ...prev };
        current[STEP_FATTURAZIONE] = fatturazione;
        return current;
      });
    }
  };

  const inviaFattureF24 = async () => {
    const buttonLabelInvioFatture = 'Invia Fatture';
    const currentInfoStep = { ...infoSteps };
    currentInfoStep[STEP_INVIO_FATTURE].stato = 'waiting';
    setInfoSteps(currentInfoStep);
    const response = await dispatch(sendFatturazioni({ scheda_id: id }));
    if (response && !response.error) {
      setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
        const invioFatture = prev[STEP_INVIO_FATTURE];
        invioFatture.stato = 'success';
        const current = { ...prev };
        current[STEP_INVIO_FATTURE] = invioFatture;
        return current;
      });
      const stats = response.payload.data;

      const component = React.createElement(FattureStats, {
        ...stats,
        actionCallback: () => {
          closeForm && closeForm();
          navigate('/fatturazione/list');
        },
      });
      setCompletedComponent(component);
      setfattureStats(stats);
    } else {
      setInfoSteps((prev: Record<StepValidazione, InfoStepsProps>) => {
        const invioFatture = prev[STEP_INVIO_FATTURE];
        invioFatture.stato = 'error';
        const current = { ...prev };
        current[STEP_INVIO_FATTURE] = invioFatture;
        return current;
      });
      if (fattureDaInviare.length > 0) {
        setButtonSteps([buttonLabelInvioFatture]);
        addFormFatture(fattureDaInviare);
      }
    }
  };

  const validateStepByStep = async () => {
    setButtonSteps([]);
    setSteps([]);
    setIsLoading(true);
    const params: SchedaCamnbioStatoParams = {
      id,
      stato: VALIDATE,
    };

    const response = await dispatch(cambioStatoScheda(params));
    if (response && !response.error) {
      const resAnagrafiche = await validateAnagrafica();
      const resSchede = await validateSchede();
      if (resAnagrafiche && resSchede) {
        await createFatture();
        const resInvio = await inviaFattureF24();
      }
    }
    setIsLoading(false);
  };

  const onCellValueChangedAnagrafica = async (params: any) => {
    const { colDef, data, newValue, oldValue } = params;

    const field = colDef.field;

    const valueChanged = newValue !== oldValue;
    const valueAzione = Azioni.find((a: KeyValue) => a.descrizione === (data.azione as string));

    if (field === 'azione' && valueChanged) {
      const paramsLog = {
        scheda_id: id,
        anagrafica_id: data.id,
        tipo: ACTION_USER,
        tipo_riga: RIGA_ANAGRAFICA,
        azione: valueAzione?.id,
      };
      const response = await dispatch(addLogsScheda(paramsLog as AddLogSchedaParams));
      return;
    }

    if (newValue !== oldValue) {
      const req = { ...data };
      delete req.azione;
      if (valueAzione?.id && [IGNORE_ERROR, IGNORE_NOW].includes(valueAzione.id as string)) return;
      dispatch(createAnagrafica(req));
    }
  };

  const onCellValueChangedRiga = async (params: any) => {
    const { colDef, data, newValue, oldValue } = params;

    const field = colDef.field;

    const valueChanged = newValue !== oldValue;
    const valueAzione = Azioni.find((a: KeyValue) => a.descrizione === (data.azione as string));

    if (field === 'azione' && valueChanged) {
      const paramsLog = {
        scheda_id: id,
        riga_id: data.id,
        anagrafica_id: data.anagrafica_id,
        tipo: ACTION_USER,
        tipo_riga: RIGA,
        azione: valueAzione?.id,
      };
      const response = await dispatch(addLogsScheda(paramsLog as AddLogSchedaParams));
      return;
    }

    if (newValue !== oldValue) {
      const row = { ...data };
      delete row.azione;
      const req: SchedaSaveParams = { scheda_id: id, row: { ...row } };
      if (valueAzione?.id && [IGNORE_ERROR, IGNORE_NOW].includes(valueAzione.id as string)) return;

      dispatch(saveScheda(req));
    }
  };

  const addFormAnagrafiche = (anagrafiche: Anagrafica[]) => {
    setButtonSteps((prev: string[]) => {
      const buttonLabel = 'Valida Anagrafiche';
      if (!prev || prev.length === 0) return [buttonLabel];
      const newButtonSteps = prev.find((label) => label === buttonLabel);
      if (!newButtonSteps) return [buttonLabel, ...prev];
      return prev;
    });
    const columns: ColDef[] = [
      { headerName: 'ID', field: 'id', editable: false, sortable: false, width: 150 },
      {
        headerName: 'Rag. sociale',
        field: 'ragione_sociale',
        width: 500,
        editable: false,
        sortable: true,
      } as ColDef,
      {
        headerName: 'Scegi opzione',
        field: 'azione',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: AzioniLabels },
        width: 200,
      },
      {
        headerName: 'Cod. Destinatario',
        field: 'codice_destinatario',
        width: 200,
        editable: true,
        sortable: false,
        cellEditor: 'agTextCellEditor',
      } as ColDef,
      {
        headerName: 'PEC',
        field: 'pec',
        width: 200,
        editable: true,
        sortable: false,
        cellEditor: 'agTextCellEditor',
      } as ColDef,
      {
        headerName: 'Canonne mensile',
        field: 'canone_fisso',
        editable: true,
        width: 200,
        cellEditor: 'agNumberCellEditor',
      } as ColDef,
      {
        headerName: 'Quota cedolino',
        field: 'quota_cedolino',
        editable: true,
        width: 200,
        cellEditor: 'agNumberCellEditor',
      } as ColDef,
    ];

    const smallRows = anagrafiche.map((anagrafica: Anagrafica) => {
      return {
        id: anagrafica.id,
        ragione_sociale: anagrafica.ragione_sociale,
        azione: ERROR_CORRECTION_LABEL,
        codice_destinatario: anagrafica.codice_destinatario,
        pec: anagrafica.pec,
        canone_fisso: Number(anagrafica.canone_fisso),
        quota_cedolino: Number(anagrafica.quota_cedolino),
      };
    });
    setSteps((prevStep: StepProps[]) => {
      const title = 'Errori Anagrafiche';
      const newSteps = [];
      if (prevStep.length > 0) {
        const diffSteps = prevStep.filter((step) => step.title !== title);
        if (diffSteps.length > 0) newSteps.push(...diffSteps);
      }
      return [
        ...newSteps,
        {
          title: 'Errori Anagrafiche',
          actionNext: () => {
            validateStepByStep();
          }, // Add a dummy actionNext function
          component: (
            <DataGridEditable
              columns={columns}
              rows={smallRows}
              onCellValueChanged={(params: any) => {
                onCellValueChangedAnagrafica(params);
              }}
            />
          ),
        },
      ];
    });
  };

  const addFormRighe = (righe: Record<string, any>[]) => {
    const columns: ColDef[] = [
      { headerName: 'ID', field: 'id', editable: false, sortable: false, width: 150 },
      {
        headerName: 'Rag. sociale',
        field: 'ragione_sociale',
        width: 500,
        sortable: true,
      } as ColDef,
      {
        headerName: 'Scegi opzione',
        field: 'azione',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: { values: AzioniLabels },
        width: 200,
      },
    ];
    campiScheda.forEach((c) => {
      if (c.riga_tipo === TYPE_CEDOLINI) {
        columns.push({
          headerName: c.riga_nome,
          field: c.colonna_nome,
          editable: true,
          sortable: false,
          cellEditor: 'agNumberCellEditor',
          width: 150,
        });
      }
      if (c.riga_tipo === TYPE_DATA_FATTURAZIONE) {
        columns.push({
          headerName: c.riga_nome,
          field: c.colonna_nome,
          editable: true,
          sortable: false,
          cellEditorFramework: DateEditor,
          width: 200,
        } as ColDef);
      }
    });
    const smallRighe = righe.map((riga) => {
      const item: Record<string, any> = {
        id: riga.id,
        ragione_sociale: riga.ragione_sociale,
        azione: ERROR_CORRECTION_LABEL,
        anagrafica_id: riga.anagrafica_id,
      };

      Object.keys(riga).forEach((key) => {
        const campo = campiScheda.find((c: CampiScheda) => c.colonna_nome === key);
        if (campo) {
          if (campo.riga_tipo === TYPE_CEDOLINI) {
            item[key] = Number(riga[key]);
          } else {
            item[key] = riga[key];
          }
        }
      });

      return item;
    });

    setSteps((prevStep: StepProps[]) => {
      const title = 'Errori Scheda';
      const newSteps = [];
      if (prevStep.length > 0) {
        const diffSteps = prevStep.filter((step) => step.title !== title);
        if (diffSteps.length > 0) newSteps.push(...diffSteps);
      }
      return [
        ...newSteps,
        {
          title: 'Errori Scheda',
          actionNext: () => {
            validateStepByStep();
          },
          component: (
            <DataGridEditable
              columns={columns}
              rows={smallRighe}
              onCellValueChanged={(params: any) => {
                onCellValueChangedRiga(params);
              }}
            />
          ),
        },
      ];
    });
  };

  const addFormFatture = (righe: Record<string, any>[]) => {
    const columns: ColDef[] = [
      { headerName: 'ID', field: 'id', editable: false, sortable: false, width: 150 },
      {
        headerName: 'Rag. sociale',
        field: 'ragione_sociale',
        width: 500,
        sortable: true,
      } as ColDef,
      {
        headerName: 'Tot. Netto',
        field: 'totale_netto',
        editable: false,
        width: 200,
      },
      {
        headerName: 'Tot. IVA',
        field: 'totale_iva',
        editable: false,
        width: 200,
      },
      {
        headerName: 'Tot. Ivato',
        field: 'totale_ivato',
        editable: false,
        width: 200,
      },
    ];

    const smallRighe = righe.map((riga) => {
      const item: Record<string, any> = {
        id: riga.id,
        ragione_sociale: riga.ragione_sociale,
        totale_netto: riga.totale_netto,
        totale_iva: riga.totale_iva,
        totale_ivato: riga.totale_ivato,
      };

      return item;
    });

    const reloadFatture = async () => {
      setIsLoading(true);
      setButtonSteps([]);
      const response = await dispatch(deleteFatturazioniScheda({ scheda_id: id }));
      await createFatture();
      const resInvio = await inviaFattureF24();
      setIsLoading(false);
    };

    setSteps((prevStep: StepProps[]) => {
      const title = 'Fatture da inviare';
      const newSteps = [];
      if (prevStep.length > 0) {
        const diffSteps = prevStep.filter((step) => step.title !== title);
        if (diffSteps.length > 0) newSteps.push(...diffSteps);
      }
      return [
        ...newSteps,
        {
          title: title,
          actionNext: () => {
            reloadFatture();
          },
          component: (
            <DataGridEditable
              columns={columns}
              rows={smallRighe}
              onCellValueChanged={(params: any) => {
                void 0;
              }}
            />
          ),
        },
      ];
    });
  };

  const addFattureErrate = (righe: Record<string, any>[]) => {
    const columns: ColDef[] = [
      { headerName: 'ID', field: 'id', editable: false, sortable: false, width: 150 },
      {
        headerName: 'Rag. sociale',
        field: 'ragione_sociale',
        width: 500,
        sortable: true,
      } as ColDef,
      {
        headerName: 'Descrizione errore',
        field: 'f24_description',
        editable: false,
        width: 500,
      },
      {
        headerName: 'Tot. Netto',
        field: 'totale_netto',
        editable: false,
        width: 200,
      },
      {
        headerName: 'Tot. IVA',
        field: 'totale_iva',
        editable: false,
        width: 200,
      },
      {
        headerName: 'Tot. Ivato',
        field: 'totale_ivato',
        editable: false,
        width: 200,
      },
    ];

    const smallRighe = righe.map((riga) => {
      const item: Record<string, any> = {
        id: riga.id,
        ragione_sociale: riga.ragione_sociale,
        f24_description: riga.f24_description,
        totale_netto: riga.totale_netto,
        totale_iva: riga.totale_iva,
        totale_ivato: riga.totale_ivato,
      };

      return item;
    });

    const deleteFattureScheda = async () => {
      const response = await dispatch(deleteFatturazioniScheda({ scheda_id: id }));
      await validateStepByStep();
    };

    setSteps((prevStep: StepProps[]) => {
      const title = 'Fatture Inviate con Errori';
      const newSteps = [];
      if (prevStep.length > 0) {
        const diffSteps = prevStep.filter((step) => step.title !== title);
        if (diffSteps.length > 0) newSteps.push(...diffSteps);
      }
      return [
        ...newSteps,
        {
          title: title,
          actionNext: () => {
            deleteFattureScheda();
          },
          component: (
            <DataGridEditable
              columns={columns}
              rows={smallRighe}
              onCellValueChanged={(params: any) => {
                void 0;
              }}
            />
          ),
        },
      ];
    });
  };

  return {
    steps,
    isLoading,
    buttonSteps,
    validate: validateStepByStep,
    infoSteps,
    fattureStats,
    completedComponent,
  };
};
