import {
  AdvancedConfrimModal,
  DataModal,
  DataOptionTable,
  DropdownMenu,
  Flex,
  FormInput,
  SimpleStickyTable,
  TableGrid,
  Text,
} from 'components';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Context } from '../Context';
import Icon from '@ailibs/feather-react-ts';
import classNames from 'classnames';
import { AFFILIATI, MenuItem } from 'utils';
import {
  AFFILIATI_ID,
  ANAGRAFICA_ID,
  Anagrafica,
  getSelectorElement,
  getSelectorSelectedIds,
  mapGlobalSelector,
  resetAnagraficaSelectedIds,
  useAppDispatch,
} from 'store';
import { GridRowId } from '@mui/x-data-grid';
import FormContext from 'components/Form/FormContext';
import {
  associaAffiliatiMulti,
  deleteAffiliatiMulti,
  fetchAffiliatiOptions,
} from 'store/anagrafiche/affiliati';
import { ConfrimModal } from 'components/ConfirmModal';

export const Affiliati = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { perms } = useContext(Context);

  const { formId } = useContext(FormContext);

  const anagrafica = getSelectorElement<Anagrafica>(ANAGRAFICA_ID);

  const selectedIds: any[] = getSelectorSelectedIds(ANAGRAFICA_ID);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const dispatch = useAppDispatch();

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => setIsOpen(true);

  const onSubmit = async () => {
    if (selectedIds && selectedIds.length > 0 && formId) {
      const response = await dispatch(
        associaAffiliatiMulti({ padre_id: formId, children_ids: selectedIds })
      );
      if (response && !response.error) {
        dispatch(resetAnagraficaSelectedIds());
        await dispatch(fetchAffiliatiOptions());
      }
    }
  };

  const deleteMenu: MenuItem = {
    key: `elimina-affiliati`,
    title: 'Elimina selezionati',
    onClick: onOpen,
    icon: 'trash-2',
    color: 'red',
  };

  const menu = [deleteMenu];

  const handleDeleteMulti = async () => {
    const response = dispatch(deleteAffiliatiMulti());
    if (response && !response.error) dispatch(fetchAffiliatiOptions());
    onClose();
  };

  return anagrafica && anagrafica.padre_id ? (
    <div />
  ) : (
    <Card className="mb-3">
      <Card.Header>
        <Flex justifyContent="space-between">
          <span>Affiliati</span>
          <Flex justifyContent="center">
            <DataModal
              button={
                <Button
                  variant="soft-primary"
                  onClick={() => void 0}
                  className="cursor-pointer rounded-pill dropdown-toggle btn btn-soft-primary"
                  disabled={perms && !perms.can_write}
                  style={{
                    padding: '.1rem 0rem .1rem .4rem',
                    marginRight: '0.5rem',
                    borderRadius: '50%',
                  }}
                >
                  <Icon
                    name={'plus-circle'}
                    size={14}
                    className={classNames('align-self-center, icon-xs me-1')}
                  />
                </Button>
              }
              onSubmit={onSubmit}
              title={'Elenco clienti da affiliare'}
              tabId={ANAGRAFICA_ID}
              additionalParams={{ no_parent: true, excluded_id: formId }}
            />
            <DropdownMenu menu={menu} />
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Body>
        <DataOptionTable tabId={AFFILIATI_ID} parentId={formId || undefined} height="40vh" />
      </Card.Body>
      <AdvancedConfrimModal
        show={isOpen}
        setShow={(show: boolean) => setIsOpen(false)}
        title={'Elimina affiliati'}
        onConfirm={handleDeleteMulti}
      >
        <Flex justifyContent="center" marginBottom=".5rem">
          Confermi di voler rimuovere gli affiliati selezionati?
        </Flex>
      </AdvancedConfrimModal>
    </Card>
  );
};

export default Affiliati;
