// GenericGrid.tsx
import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { TabsId } from 'store';
import IndeterminateCheckbox from './IndeterminateCheck';
import Flex from 'components/Flex';
import { EllipsisTableCell } from './styledCell';

export interface GridColumn {
  id: string;
  label: string;
  minWidth?: number;
  renderCell?: (row?: any) => React.ReactNode;
}

const StyledTablePagination = styled(TablePagination)({
  // Aggiungi qui i tuoi stili personalizzati
  display: 'flex',
  alignItems: 'center',
  'p.MuiTablePagination-displayedRows': {
    margin: '0',
  },
  '.MuiTablePagination-toolbar': {
    alignItems: 'center',
  },
  'p.MuiTablePagination-selectLabel': {
    margin: 0,
  },
});

interface GenericGridProps {
  tabId?: TabsId;
  columns: GridColumn[];
  data: any[];
  rowsPerPageOptions?: number[];
  height?: string;
  option?: boolean;
}

const GenericGrid: React.FC<GenericGridProps> = ({
  tabId,
  columns,
  data,
  rowsPerPageOptions = [25, 50, 100],
  height = '50rem',
  option = true,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [tableHeight, setTableHeight] = useState('auto');
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [rows, setRows] = useState<Record<string, any>[]>([]);

  // useEffect(() => {
  //   const calculateTableHeight = () => {
  //     const viewportHeight = window.innerHeight;
  //     const headerHeight = 15 * 16; // Assumendo un'altezza di intestazione di 15rem
  //     const paginationHeight = 56; // Altezza approssimativa del componente di paginazione di Material-UI

  //     const availableHeight = viewportHeight - headerHeight - paginationHeight;
  //     setTableHeight(`${availableHeight}px`);
  //   };

  //   window.addEventListener('resize', calculateTableHeight);
  //   calculateTableHeight();

  //   return () => {
  //     window.removeEventListener('resize', calculateTableHeight);
  //   };
  // }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchData = (currentPage: number) => {
    if (data.length > 0) {
      const start = currentPage * rowsPerPage;
      const end = (currentPage + 1) * rowsPerPage;
      const newRows = [...rows, ...data.slice(start, end)];

      setRows(newRows);
    }
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

      if (Math.abs(scrollHeight - scrollTop - clientHeight) < 1) {
        setPage((prevPage) => prevPage + 1);
      }
    };
    fetchData(0);
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    // Ricorda di rimuovere l'evento quando il componente si smonta
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    if (data && data.length) {
      const start = 0;
      const end = rowsPerPage;
      const newRows = [...data.slice(start, end)];
      setRows(newRows);
      setPage(0);
    }
  }, [data, data.length]);

  return (
    <Paper>
      <TableContainer ref={tableContainerRef} style={{ height: height, overflow: 'auto' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {tabId && (
                <TableCell style={{ background: 'aliceblue' }}>
                  <IndeterminateCheckbox tabId={tabId} header option={option} />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    background: 'aliceblue',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  <Typography fontWeight={'bold'}>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow hover key={index}>
                {tabId && (
                  <TableCell>
                    <IndeterminateCheckbox
                      tabId={tabId}
                      id={row?.id}
                      style={{ marginBottom: '0.6rem', marginTop: 0 }}
                      option={option}
                    />
                  </TableCell>
                )}
                {columns.map((column) => {
                  if (column.renderCell) {
                    return <TableCell>{column.renderCell(row)}</TableCell>;
                  }
                  const value = row[column.id];
                  return <EllipsisTableCell key={column.id}>{value}</EllipsisTableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {pagination && (
        <StyledTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
    </Paper>
  );
};

export default GenericGrid;
