import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getFatturazioneState = (state: RootState) => state.fatturazione;

export const getFatturazioneLoader = createSelector(
  getFatturazioneState,
  (fatturazioneState) => fatturazioneState.loading === PENDING
);

export const getFatturazioneFailed = createSelector(
  getFatturazioneState,
  (fatturazioneState) => fatturazioneState.loading === FAILED
);

export const getFatturazioneSuccess = createSelector(
  getFatturazioneState,
  (fatturazioneState) => fatturazioneState.loading === SUCCEEDED
);

export const getFatturazioneError = createSelector(
  getFatturazioneState,
  (fatturazioneState) => fatturazioneState?.error
);

export const getFatturazione = createSelector(
  getFatturazioneState,
  (fatturazioneState) => fatturazioneState?.data
);
