import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
  DELETE,
  DRIVE_TEST_ID,
  FETCHLIST,
  getAction,
  getSelectorListData,
  getSelectorListDataInfo,
  getSelectorListDataParams,
  getSelectorLoading,
  getSelectorSelectedIds,
  mapAction,
  Perm,
  SETPARAMS,
  SETSELECTALL,
  SETSELECTEDIDS,
  SUBMIT,
  TabsId,
  useAppDispatch,
} from '../../store';
import { accessorGrid, ModuleName } from 'utils';
import { useFormContext } from 'react-hook-form';

interface DataGridProps {
  tabId: TabsId;
  module: ModuleName;
  initialParams: any;
  parentId: number | null;
  perm?: Perm;
  dataRows: any[];
  setIsNew: (isNew: boolean) => void;
  setDataRows: (dataRows: any[]) => void;
  handleSaveCurrentTab?: () => Promise<any> | undefined;
}

interface DataGridReturn {
  data: any;
  loading: boolean;
  columnSettings: any;
  rowSelection: any;
  getData: () => void;
  handleDelete: (id: number) => Promise<void>;
  onClickNew: (parentId: number, position?: number, row?: any) => void;
  onClickNewWithOutFormId: () => void;
  show: boolean;
  onConfirm: () => void;
  handleClose: () => void;
  handleOpenDelete: (crudId: number) => void;
  saveRow: (row: any) => Promise<any>;
  crudId: number | null;
}

export const useGrid = ({
  tabId,
  module,
  initialParams,
  parentId,
  dataRows,
  perm,
  setIsNew,
  setDataRows,
  handleSaveCurrentTab,
}: DataGridProps): DataGridReturn => {
  const dispatch = useAppDispatch();
  const { setValue, reset } = useFormContext();
  const data: any = getSelectorListData(tabId);
  const params: Record<string, any> = getSelectorListDataParams(tabId);
  const [fetchParams, setFetchParams] = useState(params ?? { parentId });
  const loading: boolean = getSelectorLoading(tabId);
  const selectedIds = getSelectorSelectedIds(tabId);
  const [show, setShow] = useState<boolean>(false);

  const [crudId, setCrudId] = useState<number | null>(null);

  const onConfirm = async () => {
    await handleDelete(crudId as number);
    setCrudId(null);
    handleClose();
  };

  const handleClose = () => {
    setShow(false);
    setCrudId(null);
  };

  const handleOpenDelete = (crudId: number) => {
    setCrudId(crudId);
    setShow(true);
  };
  // const [paramsLength, setParamsLength] = useState<number>(params ? Object.keys(params).length : 0);

  const updateParams = useCallback(
    (parameters: any) => {
      if (parameters) {
        setFetchParams(parameters);
      }
    },
    [fetchParams]
  );

  const getData = useCallback(async () => {
    const actionFetch = getAction(tabId, FETCHLIST);
    if (actionFetch) {
      const response = await dispatch(actionFetch());
      setDataRows(response?.payload?.data);
    }
  }, [tabId, parentId]);

  const onSelectedRowKeysChange = useCallback(
    (selectedRowId: any) => {
      const actionSelectedIds = mapAction[tabId][SETSELECTEDIDS];
      actionSelectedIds && dispatch(actionSelectedIds(selectedRowId));
    },
    [tabId]
  );

  const onSelectAllRecord = useCallback(() => {
    const actionSelectedIds = mapAction[tabId][SETSELECTALL];
    actionSelectedIds && dispatch(actionSelectedIds());
  }, []);

  const rowSelection = {
    selectedIds,
    onSelect: (record: any, selected: any, selectedRows: any) => {
      onSelectedRowKeysChange(record?.id);
    },
    onSelectAll: () => {
      onSelectAllRecord();
    },
  };
  const handleDelete = async (id: number) => {
    const action = getAction(tabId, DELETE);
    action && (await dispatch(action({ id })));
    getData();
  };

  const onClickNew = async (parentId: number, position?: number, row?: any) => {
    const newPosition: number =
      position || position === 0
        ? -position
        : data[data.length - 1]?.posizione
        ? Number(data[data.length - 1]?.posizione) + 1
        : 1;

    // const newPosition = data?.length ?? 1;
    const request = { posizione: newPosition, parentId };
    const actionCreate = getAction(tabId, SUBMIT);
    const rowResponse = await dispatch(actionCreate(request));
    // await getData();
    if (!!rowResponse && !rowResponse.error) {
      const actionSetParams = getAction(tabId, SETPARAMS);
      actionSetParams && dispatch(actionSetParams({ parentId }));
      const actionFetchList = getAction(tabId, FETCHLIST);
      const newData = await dispatch(actionFetchList());
      setDataRows(newData?.payload?.data);
      setIsNew(true);
    }
  };

  const onClickNewWithOutFormId = async () => {
    if (handleSaveCurrentTab) {
      const result = await handleSaveCurrentTab();
      if (result) {
        setValue('id', result.id);
        reset(result);
        onClickNew(result.id);
      }
    }
  };

  const saveRow = async (row: any) => {
    const actionCreate = getAction(tabId, SUBMIT);
    if (actionCreate) {
      const response = await dispatch(actionCreate(row));
      const actionFetch = getAction(tabId, FETCHLIST);
      actionFetch && (await dispatch(actionFetch()));
      return response.payload?.data;
    }
    return null;
  };

  const columnSettings = accessorGrid({ tabId, delete: handleOpenDelete, add: onClickNew, perm });

  useEffect(() => {
    const actionSetParams = getAction(tabId, SETPARAMS);
    dispatch(actionSetParams({ parentId }));
    getData();
  }, [parentId, tabId]);

  const handleCloseRow = (): void => {
    setDataRows(dataRows.filter((row: any) => row.id));
  };

  useEffect(() => {
    setDataRows(data);
  }, [JSON.stringify(data)]);

  return {
    data,
    loading,
    columnSettings,
    rowSelection,
    getData,
    handleDelete,
    onClickNew,
    onClickNewWithOutFormId,
    show,
    onConfirm,
    handleClose,
    handleOpenDelete,
    saveRow,
    crudId,
  };
};
