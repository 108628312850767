import {
  getUtenti,
  getUtentiFailed,
  getUtentiInfo,
  getUtentiLoader,
  getUtentiOption,
  getUtentiOptionFailed,
  getUtentiOptionInfo,
  getUtentiOptionLoader,
  getUtentiOptionSuccess,
  getUtentiParams,
  getUtentiParamsOption,
  getUtentiSelectedIds,
  getUtentiSuccess,
  getUtenteById,
  getUtenteOptionById,
  getUtentiKeyValues,
  getUtentiOptionKeyValues,
} from '../selectors';

export const UtentiMapSelector = {
  loading: getUtentiLoader,
  success: getUtentiSuccess,
  failed: getUtentiFailed,
  data: getUtenti,
  info: getUtentiInfo,
  params: getUtentiParams,
  loadingOption: getUtentiOptionLoader,
  successOption: getUtentiOptionSuccess,
  failedOption: getUtentiOptionFailed,
  dataOption: getUtentiOption,
  infoOption: getUtentiOptionInfo,
  paramsOption: getUtentiParamsOption,
  selectedIds: getUtentiSelectedIds,
  selectKeyValues: getUtentiKeyValues,
  selectOptionKeyValues: getUtentiOptionKeyValues,
  elementById: getUtenteById,
  elementOptionById: getUtenteOptionById,
};

export default UtentiMapSelector;
