import { UtenteState } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { createUtente, deleteUtente, fetchUtente, resetUtente } from '../actions';
import { FAILED, IDLE, PENDING, SUCCEEDED } from '../../../types';

const initialState: UtenteState = {
  data: null,
  loading: IDLE,
  error: null,
  config: null,
  list: [],
};

export const utenteSlice = createSlice({
  name: 'utente',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchUtente.fulfilled, (state: any, { payload }: any) => {
        state.data = payload?.data?.utente;
        state.list = payload?.data?.list;
        state.config = payload?.data?.config;
        state.loading = SUCCEEDED;
      })
      .addCase(fetchUtente.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        fetchUtente.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(
        createUtente.fulfilled,
        (state: { data: any; list: never[]; config: null; loading: string }, { payload }: any) => {
          state.data = payload;
          state.list = [];
          state.config = null;
          state.loading = SUCCEEDED;
        }
      )
      .addCase(createUtente.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        createUtente.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      )
      .addCase(
        resetUtente,
        (state: { data: null; list: never[]; config: null; loading: string; error: null }) => {
          state.data = null;
          state.list = [];
          state.config = null;
          state.loading = IDLE;
          state.error = null;
        }
      )
      .addCase(
        deleteUtente.fulfilled,
        (state: { data: null; list: never[]; config: null; loading: string }) => {
          state.data = null;
          state.list = [];
          state.config = null;
          state.loading = SUCCEEDED;
        }
      )
      .addCase(deleteUtente.pending, (state: { loading: string }) => {
        state.loading = PENDING;
      })
      .addCase(
        deleteUtente.rejected,
        (state: { error: any; loading: string }, action: { error: { code: any; name: any } }) => {
          state.error = action?.error?.code || action?.error?.name || '';
          state.loading = FAILED;
        }
      );
  },
});
