import { useAppDispatch } from 'store';
import Switch from '@mui/material/Switch';
import React from 'react';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13 / 2,
  },
}));

interface ActionOnChangeParams {
  id: number;
  enable: boolean;
}

interface SwitchRowGridProps {
  id: number;
  value: boolean;
  checkId: string;
  disabled?: boolean;
  action: (params: ActionOnChangeParams) => any;
  updateSingleRecord: (params: any) => any;
}

export const SwitchRowGrid = ({
  id,
  value,
  checkId,
  disabled = false,
  action,
  updateSingleRecord,
}: SwitchRowGridProps) => {
  const [checked, setChecked] = React.useState(value);

  const dispatch = useAppDispatch();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.checked;

    const response = await dispatch(action({ id, enable: val }));
    if (response && !response.error) {
      const element = response.payload.data;
      if (element) {
        dispatch(updateSingleRecord(element));
      }
    }
    setChecked(val);
  };

  return (
    <CustomSwitch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': `${checkId}` }}
      disabled={disabled}
    />
  );
};

export default SwitchRowGrid;
