import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './applicationState';
import { useDispatch } from 'react-redux';
import { resetStateMiddleware } from './middlewares';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false }).concat(resetStateMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): any => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
