import {
  Fatturazione,
  inviataSdiFatturazione,
  updateSingleRecordFatturazioni,
  useAppDispatch,
} from 'store';
import React from 'react';
import { Checkbox, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CheckInvioSDIProps {
  value: boolean;
  row: Fatturazione;
  showSwitch?: boolean;
  onChangeCallback?: () => void;
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13 / 2,
  },
}));

export const CheckInvioSDI = ({
  value,
  row,
  showSwitch = false,
  onChangeCallback,
}: CheckInvioSDIProps) => {
  const [checked, setChecked] = React.useState(value);

  const dispatch = useAppDispatch();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inviata = event.target.checked;
    const response = await dispatch(
      inviataSdiFatturazione({
        inviata,
        fatturazione_id: row.id as number,
      })
    );
    if (response && !response.error) {
      const fatturazione = response.payload.data;
      if (fatturazione) {
        dispatch(updateSingleRecordFatturazioni(fatturazione));
        onChangeCallback && onChangeCallback();
      }
    }
    setChecked(inviata);
  };

  return showSwitch ? (
    <CustomSwitch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': `inviata_sdi_${row.id}` }}
    />
  ) : (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': `inviata_sdi_${row.id}` }}
      style={{ padding: 0, paddingLeft: '1rem' }}
    />
  );
};

export default CheckInvioSDI;
