import { ActionType } from '../../..';
import {
  createFatturazione,
  deleteFatturazione,
  fetchFatturazione,
  resetFatturazione,
} from '../actions';

export const FatturazioneMapAction: Record<ActionType | string, any> = {
  fetch: fetchFatturazione,
  submit: createFatturazione,
  delete: deleteFatturazione,
  reset: resetFatturazione,
};
