import { selectorTypes } from '../../..';
import {
  getAffiliati,
  getAffiliatiFailed,
  getAffiliatiInfo,
  getAffiliatiLoader,
  getAffiliatiOption,
  getAffiliatiOptionFailed,
  getAffiliatiOptionInfo,
  getAffiliatiOptionLoader,
  getAffiliatiOptionSuccess,
  getAffiliatiParams,
  getAffiliatiParamsOption,
  getAffiliatiSelectedIds,
  getAffiliatiSuccess,
  getAffiliatoById,
  getAffiliatoOptionById,
  getAffiliatiKeyValues,
  getAffiliatiOptionKeyValues,
} from '../selectors';

export const AffiliatiMapSelector = {
  loading: getAffiliatiLoader,
  success: getAffiliatiSuccess,
  failed: getAffiliatiFailed,
  data: getAffiliati,
  info: getAffiliatiInfo,
  params: getAffiliatiParams,
  loadingOption: getAffiliatiOptionLoader,
  successOption: getAffiliatiOptionSuccess,
  failedOption: getAffiliatiOptionFailed,
  dataOption: getAffiliatiOption,
  infoOption: getAffiliatiOptionInfo,
  paramsOption: getAffiliatiParamsOption,
  selectedIds: getAffiliatiSelectedIds,
  selectKeyValues: getAffiliatiKeyValues,
  selectOptionKeyValues: getAffiliatiOptionKeyValues,
  elementById: getAffiliatoById,
  elementOptionById: getAffiliatoOptionById,
};

export default AffiliatiMapSelector;
