import { Box, Flex, FormInput, Modal } from 'components';
import { TextField, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Edit as EditIcon, Save as SaveIcon, Close as CloseIcon } from '@mui/icons-material';

import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CONTO_ID,
  createScheda,
  fetchScheda,
  lastScheda,
  Scheda,
  updateDataFatturazioneScheda,
  useAppDispatch,
} from 'store';
import { createConto, fetchConto, getConto } from 'store/conto';
import {
  CONTO,
  DATE_FORMAT,
  formatDate,
  getLastDayOfNextMonth,
  Months,
  MONTHS,
  parseDate,
  YYYY_MM_DD,
} from 'utils';
import * as yup from 'yup';
import { isValidItalianIBAN } from 'helpers/validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fdatasync } from 'fs';
import { Form } from 'react-bootstrap';

interface Props {
  scheda: Scheda;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const schedaSchema = yup.object().shape({
  mese: yup.string().required('Campo obbligatorio'),
  anno: yup.string().required('Campo obbligatorio'),
});

const ibanSchema = yup.object().shape({
  iban: yup
    .string()
    .required('Campo obbligatorio')
    .test('is-valid-iban', 'IBAN non valido', isValidItalianIBAN),
});

// Schema di validazione per la data di fatturazione
const dataFatturazioneSchema = yup.object().shape({
  data_fatturazione: yup.date().required('Campo obbligatoria'),
});

export const RecapInfoScheda = ({ scheda, isOpen, onClose, onConfirm }: Props) => {
  const mese = Number(scheda.mese);
  const anno = Number(scheda.anno);

  const [dataFatturazione, setDataFatturazione] = useState<Date | null>(null);
  const [isContoOpen, setIsContoOpen] = useState(false);
  const [isSchedaEditable, setIsSchedaEditable] = useState(false);
  const [isIbanEditable, setIsIbanEditable] = useState(false);
  const [isDateEditable, setIsDateEditable] = useState(false);
  const [ultimaScheda, setLastScheda] = useState<Scheda | null>(null);

  const conto = useSelector(getConto);

  const {
    reset: resetScheda,
    register: registerScheda,
    getValues: getValuesScheda,
    trigger: triggerScheda,
    clearErrors: clearErrorsScheda,
    formState: { errors: errorsScheda },
  } = useForm({
    resolver: yupResolver(schedaSchema),
    defaultValues: { id: scheda.id, mese, anno },
  });

  const {
    reset: resetIban,
    register: registerIban,
    getValues: getValuesIban,
    trigger: triggerIban,
    clearErrors: clearErrorsIban,
    formState: { errors: errorsIban },
  } = useForm({
    resolver: yupResolver(ibanSchema),
    defaultValues: { iban: conto?.iban },
  });

  const {
    reset: resetDate,
    control: controlDate,
    trigger: triggerDate,
    setValue: setValueDate,
    getValues: getValuesDataFatturazione,
    clearErrors: clearErrorsDate,
    formState: { errors: errorsDate },
  } = useForm({
    resolver: yupResolver(dataFatturazioneSchema),
    defaultValues: { data_fatturazione: getLastDayOfNextMonth(mese, anno) },
  });

  const dispatch = useAppDispatch();

  const fetchContoCorrente = async () => {
    const response = await dispatch(fetchConto());
    if (response && !response.error) {
      const conto = response.payload.data;
      return conto;
    }
  };

  const fetchLastScheda = async () => {
    const response = await dispatch(lastScheda({ mese, anno }));
    if (response && !response.error) {
      const lastSheda = response.payload.data;
      setLastScheda(lastSheda);
      return lastScheda;
    }
  };

  const editScheda = () => setIsSchedaEditable(true);
  const editIban = () => setIsIbanEditable(true);
  const editDate = () => setIsDateEditable(true);

  const closeSchedaEditable = () => {
    setIsSchedaEditable(false);
    clearErrorsScheda();
  };

  const closeIbanEditable = () => {
    setIsIbanEditable(false);
    clearErrorsIban();
  };

  const closeDateEditable = () => {
    setIsDateEditable(false);
    clearErrorsDate();
  };

  const goOn = async () => {
    const noErrorsIban = await triggerIban();
    const noErrorsDate = await triggerDate();
    console.log({ noErrorsDate, noErrorsIban });
    let canValidate = true;
    if (noErrorsIban && noErrorsDate) {
      if (isIbanEditable) {
        const isSavedIban = await updateIban();
        canValidate = canValidate && isSavedIban;
      }
      if (isDateEditable) {
        const isSavedDate = await updateDataFatturazione();
        canValidate = canValidate && isSavedDate;
      }
      canValidate && onConfirm();
    } else {
      if (!noErrorsIban) {
        setIsIbanEditable(true);
      }
      if (!noErrorsDate) {
        setIsDateEditable(true);
      }
    }
  };

  const updateScheda = async () => {
    const noErrors = await triggerScheda();
    if (noErrors && scheda) {
      const currentMonth = getValuesScheda('mese');
      const currenYear = getValuesScheda('anno');
      const response = await dispatch(
        createScheda({
          ...scheda,
          mese: Number(currentMonth),
          anno: Number(currenYear),
        })
      );
      resetScheda({ mese: currentMonth, anno: currenYear });
      const newDate = getLastDayOfNextMonth(Number(currentMonth), Number(currenYear));
      console.log({ newDate });
      setDataFatturazione(newDate);
      setValueDate('data_fatturazione', newDate);
      const params = {
        scheda_id: scheda.id,
        data_fatturazione: formatDate(newDate, DATE_FORMAT[YYYY_MM_DD]),
      };
      const responseDate = await dispatch(updateDataFatturazioneScheda(params as any));

      dispatch(fetchScheda({ id: Number(scheda.id), edit_mode: true }));
      setIsSchedaEditable(false);
      clearErrorsScheda();
      return true;
    }
    return false;
  };

  const updateIban = async () => {
    const noErrors = await triggerIban();
    if (noErrors && conto) {
      const value = getValuesIban('iban');
      const response = await dispatch(
        createConto({
          id: conto.id,
          iban: String(value),
          nome_banca: conto.nome_banca,
          predefinito: conto.predefinito,
        })
      );
      resetIban({ iban: value });
      setIsIbanEditable(false);
      clearErrorsIban();
      return true;
    }
    return false;
  };

  const updateDataFatturazione = async () => {
    const noErrors = await triggerDate();
    if (noErrors) {
      if (scheda && scheda.id && dataFatturazione) {
        console.log({ dataFatturazione });
        const params = {
          scheda_id: scheda.id,
          data_fatturazione: formatDate(dataFatturazione, DATE_FORMAT[YYYY_MM_DD]),
        };
        const response = await dispatch(updateDataFatturazioneScheda(params as any));
        setIsDateEditable(false);
        clearErrorsDate();
        resetDate({ data_fatturazione: dataFatturazione ?? undefined });
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    fetchLastScheda().then();
    if (!conto) {
      fetchContoCorrente().then();
    }
    const defaultDate = getLastDayOfNextMonth(mese, anno);
    setValueDate('data_fatturazione', defaultDate);
    setDataFatturazione(defaultDate);
  }, [conto, mese, anno, setValueDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal
        title="Dati fatturazione"
        open={isOpen}
        handleClose={onClose}
        // style={{ width: '120rem' }}
        fullWidth
        maxWidth="sm"
        handleConfirm={goOn}
        textConfirm="Validazione"
        disableConfirm={isSchedaEditable || isIbanEditable || isDateEditable}
      >
        {ultimaScheda ? (
          <Flex alignItems="center" marginBottom="1rem" marginTop="1rem">
            <Box>
              {' '}
              L'ultima fatturazione effettuatata risale al periodo{' '}
              <strong>
                {MONTHS[Number(ultimaScheda.mese) - 1]}/{ultimaScheda.anno}
              </strong>
            </Box>
          </Flex>
        ) : (
          <div />
        )}
        <Flex alignItems="center" marginBottom="1rem" marginTop="1rem" height="4rem">
          <FormInput
            type="select"
            name="mese"
            label={'Mese rif.'}
            containerClass="m-1"
            options={MONTHS.map((month, index) => ({ id: index + 1, descrizione: month }))}
            register={registerScheda}
            errors={errorsScheda}
            disabled={!isSchedaEditable}
            style={{
              border: '1px solid #ced4da', // Marca leggermente i bordi
              borderRadius: '15px', // Arrotonda i lati sinistro e destro
              height: '38px', // Imposta un'altezza fissa
              width: '16rem',
            }}
          />
          <FormInput
            type="number"
            name="anno"
            label={'Anno rif.'}
            containerClass="m-1"
            min={anno - 30}
            max={anno + 30}
            register={registerScheda}
            disabled={!isSchedaEditable}
            errors={errorsScheda}
            style={{
              border: '1px solid #ced4da', // Marca leggermente i bordi
              borderRadius: '15px', // Arrotonda i lati sinistro e destro
              height: '38px', // Imposta un'altezza fissa
              width: '13rem',
            }}
          />
          <Flex
            marginTop={
              !errorsScheda?.mese?.message && !errorsScheda?.anno?.message ? '1.7rem' : 'unset'
            }
          >
            {isSchedaEditable ? (
              <>
                <IconButton onClick={closeSchedaEditable} size="small" sx={{ ml: 1 }}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={updateScheda} size="small" sx={{ ml: 1 }}>
                  <SaveIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={editScheda} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            )}
          </Flex>
        </Flex>

        <Flex textAlign="center" marginBottom="1rem" marginTop="1rem">
          <p>
            La modifica del periodo di riferimento della scheda comporta la modifica delle date di
            fatturazione di <b>TUTTE le righe della SCHEDA</b>
          </p>
        </Flex>

        <Flex alignItems="center" marginBottom="1rem" marginTop="1rem" height="4rem">
          <FormInput
            type={'text'}
            label={'Iban conto incasso'}
            name={'iban'}
            containerClass={'mb-1'}
            register={registerIban}
            disabled={!isIbanEditable}
            errors={errorsIban}
            style={{
              border: '1px solid #ced4da', // Marca leggermente i bordi
              borderRadius: '15px', // Arrotonda i lati sinistro e destro
              height: '38px', // Imposta un'altezza fissa
              width: '30rem',
            }}
          />
          <Flex marginTop={!errorsIban?.iban?.message ? '1.7rem' : 'unset'}>
            {isIbanEditable ? (
              <>
                <IconButton onClick={closeIbanEditable} size="small" sx={{ ml: 1 }}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={updateIban} size="small" sx={{ ml: 1 }}>
                  <SaveIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={editIban} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Controller
            name="data_fatturazione"
            control={controlDate}
            render={({ field }) => (
              <FormInput
                type={'date'}
                label={'Data di fatturazione'}
                containerClass={'mb-1'}
                disabled={!isDateEditable}
                errors={errorsDate}
                onChange={(e) => {
                  console.log({ e, val: e.target.value });
                  if (e.target.value) {
                    setDataFatturazione(parseDate(e.target.value));
                    field.onChange(parseDate(e.target.value));
                  } else {
                    setDataFatturazione(null);
                    field.onChange(null);
                  }
                }}
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '15px',
                  height: '38px',
                  width: '30rem',
                }}
                value={
                  dataFatturazione
                    ? formatDate(dataFatturazione, DATE_FORMAT[YYYY_MM_DD])
                    : undefined
                }
                name={'data_fatturazione'}
              />
            )}
          />
          <Flex marginTop="1.7rem">
            {isDateEditable ? (
              <>
                <IconButton onClick={closeDateEditable} size="small" sx={{ ml: 1 }}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={updateDataFatturazione} size="small" sx={{ ml: 1 }}>
                  <SaveIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={editDate} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            )}
          </Flex>
        </Flex>
        <Flex textAlign="center" marginBottom="1rem" marginTop="1rem">
          <p>
            La modifica della data di fatturazione comporta l'aggiornamento della data di
            fatturazione in <b>TUTTE le righe della SCHEDA</b>
          </p>
        </Flex>
      </Modal>
    </LocalizationProvider>
  );
};
