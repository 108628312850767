import { styled } from '@mui/system';
import { Box, TableCell } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  maxWidth: 0,
  padding: theme.spacing(1),
}));

export const EllipsisTableCell = ({ children }: any) => (
  <StyledTableCell>
    <Box component="div" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
      {children}
    </Box>
  </StyledTableCell>
);
