import { IconName } from '@ailibs/feather-react-ts';
import {
  Perm,
  TYPE_BOOLEAN,
  TYPE_CURRENCY,
  TYPE_DATE,
  TYPE_NUMBER,
  TYPE_SELECT,
  TYPE_STRING,
  TYPE_TAGS,
  TabsId,
} from 'store';

export interface MenuItem {
  title: string;
  onClick: () => void;
  handleClickItem?: (id: number) => void;
  key?: string;
  icon?: IconName;
  color?: string;
  id?: number;
  needId?: boolean;
  disabled?: boolean;
}

export interface Option {
  readonly label: string;
  readonly value: string;
}

export type TypeColumn =
  | typeof TYPE_STRING
  | typeof TYPE_DATE
  | typeof TYPE_NUMBER
  | typeof TYPE_SELECT
  | typeof TYPE_BOOLEAN
  | typeof TYPE_CURRENCY
  | typeof TYPE_TAGS;

export interface ColumnData<T> {
  id: keyof T;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left';
  format: (value: any) => string;
}

export interface AccessorGrid {
  tabId: TabsId;
  perm?: Perm;
  delete?: (id: any) => void;
  add?: (parentId: any, position?: number, row?: any) => void;
}

export interface RowProps {
  index: number;
  style: React.CSSProperties;
  data: Record<string, any>[];
}

export interface StepProps {
  title: string;
  actionNext: () => void;
  component: React.ReactElement;
}

export const STEP_ANAGRAFICA = 'Anagrafica';
export const STEP_SCHEDA = 'Schede';
export const STEP_FATTURAZIONE = 'Fatturazione';
export const STEP_INVIO_FATTURE = 'Invio Fatture';

export type StepValidazione =
  | typeof STEP_ANAGRAFICA
  | typeof STEP_SCHEDA
  | typeof STEP_FATTURAZIONE
  | typeof STEP_INVIO_FATTURE;

export interface InfoStepsProps {
  stato: 'idle' | 'waiting' | 'success' | 'error';
  title: string;
}

export type GenericObject = Record<string, any>;
export type Generics<K extends string | number | symbol, V> = Record<K, V>;
