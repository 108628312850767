import classNames from 'classnames';
import DateTimePicker from 'components/Datepicker';
import FormInput from 'components/FormInput';
import { ChangeEvent } from 'react';
import { Rect } from 'react-beautiful-dnd';
import { Form } from 'react-bootstrap';
import { KeyValue } from 'store';
import { DATE, FilterType, SELECT, TEXT, BOOLEAN, NUMBER } from './model';

export interface FilterElementProps {
  type: FilterType;
  title: string;
  name: string;
  containerClass: string;
  style: any;
  options?: KeyValue[];
  dateFormat?: string;
  timeFormat?: string;
  register?: any;
  onChange: (...args: any[]) => void;
}

export const FilterElement = ({
  type = TEXT,
  name,
  title,
  containerClass,
  style,
  onChange,
  dateFormat,
  timeFormat,
  options,
  register,
}: FilterElementProps) => {
  const ElementLiteral: Record<FilterType, React.ReactElement> = {
    [TEXT]: (
      <FormInput
        type={type}
        label={title}
        name={name}
        containerClass={containerClass}
        onChange={onChange}
        register={register}
        style={{
          border: '1px solid #ced4da', // Marca leggermente i bordi
          borderRadius: '25px', // Arrotonda i lati sinistro e destro
          height: '38px', // Imposta un'altezza fissa
          // Aggiungi qui altri stili se necessario
        }}
      />
    ),
    [NUMBER]: (
      <FormInput
        type={type}
        name={name}
        label={title}
        containerClass={containerClass}
        onChange={onChange}
        register={register}
        style={{
          border: '1px solid #ced4da', // Marca leggermente i bordi
          borderRadius: '25px', // Arrotonda i lati sinistro e destro
          height: '38px', // Imposta un'altezza fissa
          // Aggiungi qui altri stili se necessario
        }}
      />
    ),
    [SELECT]: (
      <FormInput
        type={type}
        name={name}
        label={title}
        containerClass={containerClass}
        onChange={onChange}
        register={register}
        options={options as KeyValue[]}
        style={{
          border: '1px solid #ced4da', // Marca leggermente i bordi
          borderRadius: '25px', // Arrotonda i lati sinistro e destro
          height: '38px', // Imposta un'altezza fissa
          // Aggiungi qui altri stili se necessario
        }}
      />
    ),
    [DATE]: (
      <DateTimePicker
        name={name}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        onChange={onChange}
      />
    ),
    // [SELECT]:
    //   options && options.length ? (
    //     <Form.Group className={containerClass}>
    //       <select onChange={onChange} className={'form-select d-inline-block w-auto'}>
    //         {options?.map((option) => (
    //           <option
    //             key={typeof option === 'string' ? option : option.id}
    //             value={typeof option === 'string' ? option : option.id}
    //           >
    //             {typeof option === 'string' ? option : option.descrizione}
    //           </option>
    //         ))}
    //       </select>
    //     </Form.Group>
    //   ) : (
    //     <></>
    //   ),
    [BOOLEAN]: <></>,
  };

  return ElementLiteral[type];
};
