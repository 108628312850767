import Flex from 'components/Flex';
import Modal from 'components/Modal';
import { useDisclosure } from 'hooks';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Grid, Typography, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { duplicateFatturazione, getPermsByModule, NOTA_DI_CREDITO, useAppDispatch } from 'store';
import { FATTURAZIONE } from 'utils';
import React, { useState } from 'react';
import FormContext from 'components/Form/FormContext';
import CurrencyFormatter from 'components/CurrencyFormatter';
import { AlertMessage } from 'components/AlertMessage';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import itLocale from 'date-fns/locale/it';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormContext } from 'react-hook-form';

export const NotaCredito = () => {
  const dayjsElement = dayjs.default();

  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const handleMonthChange = (newDate: Date | null) => {
    if (newDate) {
      console.log({ newDate });
      setCurrentDate(newDate);
      setValue('data', newDate);
    }
  };

  const perms = useSelector(getPermsByModule(FATTURAZIONE));
  const { element } = React.useContext(FormContext);

  const { setValue, register, getValues } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useAppDispatch();

  const save = async () => {
    const data = getValues('data');
    console.log({ data });
    const response = await dispatch(
      duplicateFatturazione({
        id: element?.id as number,
        documento_tipo: NOTA_DI_CREDITO,
        data: data,
      })
    );
    if (response && !response.error) {
      const notaCredito = response.payload.data;
      const dettaglioNotaCredito = `${notaCredito.f24_doc_number} del ${notaCredito.data}`;

      onClose();
      AlertMessage({
        title: 'Nota di credito creata',
        html: (
          <Flex justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {dettaglioNotaCredito}
            </Typography>
          </Flex>
        ),
        icon: 'success',
        allowOutsideClick: false,
      });
    }
  };
  return (
    <Flex paddingRight="2rem" paddingBottom="0.2rem">
      <Button
        variant="soft-primary"
        className="cursor-pointer"
        onClick={onOpen}
        disabled={perms && !perms.can_write}
        style={{
          margin: '0', // Rimuove il padding del pulsante
          borderRadius: '50%', // Rende il pulsante perfettamente rotondo
          width: '30px', // Imposta una larghezza fissa
          height: '30px', // Imposta un'altezza fissa per assicurarsi che sia uguale alla larghezza
          display: 'flex', // Usa flex per centrare l'icona all'interno del pulsante
          justifyContent: 'center', // Centra orizzontalmente l'icona
          alignItems: 'center', // Centra verticalmente l'icona
          marginRight: '.5rem',
        }}
      >
        <i className="uil uil-bill"></i>
      </Button>
      <Flex fontWeight="700" fontSize="0.9rem" alignItems="center">
        Nota di credito
      </Flex>

      <Modal
        title="Nota di credito"
        open={isOpen}
        handleClose={onClose}
        textConfirm="Salva"
        handleConfirm={save}
        maxWidth="lg"
      >
        <Card className="mb-3" style={{ border: 'unset', marginTop: '1rem' }}>
          <Card.Body>
            <Row>
              <Col md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {element?.ragione_sociale}
                  </Grid>
                  <Grid item xs={12}>
                    {element?.indirizzo}
                  </Grid>
                  <Grid item xs={12}>
                    {element?.cap} {element?.localita} ({element?.provincia})
                  </Grid>
                  <Grid item xs={12}>
                    {element?.nazione ?? 'Italia'}
                  </Grid>
                </Grid>
              </Col>
              <Col md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    Partita IVA: {element?.piva}
                  </Grid>
                  <Grid item xs={12}>
                    Codice fiscale: {element?.codice_fiscale}
                  </Grid>
                  <Grid item xs={12}>
                    Codice destinatario: {element?.codice_destinatario ?? '-'}
                  </Grid>
                  <Grid item xs={12}>
                    PEC destinatario: {element?.pec ?? '-'}
                  </Grid>
                </Grid>
              </Col>
              <Col md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                      <DatePicker
                        views={['year', 'month', 'day']}
                        label="Data documento"
                        minDate={new Date('2000-01-01')}
                        maxDate={new Date('2099-12-31')}
                        value={currentDate}
                        {...(register ? register('data') : {})}
                        onChange={handleMonthChange}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true, // Impedisce la modifica della data dall'input
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    Totale netto:{' '}
                    <b>
                      <CurrencyFormatter amount={element?.totale_netto} currency={'EUR'} />
                    </b>
                  </Grid>
                  <Grid item xs={12}>
                    Totale documento:{' '}
                    <b>
                      <CurrencyFormatter amount={element?.totale_ivato} currency={'EUR'} />
                    </b>
                  </Grid>
                  <Grid item xs={12}>
                    Totale iva:{' '}
                    <b>
                      <CurrencyFormatter amount={element?.totale_iva} currency={'EUR'} />
                    </b>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal>
    </Flex>
  );
};

export default NotaCredito;
