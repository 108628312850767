import * as dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Popover, Typography, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  DRIVE_TEST_ID,
  FETCHLIST,
  RESETSELECTEDIDS,
  TabsId,
  getAction,
  useAppDispatch,
} from 'store';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import itLocale from 'date-fns/locale/it';
import { Flex } from 'components';

interface SelectorTimeProps {
  tabId: TabsId;
  params: any;
  hasSchedeSelector: boolean;
  updateParams: (params: any) => void;
}

const iconStyles = {
  cursor: 'pointer',
  fontSize: '1.25rem', // Aumenta leggermente la dimensione dell'icona
};

const SelectorTime = ({ tabId, params, updateParams }: SelectorTimeProps) => {
  const dispatch = useAppDispatch();
  const dayjsElement = dayjs.default();
  const currentMonth = dayjsElement.month();
  const currentYear = dayjsElement.year();

  const [currentDate, setCurrentDate] = useState<Date>(new Date(currentYear, currentMonth));
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getData = async () => {
    if (tabId !== DRIVE_TEST_ID) {
      const actionFetch = getAction(tabId, FETCHLIST);
      actionFetch && (await dispatch(actionFetch()));
    }
  };

  const resetSelectedIds = () => {
    const action = getAction(tabId, RESETSELECTEDIDS);
    action && dispatch(action());
  };

  const decrementMonth = () => {
    let curMonth = month;
    let curYear = year;
    if (month > 0) {
      curMonth = month - 1;
    } else {
      curMonth = 11;
      curYear = year - 1;
    }
    setMonth(curMonth);
    setYear(curYear);
    resetSelectedIds();
    updateParams({ ...params, mese: curMonth + 1, anno: curYear });
    setCurrentDate(new Date(curYear, curMonth));
    getData();
  };

  const incrementMonth = () => {
    let curMonth = month;
    let curYear = year;
    if (month < 11) {
      curMonth = curMonth + 1;
    } else {
      curMonth = 0;
      curYear = year + 1;
    }
    setMonth(curMonth);
    setYear(curYear);
    resetSelectedIds();
    updateParams({ ...params, mese: curMonth + 1, anno: curYear });
    setCurrentDate(new Date(curYear, curMonth));
    getData();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleMonthChange = (newDate: Date | null) => {
    if (newDate) {
      setMonth(newDate.getMonth());
      setYear(newDate.getFullYear());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
      <Flex paddingRight="1rem" paddingBottom="0.2rem">
        <Button
          variant={`soft-primary`}
          className="cursor-pointer"
          style={{
            margin: '0',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '.5rem',
          }}
          onClick={handleClick}
        >
          <i className="uil uil-calendar-alt"></i>
        </Button>
        <Flex
          cursor="pointer"
          onClick={handleClick}
          fontWeight="700"
          fontSize="0.9rem"
          alignItems="center"
          width="6.7rem"
        >
          {format(currentDate, 'MMMM yyyy', { locale: it })}
        </Flex>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div style={{ padding: '20px' }}>
            <DatePicker
              views={['year', 'month']}
              label="Seleziona periodo"
              minDate={new Date('2000-01-01')}
              maxDate={new Date('2099-12-31')}
              value={new Date(year, month)}
              onChange={handleMonthChange}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  size="small"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true, // Impedisce la modifica della data dall'input
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <React.Fragment>
                        <ArrowBackIosNewIcon style={iconStyles} onClick={decrementMonth} />
                        {params.InputProps?.startAdornment}
                      </React.Fragment>
                    ),
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps?.endAdornment}
                        <ArrowForwardIosIcon
                          style={{ ...iconStyles, marginLeft: '.5rem' }}
                          onClick={incrementMonth}
                        />
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Popover>
      </Flex>
    </LocalizationProvider>
  );
};

export default SelectorTime;
