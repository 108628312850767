import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  apiClient,
  FatturazioneSchedeRequest,
  handleError,
  RootState,
  URL_FATTURAZIONE,
  URL_FATTURE_INVIATE,
  URL_STATISTICHE,
} from '../../..';

export const statisticheFatturazione = createAsyncThunk(
  'fatturazione/statistiche',
  async (params: FatturazioneSchedeRequest, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_FATTURAZIONE}${URL_STATISTICHE}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
