import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { setAffiliatiSelectedIds } from '.';
import { apiClient, handleError, RootState, setError, URL_AFFILIATI, URL_ITEM } from '../../..';
import { URL_ANAGRAFICA, URL_ASSOCIA_MULTI } from '../../..';
import { AssociaAffiliatiRequest } from '../types';

export const associaAffiliatiMulti = createAsyncThunk(
  'affiliati/associaMulti',
  async (params: AssociaAffiliatiRequest, thunkAPI): Promise<any> => {
    try {
      const state = thunkAPI?.getState() as RootState;
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_ANAGRAFICA}${URL_ITEM}/${params.padre_id}${URL_AFFILIATI}${URL_ASSOCIA_MULTI}`,
        params: { children_ids: params.children_ids },
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
