import {
  ANAGRAFICA_ID,
  FILTRO_ID,
  MODULO_ID,
  TabsId,
  MODULO_RIGHE_ID,
  SCHEDA_ID,
  REFERENTE_ID,
  FATTURAZIONE_ID,
  UTENTI_ID,
  CONTO_ID,
  TAB_IVA_ID,
  AFFILIATI_ID,
  VALIDAZIONE_SCHEDA_ID,
} from 'store';

export const MapTitlesNewElement: Record<TabsId | string, any> = {
  [ANAGRAFICA_ID]: 'Nuova anagrafica',
  [FILTRO_ID]: 'Nuovo filtro',
  [MODULO_ID]: 'Nuovo modello',
  [MODULO_RIGHE_ID]: 'Nuova riga',
  [SCHEDA_ID]: 'Nuova scheda',
  [REFERENTE_ID]: 'Nuovo referente',
  [FATTURAZIONE_ID]: 'Nuova fattura',
  [UTENTI_ID]: 'Nuovo utente',
  [CONTO_ID]: 'Nuovo conto',
  [TAB_IVA_ID]: '',
  [AFFILIATI_ID]: 'Nuova anagrafica',
  [VALIDAZIONE_SCHEDA_ID]: 'Validazione scheda',
};

export const MapTitleEditElement: Record<TabsId, string[]> = {
  [ANAGRAFICA_ID]: ['ragione_sociale'],
  [FILTRO_ID]: ['nome'],
  [MODULO_ID]: ['nome'],
  [MODULO_RIGHE_ID]: ['nome'],
  [SCHEDA_ID]: ['nome', 'mese', 'anno'],
  [REFERENTE_ID]: ['nome', 'cognome'],
  [FATTURAZIONE_ID]: ['ragione_sociale', 'data', 'f24_doc_number'],
  [UTENTI_ID]: ['name', 'lastname'],
  [CONTO_ID]: ['nome_banca'],
  [TAB_IVA_ID]: [''],
  [AFFILIATI_ID]: ['ragione_sociale'],
  [VALIDAZIONE_SCHEDA_ID]: ['nome', 'mese', 'anno'],
};
