import { createSelector } from 'reselect';
import { FAILED, PENDING, RootState, SUCCEEDED } from '../../..';

export const getUtenteState = (state: RootState) => state.utente;

export const getUtenteLoader = createSelector(
  getUtenteState,
  (utenteState) => utenteState.loading === PENDING
);

export const getUtenteFailed = createSelector(
  getUtenteState,
  (utenteState) => utenteState.loading === FAILED
);

export const getUtenteSuccess = createSelector(
  getUtenteState,
  (utenteState) => utenteState.loading === SUCCEEDED
);

export const getUtenteError = createSelector(getUtenteState, (utenteState) => utenteState?.error);

export const getUtente = createSelector(getUtenteState, (utenteState) => utenteState?.data);
export const getUtenteList = createSelector(
  getUtenteState,
  (utenteState) => utenteState?.list ?? []
);
export const getUtenteConfig = createSelector(getUtenteState, (utenteState) => utenteState?.config);
