import { ActionType } from '../../..';
import {
  fetchFatturazioni,
  fetchFatturazioniOptions,
  resetFatturazioneParams,
  resetFatturazioneParamsOption,
  resetFatturazioneSelectedIds,
  resetFatturazioni,
  resetFatturazioniOption,
  setFatturazioneParams,
  setFatturazioneParamsOption,
  setFatturazioneSelectedIds,
  selectFatturazioneAll,
  deleteFatturazioniMulti,
  updateSingleRecordFatturazioni,
} from '../actions';

export const FatturazioniMapAction: Record<ActionType | string, any> = {
  fetchList: fetchFatturazioni,
  fetchListOption: fetchFatturazioniOptions,
  setParams: setFatturazioneParams,
  setParamsOption: setFatturazioneParamsOption,
  setSelectedIds: setFatturazioneSelectedIds,
  resetSelectedIds: resetFatturazioneSelectedIds,
  resetParams: resetFatturazioneParams,
  resetParamsOption: resetFatturazioneParamsOption,
  resetList: resetFatturazioni,
  resetListOption: resetFatturazioniOption,
  setSelectAll: selectFatturazioneAll,
  deleteMulti: deleteFatturazioniMulti,
  updateSingleRecord: updateSingleRecordFatturazioni,
};
