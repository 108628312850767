import {
  BLOCK,
  COMPLETED,
  EDIT,
  INVIO_STATO_DI,
  INVIO_STATO_ERR,
  INVIO_STATO_IN,
  INVIO_STATO_WINV,
  NEW,
  StatoInvio,
  StatoScheda,
  VALIDATE,
} from 'store';

export const getVariantForStatus = (status: StatoScheda): string => {
  switch (status) {
    case NEW:
      return 'primary';
    case EDIT:
      return 'info';
    case VALIDATE:
      return 'warning';
    case BLOCK:
      return 'danger';
    case COMPLETED:
      return 'success';
    default:
      return 'secondary';
  }
};

export const getVariantForInvioStato = (status: StatoInvio): string => {
  switch (status) {
    case INVIO_STATO_DI:
      return 'primary';
    case INVIO_STATO_WINV:
      return 'info';
    case INVIO_STATO_IN:
      return 'success';
    case INVIO_STATO_ERR:
      return 'danger';
    default:
      return 'secondary';
  }
};
