import { createSelector } from 'reselect';
import { TabIva } from '../';
import { IDLE, PENDING, FAILED, SUCCEEDED, RootState, Info } from '../../..';

export const getTabelleIvaState = (state: RootState) => state.tabelleIva;

export const getTabelleIvaLoader = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loading === PENDING
);

export const getTabelleIvaFailed = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loading === FAILED
);

export const getTabelleIvaSuccess = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loading === SUCCEEDED
);

export const getTabelleIvaOptionLoader = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loadingOption === PENDING
);

export const getTabelleIvaOptionFailed = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loadingOption === FAILED
);

export const getTabelleIvaOptionSuccess = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loadingOption === SUCCEEDED
);

export const getTabelleIvaEnumLoader = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loadingEnum === PENDING
);

export const getTabelleIvaEnumFailed = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loadingEnum === FAILED
);

export const getTabelleIvaEnumSuccess = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState.loadingEnum === SUCCEEDED
);

export const getTabelleIvaError = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.error
);

export const getTabelleIva = createSelector(getTabelleIvaState, (tabIvaState) => tabIvaState?.data);

export const getTabelleIvaInfo = createSelector(
  getTabelleIvaState,
  (tabIvaState): Info => tabIvaState?.info ?? { pages: 0, currentPage: 0, total: 0 }
);

export const getTabelleIvaOption = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.dataOption
);

export const getTabelleIvaOptionInfo = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.infoOption
);

export const getCategorieTabIva = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.categorie
);

export const getCategoriaTabIva = (gruppo: string | number) =>
  createSelector(getCategorieTabIva, (categorie) => {
    const gruppoElement = categorie
      ? categorie.find((element: Record<string, any>) => element.id === gruppo)
      : null;
    return gruppoElement;
  });

export const getTabelleIvaTipologie = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.tipologie
);

export const getTabelleIvaKeyValues = createSelector(
  getTabelleIva,
  (tabIva) =>
    tabIva?.map((tabIva: TabIva) => ({
      id: tabIva.id,
      descrizione: tabIva.descrizione,
    })) ?? []
);
export const getTabelleIvaOptionKeyValues = createSelector(
  getTabelleIvaOption,
  (tabIva) =>
    tabIva?.map((tabIva: TabIva) => ({
      id: tabIva.id,
      descrizione: tabIva.descrizione,
    })) ?? []
);

export const getTabIvaById = (id: string | number) =>
  createSelector(getTabelleIva, (tabIva) =>
    tabIva?.find((tabIvaElement: TabIva) => tabIvaElement.id === id)
  );

export const getTabIvaOptionById = (id: string | number) =>
  createSelector(getTabelleIvaOption, (tabIva) =>
    tabIva?.find((tabIvaElement: TabIva) => tabIvaElement.id === id)
  );

export const getTabIvaTipologia = (tipologia: string | number) =>
  createSelector(getTabelleIvaTipologie, (tipologie) => {
    const tipologiaElement = tipologie
      ? tipologie.find((element: Record<string, any>) => element.id === tipologia)
      : null;
    return tipologiaElement;
  });

export const getTabelleIvaParams = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.params
);

export const getTabelleIvaParamsOption = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.paramsOption
);

export const getTabelleIvaSelectedIds = createSelector(
  getTabelleIvaState,
  (tabIvaState) => tabIvaState?.selectedIds
);
