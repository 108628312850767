import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReferenteCreateParams } from '../types';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, URL_REFERENTE, URL_CREATE } from 'store/utils';

export const createReferente = createAsyncThunk(
  'referente/create',
  async (params: ReferenteCreateParams, thunkAPI) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_REFERENTE}${URL_CREATE}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
