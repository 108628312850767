import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import FormInput from 'components/FormInput';
import { ChangeEvent, useState } from 'react';
interface ConfirmModalProps {
  show: boolean;
  title: string;
  children: React.ReactElement | React.ReactElement[];
  maxWidth?: Breakpoint;
  onConfirm: (params?: any) => void;
  setShow: (show: boolean) => void;
}
const CHECK_VALUE = 'CONFERMA';
export const AdvancedConfrimModal = ({
  title,
  children,
  setShow,
  onConfirm,
  show,
  maxWidth = 'sm',
}: ConfirmModalProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleConfirm = () => {
    if (inputValue === CHECK_VALUE) {
      onConfirm();
      setInputValue('');
    }
  };
  return (
    <Dialog fullWidth open={show} onClose={() => setShow(false)} maxWidth={maxWidth}>
      <DialogTitle
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          borderBottom: '1px solid',
          marginBottom: '1rem',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
        <Box textAlign="center" fontWeight="bold" marginBottom="1rem">
          Digitare CONFERMA e premere SI per proseguire
        </Box>
        <FormInput
          name={'conferma'}
          placeholder="CONFERMA"
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            const value = ev.target.value;
            setInputValue(value);
          }}
          value={inputValue}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={() => setShow(false)} variant="outlined" color="error">
          NO
        </Button>
        <Button onClick={handleConfirm} variant="contained" className="btn btn-primary">
          SI
        </Button>
      </DialogActions>
    </Dialog>
  );
};
