import { AddLogSchedaParams } from '../types';
import { apiClient, handleError, URL_SCHEDA, URL_ADD_LOG_SCHEDA } from '../../../index';
import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addLogsScheda = createAsyncThunk(
  'scheda/addLog',
  async (params: AddLogSchedaParams, thunkAPI: any) => {
    try {
      const response = await apiClient.post<AxiosResponse>({
        url: `${URL_SCHEDA}${URL_ADD_LOG_SCHEDA}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
