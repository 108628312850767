import { FormInput, SelectForm } from 'components';
import { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ANAGRAFICA_ID, Anagrafica, getPermsByModule, getSelectorElementById } from 'store';
import { ANAGRAFICA } from 'utils';
import { Context } from '../Context';
import FormContext from 'components/Form/FormContext';

export const DatiGenerali = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { perms } = useContext(Context);

  const { formId } = useContext(FormContext);

  const anagrafica = getSelectorElementById<Anagrafica>(ANAGRAFICA_ID, formId ?? 0);

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Dati generali</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={anagrafica && anagrafica.padre_ragione_sociale ? 4 : 6}>
            <FormInput
              type="text"
              name="ragione_sociale"
              label={'Ragione Sociale'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={anagrafica && anagrafica.padre_ragione_sociale ? 4 : 6}>
            <FormInput
              type="text"
              name="referente"
              label={'Legale rappresentante'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>

          {anagrafica && anagrafica.padre_ragione_sociale ? (
            <Col md={4}>
              <FormInput
                type="text"
                name="padre_ragione_sociale"
                label={'Anagrafica madre'}
                minHeight="6.25rem"
                register={register}
                errors={errors}
                disabled={perms && !perms.can_write}
              />
            </Col>
          ) : (
            <div />
          )}
          {/* <Col md={4}>
            <FormInput
              type="text"
              name="piva"
              label="P.IVA"
              placeholder="01234567890"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="text"
              name="codice_fiscale"
              label="Cod. fiscale"
              placeholder="GHBMNF67R09D462N"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col> */}
          <Col md={6}>
            <FormInput
              type="text"
              name="piva"
              label="P.IVA"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={6}>
            <FormInput
              type="text"
              name="codice_fiscale"
              label="Cod. fiscale"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={4}>
            <FormInput
              type="text"
              name="categoria"
              label={'Categoria'}
              placeholder="Artigiano"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <SelectForm
              label={'Gruppo Anagrafico'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
              name="gruppo_anagrafico"
              elements={[
                { id: 'CLIENTE', descrizione: 'Cliente' },
                { id: 'FORNITORE', descrizione: 'Fornitore' },
                { id: 'DIPENDENTE', descrizione: 'Dipendente' },
                { id: 'GENERICO', descrizione: 'Generico' },
              ]}
            />
          </Col>
          <Col md={4}>
            <SelectForm
              label={'Tipologia'}
              minHeight="6.25rem"
              register={register}
              errors={errors}
              name="tipologia"
              elements={[
                { id: 'FPR', descrizione: 'Azienda/Ditta individuale' },
                { id: 'FPF', descrizione: 'Persona fisica' },
                { id: 'FPA', descrizione: 'Pubblica amministrazione' },
                { id: 'ALT', descrizione: 'Altro' },
              ]}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="text"
              name="codice"
              label={'Cod. Fattura24'}
              placeholder="123456"
              minHeight="6.25rem"
              register={register}
              errors={errors}
            />
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default DatiGenerali;
