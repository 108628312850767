import { CircularProgress } from '@mui/material';
import { Flex, StepLoader, Wizard } from 'components';
import { ModelFormProps } from 'components/Forms/model';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Scheda, getSchedaLoader } from 'store';

import { ColDef } from 'ag-grid-community';
import { DataGridEditable, DateEditor } from 'components/DataGridEditable';
import { useValidate } from './hookValidate';
import FormContext from 'components/Form/FormContext';

export const ValidateSchedaForm = ({ id, element }: ModelFormProps<Scheda>) => {
  const { closeForm } = useContext(FormContext);

  const { steps, isLoading, buttonSteps, infoSteps, completedComponent, validate } = useValidate({
    id,
    closeForm,
  });

  useEffect(() => {
    validate();
  }, [id]);

  if (isLoading) {
    return (
      <div>
        <StepLoader steps={infoSteps} />
      </div>
    );
  }

  return (
    <div>
      <Wizard steps={steps} buttonStepLabel={buttonSteps} completedComponent={completedComponent} />
    </div>
  );
};
