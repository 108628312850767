import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { apiClient, handleError, handleErrorLock, URL_ITEM, URL_LOCK } from 'store/utils';
import { LockStatus, LockParams } from '../types';
import { RootState } from 'store/applicationStore';

export const lock = createAsyncThunk(
  'lock/lock',
  async (parameters: LockParams, thunkAPI): Promise<LockStatus> => {
    const { module, module_id } = parameters;
    const state = thunkAPI?.getState() as RootState;
    const currentUser = state?.user?.data ?? {};
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_LOCK}/${module}${URL_ITEM}/${module_id}`,
        params: {},
      });
      if (response.status === 200) {
        const lock = response.data.data;

        if (Number(currentUser?.id ?? 0) !== Number(lock.user_id ?? 0)) {
          handleErrorLock(423, thunkAPI, lock);
        }
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
