export interface Conto {
  id?: number;
  nome_banca: string | null;
  iban: string | null;
  predefinito: boolean;
  created?: Date;
  updated?: Date;
}

export class ContoClass implements Conto {
  id = 0;
  nome_banca = null;
  iban = null;
  predefinito = true;
  created = undefined;
  updated = undefined;
}
