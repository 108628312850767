import {
  TabsId,
  ANAGRAFICA_ID,
  LOGIN_ID,
  CONFIRM_ID,
  REGISTER_ID,
  FILTRO_ID,
  MODULO_ID,
  DRIVE_TEST_ID,
  SCHEDA_ID,
  REFERENTE_ID,
  FATTURAZIONE_ID,
  UTENTI_ID,
  CONTO_ID,
  TAB_IVA_ID,
  AFFILIATI_ID,
  VALIDAZIONE_SCHEDA_ID,
} from 'store';

import React, { lazy } from 'react';
import UtenteForm from 'components/Forms/Utente';

const AnagraficaForm = lazy(() => import('../Forms/Anagrafica'));
const FiltroEdit = lazy(() => import('../Forms/FiltroEditForm'));
const SchedaForm = lazy(() => import('../Forms/Scheda/SchedaForm'));
const ModuloEdit = lazy(() => import('../Forms/Modulo'));
const ContoForm = lazy(() => import('../Forms/Conto'));
const FatturazioneForm = lazy(() => import('../Forms/Fatturazione'));

export const Forms: Record<TabsId, React.ReactElement> = {
  [LOGIN_ID]: <div />,
  [CONFIRM_ID]: <div />,
  [REGISTER_ID]: <div />,
  [ANAGRAFICA_ID]: <AnagraficaForm id={0} element={undefined} />,
  [FILTRO_ID]: <FiltroEdit id={0} element={undefined} />,
  [MODULO_ID]: <ModuloEdit id={0} element={undefined} />,
  [SCHEDA_ID]: <SchedaForm id={0} element={undefined} />,
  [VALIDAZIONE_SCHEDA_ID]: <div />,
  [UTENTI_ID]: <UtenteForm id={0} element={undefined} />,
  [FATTURAZIONE_ID]: <FatturazioneForm id={0} />,
  [REFERENTE_ID]: <div />,
  [DRIVE_TEST_ID]: <div />,
  [TAB_IVA_ID]: <div />,
  [CONTO_ID]: <ContoForm id={0} element={undefined} />,
  [AFFILIATI_ID]: <AnagraficaForm id={0} element={undefined} />,
};

export default Forms;
