import { Anagrafica } from 'store/anagrafiche';
import { KeyValue } from 'store/types';
import { GenericObject, Generics } from 'utils';

export interface Scheda {
  id?: number;
  nome: string | null;
  mese?: number;
  anno?: number;
  filtro_id?: number | null;
  filtro: KeyValue | null;
  modulo: KeyValue | null;
  stato: string;
  created?: Date;
  updated?: Date;
}

export class SchedaClass implements Scheda {
  id = 0;
  nome = null;
  mese = 0;
  anno = 0;
  filtro_id = null;
  filtro = null;
  modulo = null;
  stato = NEW;
  created = undefined;
  updated = undefined;
}

export interface ValidationError {
  anagrafiche?: Anagrafica[];
  righe?: Record<string, any>[];
}

export interface CampiScheda {
  id: number;
  scheda_id: number;
  modulo_id: number;
  riga_nome: string;
  riga_tipo: string;
  colonna_nome: string;
}

export interface SchedaLogs {
  id?: number;
  scheda_id: number;
  user_id: number;
  user: string;
  riga_id?: number;
  anagrafica_id?: number;
  tipo: TipoSchedaLogs;
  tipo_riga: TipoRigaSchedaLogs;
  stato_iniziale?: StatoScheda;
  stato_finale?: StatoScheda;
  azione?: string;
  note?: string;
  created?: Date;
  updated?: Date;
}

export const CHANGE_STATE = 'change_state';
export const ACTION_USER = 'action_user';

export type TipoSchedaLogs = typeof CHANGE_STATE | typeof ACTION_USER;

export const ERROR_CORRECTION = 'ERROR_CORRECTION';
export const IGNORE_ERROR = 'IGNORE_ERROR';
export const IGNORE_NOW = 'IGNORE_NOW';

export const ERROR_CORRECTION_LABEL = 'Correggi';
export const IGNORE_ERRORE_LABEL = 'Ignora';
export const IGNORE_NOW_LABEL = 'Ignora e correggi successivamente';

export const AzioniLabels: string[] = [
  ERROR_CORRECTION_LABEL,
  IGNORE_ERRORE_LABEL,
  IGNORE_NOW_LABEL,
];

export const Azioni: KeyValue[] = [
  { id: ERROR_CORRECTION, descrizione: ERROR_CORRECTION_LABEL },
  { id: IGNORE_ERROR, descrizione: IGNORE_ERRORE_LABEL },
  { id: IGNORE_NOW, descrizione: IGNORE_NOW_LABEL },
];

export const ANAGRAFICA_ERROR_CORRECTION = 'ERROR_CORRECTION';
export const ANAGRAFICA_IGNORE_ERROR = 'IGNORE_ERROR';
export const ANAGRAFICA_IGNORE_QUOTA_CEDOLINO = 'IGNORE_QUOTA_CEDOLINO';
export const ANAGRAFICA_IGNORE_CANONE_FISSO = 'IGNORE_CANONE_FISSO';

export const ANAGRAFICA_ERROR_CORRECTION_LABEL = 'Correggi';
export const ANAGRAFICA_IGNORE_ERRORE_LABEL = 'Ignora';
export const ANAGRAFICA_IGNORE_QUOTA_CEDOLINO_LABEL = 'Ignora errore quota cedolino';
export const ANAGRAFICA_IGNORE_CANONE_FISSO_LABEL = 'Ignora errore canone fisso';

export const AnagraficaAzioniLabels: string[] = [
  ANAGRAFICA_ERROR_CORRECTION_LABEL,
  ANAGRAFICA_IGNORE_ERRORE_LABEL,
  ANAGRAFICA_IGNORE_QUOTA_CEDOLINO_LABEL,
  ANAGRAFICA_IGNORE_CANONE_FISSO_LABEL,
];

export const AnagraficaAzioni: KeyValue[] = [
  { id: ANAGRAFICA_ERROR_CORRECTION, descrizione: ANAGRAFICA_ERROR_CORRECTION_LABEL },
  { id: ANAGRAFICA_IGNORE_ERROR, descrizione: ANAGRAFICA_IGNORE_ERRORE_LABEL },
  { id: ANAGRAFICA_IGNORE_QUOTA_CEDOLINO, descrizione: ANAGRAFICA_IGNORE_QUOTA_CEDOLINO_LABEL },
  { id: ANAGRAFICA_IGNORE_CANONE_FISSO, descrizione: ANAGRAFICA_IGNORE_CANONE_FISSO_LABEL },
];

export const FATTURAZIONE_ERROR_CORRECTION = 'ERROR_CORRECTION';
export const FATTURAZIONE_IGNORE_ERROR = 'IGNORE_ERROR';
export const FATTURAZIONE_IGNORE_AND_SOLVE_EXTERNAL = 'IGNORE_AND_SOLVE_EXTERNAL';

export const FATTURAZIONE_ERROR_CORRECTION_LABEL = 'Correggi';
export const FATTURAZIONE_IGNORE_ERROR_LABEL = 'Ignora';
export const FATTURAZIONE_IGNORE_AND_SOLVE_EXTERNAL_LABEL =
  'Ignora e risolvi su fatturazione esterna';

export const FatturazioneLabels: string[] = [
  FATTURAZIONE_ERROR_CORRECTION_LABEL,
  FATTURAZIONE_IGNORE_ERROR_LABEL,
  FATTURAZIONE_IGNORE_AND_SOLVE_EXTERNAL_LABEL,
];

export const FatturazioneAzioni: KeyValue[] = [
  { id: FATTURAZIONE_ERROR_CORRECTION, descrizione: FATTURAZIONE_ERROR_CORRECTION_LABEL },
  { id: FATTURAZIONE_IGNORE_ERROR, descrizione: FATTURAZIONE_IGNORE_ERROR_LABEL },
  {
    id: FATTURAZIONE_IGNORE_AND_SOLVE_EXTERNAL,
    descrizione: FATTURAZIONE_IGNORE_AND_SOLVE_EXTERNAL_LABEL,
  },
];

export const RIGA_ERROR_CORRECTION = 'ERROR_CORRECTION';
export const RIGA_IGNORE_ERROR = 'IGNORE_ERROR';
export const RIGA_IGNORE_NUMERO_CEDOLINI = 'IGNORE_NUMERO_CEDOLINI';
export const RIGA_IGNORE_DATA_FATTURAZIONE = 'IGNORE_DATA_FATTURAZIONE';

export const RIGA_ERROR_CORRECTION_LABEL = 'Correggi';
export const RIGA_IGNORE_ERROR_LABEL = 'Ignora';
export const RIGA_IGNORE_NUMERO_CEDOLINI_LABEL = 'Ignora valorizzazione numero cedolini';
export const RIGA_IGNORE_DATA_FATTURAZIONE_LABEL = 'Ignora valorizzazione data fatturazione';

export const RigaLabels: string[] = [
  RIGA_ERROR_CORRECTION_LABEL,
  RIGA_IGNORE_ERROR_LABEL,
  RIGA_IGNORE_NUMERO_CEDOLINI_LABEL,
  RIGA_IGNORE_DATA_FATTURAZIONE_LABEL,
];

export const RigaAzioni: KeyValue[] = [
  { id: RIGA_ERROR_CORRECTION, descrizione: RIGA_ERROR_CORRECTION_LABEL },
  { id: RIGA_IGNORE_ERROR, descrizione: RIGA_IGNORE_ERROR_LABEL },
  { id: RIGA_IGNORE_NUMERO_CEDOLINI, descrizione: RIGA_IGNORE_NUMERO_CEDOLINI_LABEL },
  { id: RIGA_IGNORE_DATA_FATTURAZIONE, descrizione: RIGA_IGNORE_DATA_FATTURAZIONE_LABEL },
];

export const SCHEDA = 'scheda';
export const RIGA = 'riga';
export const RIGA_ANAGRAFICA = 'riga_anagrafica';
export const RIGA_FATTURAZIONE = 'riga_fatturazione';

export type TipoRigaSchedaLogs =
  | typeof SCHEDA
  | typeof RIGA
  | typeof RIGA_ANAGRAFICA
  | typeof RIGA_FATTURAZIONE;

export const NEW = 'nuovo';
export const EDIT = 'modifica';
export const VALIDATE = 'validate';
export const BLOCK = 'blocco';
export const COMPLETED = 'completo';

export type StatoScheda =
  | typeof NEW
  | typeof EDIT
  | typeof VALIDATE
  | typeof BLOCK
  | typeof COMPLETED;

export const StatoSchedaLabels: Generics<StatoScheda | string, string> = {
  [NEW]: 'Nuovo',
  [EDIT]: 'Modifica',
  [VALIDATE]: 'Validazione',
  [BLOCK]: 'Blocco',
  [COMPLETED]: 'Completo',
};

export const VALIDAZIONE_COMPLETA = 'all';
export const VALIDAZIONE_ANAGRAFICA = 'anagrafica';
export const VALIDAZIONE_SCHEDA = 'scheda';
export const VALIDAZIONE_FATTURAZIONE = 'fatturazione';

export type TipoValidazione =
  | typeof VALIDAZIONE_COMPLETA
  | typeof VALIDAZIONE_ANAGRAFICA
  | typeof VALIDAZIONE_SCHEDA
  | typeof VALIDAZIONE_FATTURAZIONE;
