import { LastSchedaFetchParams } from '../types';
import { handleError, URL_SCHEDA, URL_LAST } from '../../../index';
import { AxiosResponse } from 'axios';
import { apiClient } from '../../../utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const lastScheda = createAsyncThunk(
  'scheda/last',
  async (params: LastSchedaFetchParams, thunkAPI) => {
    try {
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_SCHEDA}${URL_LAST}`,
        params,
      });
      if (response.status === 200) {
        return response.data;
      }
      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
