import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  apiClient,
  FatturazioneSchedeRequest,
  handleError,
  RootState,
  URL_ESPORTA_FATTURE,
  URL_FATTURAZIONE,
} from '../../..';

export const esportaFatturazione = createAsyncThunk(
  'esportaFatturazione/fetch',
  async (parameters: FatturazioneSchedeRequest, thunkAPI): Promise<any> => {
    try {
      const { schede_id, ...params } = parameters;
      const state = thunkAPI?.getState() as RootState;
      const ids = state?.fatturazioni?.selectedIds ?? null;
      const response = await apiClient.get<AxiosResponse>({
        url: `${URL_FATTURAZIONE}${URL_ESPORTA_FATTURE}`,
        params: { ...params, ids: schede_id ?? ids },
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
