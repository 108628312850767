import { FormInput, SelectForm } from 'components';
import { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Context } from '../Context';

export const DatiBancari = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { perms } = useContext(Context);

  return (
    <Card className="mb-3">
      <Card.Header>
        <span>Dati Bancari</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <FormInput
              type="text"
              name="nome_banca"
              label="Nome Banca"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
          <Col md={6}>
            <FormInput
              type="text"
              name="iban"
              label="IBAN"
              minHeight="6.25rem"
              register={register}
              errors={errors}
              disabled={perms && !perms.can_write}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DatiBancari;
