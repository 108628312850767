import { selectorTypes } from '../../..';
import {
  getTabelleIva,
  getTabelleIvaFailed,
  getTabelleIvaInfo,
  getTabelleIvaLoader,
  getTabelleIvaOption,
  getTabelleIvaOptionFailed,
  getTabelleIvaOptionInfo,
  getTabelleIvaOptionLoader,
  getTabelleIvaOptionSuccess,
  getTabelleIvaParams,
  getTabelleIvaParamsOption,
  getTabelleIvaSelectedIds,
  getTabelleIvaSuccess,
  getTabIvaById,
  getTabIvaOptionById,
  getTabelleIvaKeyValues,
  getTabelleIvaOptionKeyValues,
} from '../selectors';

export const TabelleIvaMapSelector = {
  loading: getTabelleIvaLoader,
  success: getTabelleIvaSuccess,
  failed: getTabelleIvaFailed,
  data: getTabelleIva,
  info: getTabelleIvaInfo,
  params: getTabelleIvaParams,
  loadingOption: getTabelleIvaOptionLoader,
  successOption: getTabelleIvaOptionSuccess,
  failedOption: getTabelleIvaOptionFailed,
  dataOption: getTabelleIvaOption,
  infoOption: getTabelleIvaOptionInfo,
  paramsOption: getTabelleIvaParamsOption,
  selectedIds: getTabelleIvaSelectedIds,
  selectKeyValues: getTabelleIvaKeyValues,
  selectOptionKeyValues: getTabelleIvaOptionKeyValues,
  elementById: getTabIvaById,
  elementOptionById: getTabIvaOptionById,
};

export default TabelleIvaMapSelector;
