import { ActionType, UPDATE_SINGLE_RECORD } from '../../..';
import {
  fetchUtenti,
  fetchUtentiOptions,
  resetUtenteParams,
  resetUtenteParamsOption,
  resetUtenteSelectedIds,
  resetUtenti,
  resetUtentiOption,
  setUtenteParams,
  setUtenteParamsOption,
  setUtenteSelectedIds,
  selectUtenteAll,
  deleteUtentiMulti,
  updateSingleRecordUtenti,
} from '../actions';

export const UtentiMapAction: Record<ActionType | string, any> = {
  fetchList: fetchUtenti,
  fetchListOption: fetchUtentiOptions,
  setParams: setUtenteParams,
  setParamsOption: setUtenteParamsOption,
  setSelectedIds: setUtenteSelectedIds,
  resetSelectedIds: resetUtenteSelectedIds,
  resetParams: resetUtenteParams,
  resetParamsOption: resetUtenteParamsOption,
  resetList: resetUtenti,
  resetListOption: resetUtentiOption,
  setSelectAll: selectUtenteAll,
  deleteMulti: deleteUtentiMulti,
  updateSingleRecord: updateSingleRecordUtenti,
};

export default UtentiMapAction;
