import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContoDeleteParams } from '../types';
import { apiClient, handleError, URL_CONTO, URL_DELETE } from '../../index';
import { AxiosResponse } from 'axios';

export const deleteConto = createAsyncThunk(
  'conto/delete',
  async (params: ContoDeleteParams, thunkAPI) => {
    try {
      const response = await apiClient.delete<AxiosResponse>({
        url: `${URL_CONTO}${URL_DELETE}/${params.id}`,
      });
      if (response.status === 200) {
        return response.data;
      }

      return handleError(response.status, thunkAPI, response.data?.status);
    } catch (error: any) {
      return handleError(error?.response?.status, thunkAPI, error?.response?.data?.error);
    }
  }
);
